import { getToken, errorEvaluation, getUrl } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { Invoices } from './invoices.store'

import { DialsInvoicesPaymentTerms } from './dialsInvoicesPaymentTerms.store'
//import { DialsInvoicesVatRoundingTypes } from './dialsInvoicesVatRoundingTypes.store'

import { EmailingWhiteList } from './emailingWhiteList.store'
import { EmailingBlackList } from './emailingBlackList.store'
import { DatafiltersCustomersCustomList } from './datafiltersCustomersCustomList.store'
import { DatafiltersCustomersCustomVariables } from './datafiltersCustomersCustomVariables.store'
import { EmailingConditions } from './emailingConditions.store'

import { DatafiltersCustomers } from './datafiltersCustomers.store'


import { DialsPrograms } from './dialsPrograms.store'
import { DialsProgramVersions } from './dialsProgramVersions.store'
import { DialsLicenseTypes } from './dialsLicenseTypes.store'
import { DialsServices } from './dialsServices.store'
import { DialsModules } from './dialsModules.store'
import { DialsPriceListsModules } from './dialsPriceListsModules.store'
import { DialsPriceListsServices } from './dialsPriceListsServices.store'

import { DialsSupportTypes } from './dialsSupportTypes.store'
import { DialsSupportThemes } from './dialsSupportThemes.store'

import { DialsFreeText } from './dialsFreeText.store'

import { DialsOperationsPreferences } from './dialsOperationsPreferences.store'

import { DialsDeliveryCzechPostServices } from './dialsDeliveryCzechPostServices.store'
import { DialsDeliveryCzechPostServicesAll } from './dialsDeliveryCzechPostServicesAll.store'
import { DialsDeliveryCzechPostProducts } from './dialsDeliveryCzechPostProducts.store'
import { DialsDeliveryCzechPostProductsAll } from './dialsDeliveryCzechPostProductsAll.store'
import { DialsDeliveryCzechPostTemplates } from './dialsDeliveryCzechPostTemplates.store'

import { Helpers } from './helpers.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootInvoicesStore = types
  .model({
    
    invoices: types.array(Invoices),

    dialsInvoicesPaymentTerms: types.array(DialsInvoicesPaymentTerms),
    dialsInvoicesVatRoundingTypes: types.array(types.string),
    //dialsInvoicesVatRoundingTypes: types.array(DialsInvoicesVatRoundingTypes),
    
    dialsInvoicesUnits: types.array(types.string),

    //dialsCategories: types.array(DialsCategories),
    dialsPrograms: types.array(DialsPrograms),
    dialsProgramVersions: types.array(DialsProgramVersions),
    dialsLicenseTypes: types.array(DialsLicenseTypes),
    dialsServices: types.array(DialsServices),
    dialsModules: types.array(DialsModules),
    dialsPriceListsModules: types.array(DialsPriceListsModules),
    dialsPriceListsServices: types.array(DialsPriceListsServices),
    dialsSupportTypes: types.array(DialsSupportTypes),
    dialsSupportThemes: types.array(DialsSupportThemes),
    dialsFreeText: types.array(DialsFreeText),
    dialsOperationsPreferences: types.array(DialsOperationsPreferences),

    dialsDeliveryCzechPostServices: types.array(DialsDeliveryCzechPostServices),
    dialsDeliveryCzechPostServicesAll: types.array(DialsDeliveryCzechPostServicesAll),
    dialsDeliveryCzechPostProducts: types.array(DialsDeliveryCzechPostProducts),
    dialsDeliveryCzechPostProductsAll: types.array(DialsDeliveryCzechPostProductsAll),
    dialsDeliveryCzechPostTemplates: types.array(DialsDeliveryCzechPostTemplates),
    dialsDeliveryCzechPostTemplatesAll: types.array(DialsDeliveryCzechPostTemplates),

    helpers: types.array(Helpers),


    emailingWhiteList: types.array(EmailingWhiteList),
    emailingBlackList: types.array(EmailingBlackList),
    datafiltersCustomersCustomList: types.array(DatafiltersCustomersCustomList),
    datafiltersCustomersCustomListAll: types.array(DatafiltersCustomersCustomList),
    datafiltersCustomersCustomVariables: types.array(DatafiltersCustomersCustomVariables),
    emailingConditions: types.array(EmailingConditions),
    datafiltersCustomersDesignerPreview: types.array(DatafiltersCustomers),
    
  })
  .views(self => ({
    getEmailingBlackListById(id: string) {
      return self.emailingBlackList.find(item => item.id === id)
    },

  }))
  .actions(self => ({

    fetchInvoicesList: flow(function* fetchProjects(pageNumber:number, filterSet:string) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        let filterAllways:string = '((invoiceType~eq~'+filterSet+'))'
        //filterAllways = ''

        
        filter = getFilterStringToBE(localStorage.getItem('invoicesListSearchArray'))

        if (filter !== '') {
          filter = filter+'~and~'+filterAllways
        }
        else {
          filter = filterAllways
        }
        //console.log(filter)

        if (localStorage.getItem('invoicesListOrderBy') === 'DateOfIssue') order = 'DateOfIssue~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'DateOfIssueDesc') order = 'DateOfIssue~desc'

        if (localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumber') order = 'InvoiceNumber~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'InvoiceNumberDesc') order = 'InvoiceNumber~desc'

        if (localStorage.getItem('invoicesListOrderBy') === 'Total') order = 'Total~asc'
        if (localStorage.getItem('invoicesListOrderBy') === 'TotalDesc') order = 'Total~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_INVOICES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastInvoicesListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastInvoicesListTotalCount') === '0') {
          self.invoices.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.invoices,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst faktury. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchInvoiceById: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
       const cl = yield axios.get(getUrl('URL_INVOICES')+'/'+id+'?include=true',{
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      }).then(response => {
        //console.log(response.data)
        return response.data
        
      }).catch(error => {
        errorEvaluation(error)
      });

        // Najdeme index existující faktury
        const existingIndex = self.invoices.findIndex((inv) => inv.id === id);

        // Pokud záznam existuje, nahradíme jej, jinak přidáme nový
        const invoicesMerge = [...self.invoices];
        if (existingIndex !== -1) {
          invoicesMerge[existingIndex] = cl; // Nahradí existující záznam
        } else {
          invoicesMerge.push(cl); // Přidá nový záznam na konec, pokud neexistuje
        }

        applySnapshot(self.invoices, invoicesMerge);



        //const texts = yield axios.get(getUrl('URL_INVOICES')+'/proforma/0F21109E-51B0-4A17-82B2-6E3B93C7529B/texts',{
        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/texts',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addTextsFromData(response.data)
        }).catch(error => {
          errorEvaluation(error)
        });

        //const texts = yield axios.get(getUrl('URL_INVOICES')+'/proforma/0F21109E-51B0-4A17-82B2-6E3B93C7529B/texts',{
        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/email',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addEmailsFromData(response.data.items)
        }).catch(error => {
          errorEvaluation(error)
        });

        yield axios.get(getUrl('URL_INVOICES')+'/'+id+'/pdf',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          //console.log(response.data)
          //return response.data
          self.invoices.find(inv => inv.id === id)!.addDocumentsFromData(response.data)
        }).catch(error => {
          errorEvaluation(error)
        });


       /*
        const cl = yield axios.get(getUrl('URL_INVOICES')+'/'+id+'?include=true',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const invoicesMerge:any = self.invoices.filter(inv => inv.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.invoices,invoicesMerge)
       */   
    } catch (error) {
      console.error('Failed to fetch invoice', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),


    addInvoiceFromData: flow(function* fetchProjects(invoiceData:any) {
      try {
        //self.submissions.clear()
          // eslint-disable-next-line      
          const accessToken = yield getToken()
        
          self.invoices.push(invoiceData)
          //self.submissions.clear()
          //applySnapshot(self.submissions,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchDialsInvoicesPaymentTerms: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesPaymentTerms.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_PAYMENT_TERMS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesPaymentTermsTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesPaymentTermsTotalCount') === '0') {
          self.dialsInvoicesPaymentTerms.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesPaymentTerms,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst platební podmínky. Může dojít k odhlášení!', error)
    } 
    }),

    fetchDialsInvoicesVatRoundingTypes: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesVatRoundingTypes.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_VAT_ROUNDING_TYPES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesVatRoundingTypesTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesVatRoundingTypesTotalCount') === '0') {
          self.dialsInvoicesVatRoundingTypes.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesVatRoundingTypes,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst způsoby zaokrouhlení DPH. Může dojít k odhlášení!', error)
    } 
    }),

    fetchDialsInvoicesUnits: flow(function* fetchProjects() {
      try {
        self.dialsInvoicesUnits.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DIALS_INVOICES_UNITS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            localStorage.setItem('lastDialsInvoicesUnitsTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDialsInvoicesUnitsTotalCount') === '0') {
          self.dialsInvoicesUnits.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.dialsInvoicesUnits,cl) 
        } 

    } catch (error) {
      console.error('Nepodařilo se načíst způsoby zaokrouhlení DPH. Může dojít k odhlášení!', error)
    } 
    }),



    fetchDatafiltersCustomersDesignerPreview: flow(function* fetchProjects(pageNumber:number, filterSet:string, id:string|null|undefined) {
      try {
        let isSetId:boolean = (id === null || id === undefined || id === '') ? false : true
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomPreviewSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('datafiltersCustomersCustomPreviewOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('datafiltersCustomersCustomPreviewOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        let paramsToSend:any = {
            filterSet,
            filter,
            order,
            page,
            pagesize
        }

        if (isSetId === true) paramsToSend = {
          filter,
          //order,
          page,
          pagesize
        }

        let url:string = getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_DESIGNER_PREVIEW')

        if (isSetId === true) url = getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_FILTER_PREVIEW') + id + '/execute'


        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

            localStorage.setItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDatafiltersCustomersCustomPreviewSearchTotalCount') === '0') {
          self.datafiltersCustomersDesignerPreview.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersDesignerPreview,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst datafiltersCustomersCustomPreview. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersDesignerPreview: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomList.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomList', error)
      }
    }),

    pushEmailingConditions: flow(function* fetchProjects(id:string,isSystem:boolean,props1:number|null,props2:number|null,operatorBefore:string) {
      try {
        yield sleep(100)
        self.emailingConditions.push({
          id: id, 
          isSystem: isSystem,
          props1: props1,
          props2: props2,
          operatorBefore: operatorBefore,
          includedConditions: [],
        })
                
      } catch (error) {
        console.error('Failed to push EmailingConditions', error)
      }
    }),

    fetchEmailingConditionsFromData: flow(function* fetchProjects(data:any) {
      try {
        yield sleep(100)
        applySnapshot(self.emailingConditions,data)
        
        
                
      } catch (error) {
        console.error('Failed to push EmailingConditions', error)
      }
    }),
    
    deleteEmailingConditions: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingConditions.clear()
                
      } catch (error) {
        console.error('Failed to clear EmailingConditions', error)
      }
    }),
    deleteEmailingCondition: flow(function* fetchProjects(i:any) {
      try {
        yield sleep(100)
        //self.emailingConditions = self.emailingConditions.filter((item,index) => index !== i)
        self.emailingConditions.remove(i)
                
      } catch (error) {
        console.error('Failed to clear EmailingConditions', error)
      }
    }),

    unselectDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomVariables.forEach((item) => {
          item.selectItem(false)
        })
    } catch (error) {
      console.error('Nepodařilo se unselect DatafiltersCustomersCustomVariables. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        //let filter:string = ''
        //let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        //const page = pageNumber
        //const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_VARIABLES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            //filter,
            //order,
            //page,
            //pagesize
          }  }).then(response => {

            //localStorage.setItem('lastDatafiltersCustomersCustomVariablesTotalCount',response.data.totalCount)
            return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        applySnapshot(self.datafiltersCustomersCustomVariables,cl) 
        
        /*
        if (localStorage.getItem('lastDatafiltersCustomersCustomVariablesTotalCount') === '0') {
          //self.emailingBlackList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersCustomList,cl) 
        } 
        */
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomVariables. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersCustomVariables: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomVariables.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomVariables', error)
      }
    }),
    
    
    fetchDatafiltersCustomersCustomList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastDatafiltersCustomersCustomListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastDatafiltersCustomersCustomListTotalCount') === '0') {
          self.datafiltersCustomersCustomList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.datafiltersCustomersCustomList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteDatafiltersCustomersCustomList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomersCustomList.clear()
                
      } catch (error) {
        console.error('Failed to clear DatafiltersCustomersCustomList', error)
      }
    }),

    fetchDatafiltersCustomersCustomListAll: flow(function* fetchProjects() {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('datafiltersCustomersCustomListSearchArray'))
        //console.log(filter)

        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'Name') order = 'Name~asc'
        //if (localStorage.getItem('datafiltersCustomersCustomListOrderBy') === 'NameDesc') order = 'Name~desc'

        const page = 1
        const pagesize = 9999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //localStorage.setItem('lastDatafiltersCustomersCustomListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.datafiltersCustomersCustomListAll.clear()
          applySnapshot(self.datafiltersCustomersCustomListAll,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst DatafiltersCustomersCustomList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchEmailingBlackList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('emailingBlackListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('emailingBlackListOrderBy') === 'Email') order = 'Email~asc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'EmailDesc') order = 'Email~desc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'CreateDate') order = 'CreateDate~asc'
        if (localStorage.getItem('emailingBlackListOrderBy') === 'CreateDateDesc') order = 'CreateDate~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_EMAILING_BLACKLIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastEmailingBlackListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastEmailingBlackListTotalCount') === '0') {
          self.emailingBlackList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.emailingBlackList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst BlackList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteEmailingBlackList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingBlackList.clear()
                
      } catch (error) {
        console.error('Failed to clear Blacklist', error)
      }
    }),
    
    
    fetchEmailingWhiteList: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.dialsDeliveryCzechPostProducts.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('emailingWhiteListSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('emailingWhiteListOrderBy') === 'Email') order = 'Email~asc'
        if (localStorage.getItem('emailingWhiteListOrderBy') === 'EmailDesc') order = 'Email~desc'

        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_EMAILING_WHITELIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastEmailingWhiteListTotalCount',response.data.totalCount)
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastEmailingWhiteListTotalCount') === '0') {
          self.emailingWhiteList.clear()
        }
        else {
          //self.dialsDeliveryCzechPostProducts.clear()
          applySnapshot(self.emailingWhiteList,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst WhiteList. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    deleteEmailingWhiteList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.emailingWhiteList.clear()
                
      } catch (error) {
        console.error('Failed to clear whitelist', error)
      }
    }),

    

  }))

export type RootInvoicesStoreType = Instance<typeof RootInvoicesStore>
export type InvoicesList = Instance<typeof Invoices>
