import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Button, Modal, Badge } from 'react-bootstrap'

import { observer } from 'mobx-react'

interface ModuleInfoProps {
  program: string,
  module: string,
  showAs: string,
  text: string,
}

const ModuleInfo: React.FC<ModuleInfoProps & RouteComponentProps>  = (props:any) => {
  //Práva SH BH
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')  ) ? true : false


  // Nastavení stavů pro zobrazení
  const [show, setShow] = useState(false)

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setShow(true)
  }

  const getModuleName = () => {
    let moduleName:string = ''

    if (props.module === 'Y') moduleName = 'Systémové jádro'
    if (props.module === 'U') moduleName = 'Účetnictví'
    if (props.module === 'S') moduleName = 'Sklady'
    if (props.module === 'R') moduleName = 'Polotovary dokladů'
    if (props.module === 'P') moduleName = 'Maloobchod'
    if (props.module === 'M') moduleName = 'Mzdy'
    if (props.module === 'K') moduleName = 'Kancelář'
    if (props.module === 'J') moduleName = 'Majetek'
    if (props.module === 'E') moduleName = 'E-Shop'
    if (props.module === 'F') moduleName = 'Daňová evidence'
    if (props.module === 'A') moduleName = 'Analýzy'

    return moduleName
  }
  
  return (
    <>
        {props.showAs === 'button' &&
          <>
          <Button variant="primary" size="sm" onClick={handleShow}>{props.text}</Button>
          </>
        }
        {props.showAs === 'link' &&
          <>
          {props.module === 'R' && !rights &&<><Badge variant="info" onClick={handleShow}>Nový modul</Badge><br /></>}
          <span className="as-link-no-color" style={{textDecoration: 'underline'}} title='Kliknutím na název modulu zobrazíte jeho stručný popis' onClick={handleShow}>{props.text}</span>
          </>
        }

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title><img className="noprint" alt="logo programu" src={"images/ico-header-"+props.program+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />{getModuleName()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.program === 'D' &&
            <>
              {props.module === 'Y' &&
                <>
                <p>Jedná se o nedílnou součást instalace ekonomického systému DUEL a slouží především pro spojení ekonomického systému DUEL s databázovým systémem a dále pro správu zpracovávaných firem, správu uživatelů včetně přístupových práv, uživatelské zálohování a obnovu dat, servisní zásahy nad jednotlivými moduly programu, popřípadě databázemi zpracovávaných firem.</p>
                </>
              }
              {props.module === 'U' &&
                <>
                <p>Ekonomický systém DUEL umožňuje kompletní vedení účetnictví. Stejně jako ostatní moduly, lze i tento provozovat samostatně. Účetnictví v DUELU je přehledné a snadno se ovládá jak účetními, kteří v něm evidují veškeré doklady, tak i majiteli firem, kteří v něm sledují potřebné ekonomické ukazatele. Účtování v něm může probíhat zcela automaticky, a proto systém velmi usnadňuje práci. Součástí modulu jsou agendy Účetní deník, Rozúčtování, Závazky a pohledávky, Zpracování DPH, Bankovní výpisy, Převodní příkazy, Pokladna a Výkazy.</p>
                </>
              }
              {props.module === 'S' &&
                <>
                <p>Systém evidence skladového hospodářství programu DUEL je založen na možnosti evidovat neomezené množství skladů. Může jich být až 100.000, přičemž všechny sklady jsou organizačně odděleny. Modul vám umožňuje jednoduše zadávat doklady a přehledně sledovat všechny operace, které se týkají vašich zásob. A to i napříč všemi sklady na jednom dokladu. Skladové zásoby můžete evidovat v průměrných i pevných cenách a cenách bez daně nebo s daní. Modul navíc podporuje metody účtování A i B.</p>
                </>
              }
              {props.module === 'R' &&
                <>
                <p>Představujeme vám nový modul „Účetní polotovary“ – inovativní nástroj, který přináší novou úroveň efektivity a kontroly do každodenní účetní praxe. Tento modul vám umožní efektivněji pracovat s doklady, které ještě nejsou zcela připravené k zaúčtování, ať už jsou neúplné, potřebují další úpravy nebo schválení.</p>
                <div className="showWeb">
                  <h4>Co vám „Účetní polotovary“ přinesou?</h4>
                  <ul>
                    <li><strong>Flexibilita v práci s doklady:</strong> Můžete pořizovat částečné nebo neúplné doklady a vracet se k nim, kdykoliv potřebujete. Až po doplnění všech náležitostí je zaúčtujete, čímž získáte větší kontrolu nad doklady i jejich toky.</li>
                    <li><strong>Jednoduché importy a úpravy:</strong> Modul podporuje snadný import účetních dat z formátů XML a CSV, a to i u neúplných dokladů. Můžete je doladit ručně nebo automatizovaně, aniž byste museli ztrácet čas s přepisováním všech dat.</li>
                    <li><strong>Schvalování dokladů:</strong> Už žádné chybné platby! Všechna data můžete nastavit tak, aby prošla schválením odpovědnou osobou před zaúčtováním. Tento modul vám zajistí jistotu, že například faktura nebude uhrazena bez autorizace.</li>
                  </ul>

                  <h4>Jak nový modul pracuje?</h4> 
                  <p>„Účetní polotovary“ slouží jako další vstupní brána do systému DUEL. Můžete zde zadávat a evidovat doklady, které ještě nejsou připraveny k přenosu do cílových agend, jako například laicky (neúplně) pořízené doklady, doklady vyžadující další kontrolu nebo importovaná data, která je vhodné před účtováním upravit nebo doplnit. Zkušená obsluha je následně dle potřeby dopracuje, ale již bez nutnosti přepisování je přenese do finálních agend, jako jsou Účetní deník, Závazky a pohledávky, Bankovní výpisy nebo Účetní pokladna.</p>

                  <h4>Proč si modul pořídit?</h4>
                  <ul>
                    <li><strong>Úspora času a odstranění rutinní práce:</strong> Umožněte méně kvalifikovaným pracovníkům, aby předběžně zadávali nebo importovali doklady. Zkušená obsluha pak jen doplní nezbytné informace. Tento modul šetří váš čas a umožňuje vám soustředit se na odborné úkoly.</li>
                    <li><strong>Jednoduchý a intuitivní přístup pro každého:</strong> Díky automatizovanému párování nebo zapojení finalizačních skriptů zvládne ovládání modulu i laik, a to bez nutnosti technických znalostí. Vaše účetnictví se stává efektivnější a bezpečnější, a to s minimálními náklady na zaškolení.</li>
                    <li><strong>Maximální kontrola nad účetními operacemi:</strong> Volitelný schvalovací proces zaručí, že každá transakce projde odpovídající kontrolou, což chrání firmu před zbytečnými chybami a náklady.</li>
                  </ul>

                  <p>Modul „Účetní polotovary“ je nástroj, který ochrání vaše profesionály před zbytečnou administrativou a zvýší kvalitu vašich účetních procesů. S ním získáte jistotu, že váš tým bude mít více času na důležité úkoly a méně času bude potřebovat na rutinu.</p>

                </div>
                </>
              }
              {props.module === 'P' &&
                <>
                <p>Maloobchod (nebo Kasa, jak my v Ježkovi říkáme) je nadstavbou modulu Sklad. Díky Kase můžete zásoby ze skladu ihned prodávat a tržbu inkasovat hotově či prostřednictvím platebních karet. Jinými slovy, agenda umožňuje jednoduchým způsobem provádět maloobchodní prodej nebo vytvořit z počítače pracoviště s registrační pokladnou. Na Kasu je možné připojit displej, snímač čárového kódu, pokladní zásuvku, registrační pokladnu, fiskální modul atd.</p>
                </>
              } 
              {props.module === 'M' &&
                <>
                <p>Tento modul slouží k snadnému vedení personalistiky a mzdové agendy pro neomezený počet zaměstnanců. MZDY lze napojit na docházkové systémy, lze evidovat úkolovou práci a dokonce kompletně zpracovávat exekuce. Samozřejmostí je také zpracování ročního vyúčtování daně i tisk evidenčních listů důchodového pojištění. Modul Mzdy umožňuje exportovat vybrané tiskopisy do formátu XML, což se hodí pro elektronickou komunikaci s pojišťovnami a ČSSZ. Díky provázanosti modulů a řady automatických funkcí můžete zpracované mzdy jednoduše zaúčtovat do Účetního deníku a přenést do Závazků a pohledávek.</p>
                </>
              } 
              {props.module === 'K' &&
                <>
                <p>Tento modul dodáváme ke každé licenci ZDARMA. V případě, že tedy nevedete skladové hospodářství a chcete pohodlně vystavovat faktury nebo objednávky, funkce tohoto modulu pro vás budou užitečné. Kancelář navíc obsahuje agendy pro přiznání k dani z příjmu právnických nebo fyzických osob (DPPO, DPFO), dále pro přiznání silniční daně, tvorbu převodních příkazů, evidenci neúčetních plateb a v neposlední řadě můžete vést adresář firem a osob.</p>
                </>
              }
              {props.module === 'J' &&
                <>
                <p>Zpracovat majetek je nelehkým úkolem, který vám však usnadní přehledný modul Majetek z Ježkovy dílny. Daří se mu to přednastavenými číselníky. Účtování majetku (zařazení, odpisy, vyřazení, splátky) je tudíž v DUELU jednoduché, logické a vždy v souladu s aktuální legislativou. V každé agendě můžete standardně evidovat majetek pomocí čárových kódů, nebo můžete připojovat elektronické dokumenty a média (třeba čtečku kódů). Bonusem je možnost evidence zápůjček a naplánovaných úkolů, například revizí.</p>
                </>
              }
              {props.module === 'E' &&
                <>
                <p>Tento speciální doplněk slouží k propojení programu DUEL se zvoleným internetovým obchodem. E-shop je v podstatě jakýmsi můstkem, který zajišťuje synchronizaci mezi DUELEM a vaším obchodem. Díky němu dokážete snadno vystavit zboží včetně obrázků, popisků zboží, jejich parametrů či prodejních cen. Ty přitom spravujete pohodlně právě v DUELU. Do něj můžete stahovat a vyřizovat objednávky zákazníků e-shopu.</p>
                </>
              }
              {props.module === 'F' &&
                <>
                <p>Ekonomický systém DUEL umožňuje kompletní zpracování daňové evidence. Stejně jako ostatní moduly, i tento můžete provozovat samostatně, aniž byste museli dokupovat další, pro vás nepotřebné funkcionality. Daňovou evidenci využijí zvláště živnostníci. Díky ní můžete evidovat příjmy a výdaje, pokladnu i banku, zpracovat daňová přiznání, roční uzávěrku, fakturace, evidovat majetek nebo vyřizovat svou korespondenci.</p>
                </>
              }
              {props.module === 'A' &&
                <>
                <p>Analýza účtů čerpá z účetních dat a poskytuje analytické informace za zvolené období. Oproti běžným účetním výstupů nabízí informace mnohem podrobnější, v nejrůznějším členění s  mezisoučty a to bez ohledu na to, jestli je zpracovávané období uzavřeno účetní závěrkou. Výstupy z analýzy mohou být manažerskou nadstavbou a součástí našeho moderního účetnictví. Součástí je tzv. Aktivní saldokonto. S jeho pomocí lze získat nejen dokonalý přehled o spárovaných a nespárovaných fakturách, ale rychle také odhalit případné chyby v účtování a hlavně je z jednoho místa vyřešit.</p>
                </>
              }
            </>
          }

          {props.program === 'S' &&
            <>
              {props.module === 'U' &&
                <>
                <p>Účetní systém STEREO umožňuje kompletní vedení účetnictví. Stejně jako ostatní moduly, lze i tento provozovat samostatně. Účetnictví ve STEREU je přehledné a snadno se ovládá jak účetními, kteří v něm evidují veškeré doklady, tak i majiteli firem, kteří v něm sledují potřebné ekonomické ukazatele. Účtování v něm může probíhat zcela automaticky, a proto systém velmi usnadňuje práci. Součástí modulu jsou agendy Účetní deník, Rozúčtování, Závazky a pohledávky, Zpracování DPH, Pokladna, Bankovní výpisy, Převodní příkazy a Výkazy.</p>
                </>
              }
              {props.module === 'S' &&
                <>
                <p>Systém evidence skladového hospodářství programu STEREO je založen na možnosti evidovat velké množství skladů, přičemž jsou všechny sklady odděleny organizačně i datově. Modul vám umožňuje jednoduše zadávat doklady a přehledně sledovat všechny operace, které se týkají vašich zásob. Skladové zásoby můžete evidovat v průměrných i pevných cenách a cenách bez daně nebo s daní. Modul navíc podporuje metody účtování A i B. Součástí modulu je i nadstavba - maloobchodní pokladna. Díky Kase můžete zásoby ze skladu ihned prodávat a tržbu inkasovat hotově či prostřednictvím platebních karet. Jinými slovy, agenda umožňuje jednoduchým způsobem provádět maloobchodní prodej nebo vytvořit z počítače pracoviště s registrační pokladnou. Na Kasu je možné připojit displej, snímač čárového kódu, pokladní zásuvku, registrační pokladnu, fiskální modul atd.</p>
                </>
              }
              {props.module === 'M' &&
                <>
                <p>Tento modul slouží k snadnému vedení personalistiky a mzdové agendy pro neomezený počet zaměstnanců. Samozřejmostí je také zpracování ročního vyúčtování daně i tisk evidenčních listů důchodového pojištění. Modul Mzdy umožňuje exportovat vybrané tiskopisy do formátu XML, což se hodí pro elektronickou komunikaci s pojišťovnami a ČSSZ. Díky provázanosti modulů a řady automatických funkcí můžete zpracované mzdy jednoduše zaúčtovat do Účetního deníku a přenést do Závazků a pohledávek.</p>
                </>
              } 
              {props.module === 'K' &&
                <>
                <p>Modul Kancelář je základní a nedílnou součástí programu STEREO. Obsahuje agendy, které jsou společné celému programu (např. adresář firem), a funkce, které jsou volané z jiných míst (např. EET nebo podání XML). V případě, že nevedete skladové hospodářství a chcete pohodlně vystavovat faktury nebo objednávky, funkce tohoto modulu pro vás budou užitečné. Kancelář navíc obsahuje agendy pro přiznání k dani z příjmu právnických nebo fyzických osob (DPPO, DPFO), dále pro přiznání silniční daně, tvorbu převodních příkazů a v neposlední řadě obsahuje adresář firem a osob. Od verze pro rok 2017 Kancelář obsahuje nové aparáty pro elektronickou komunikaci – prvními jsou elektronická podání na ČSSZ a Finanční úřady, včetně sledování stavu odeslaného dokumentu. Druhý nástroj umožňuje komunikaci v rámci Elektronické evidence tržeb s návazností na ostatní moduly (Sklady a Účetnictví).</p>
                </>
              }
              {props.module === 'J' &&
                <>
                <p>Zpracovat majetek je nelehkým úkolem, který vám však usnadní přehledný modul Majetek z Ježkovy dílny. Daří se mu to přednastavenými číselníky. Účtování majetku (zařazení, odpisy, vyřazení, splátky) je tudíž ve STEREU jednoduché, logické a vždy v souladu s aktuální legislativou.</p>
                </>
              }
              {props.module === 'A' &&
                <>
                <p>Nadstavba k modulu Účetnictví, která rozšiřuje běžné výstupy o nové funkce, které se ve vyšších systémech souhrnně nazývají Business Intelligence. Analýzy nabízejí mnoho reportů podle mnoha kritérií, uchovávání podmínek, srovnávání období a spoustu dalších "vychytávek". Získejte ze svého účetnictví více informací, než jen rozvahu a výsledovku.</p>
                </>
              }
            </>
          }
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Zavřít</Button>
        </Modal.Footer>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(ModuleInfo))
