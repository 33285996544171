import { types } from 'mobx-state-tree'
import moment from 'moment'

//Default License
const DLProgram = types.model('DLProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const DLProgramVersion = types.model('DLProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const DLLicenseType = types.model('DLLicenseType',{
  id: types.optional(types.string,''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const DLModule = types.model('DLModule',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const DLModules = types.model('DLModules',{
  count: types.maybeNull(types.number),
  module: types.frozen(DLModule)
})

const DefaultLicense = types.model('DefaultLicense',{
  id: types.optional(types.string, ''),
  count: types.maybeNull(types.number),
  isActive: types.optional(types.boolean, false),
  status: types.maybeNull(types.string),

  program: types.frozen(DLProgram),
  programVersion: types.frozen(DLProgramVersion),

  licenseType: types.frozen(DLLicenseType),

  modules: types.array(DLModules)
})


//Active License
const ALProgram = types.model('ALProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const ALProgramVersion = types.model('ALProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const ALLicenseType = types.model('ALLicenseType',{
  id: types.optional(types.string,''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const ALModule = types.model('ALModule',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const ALModules = types.model('ALModules',{
  count: types.maybeNull(types.number),
  module: types.frozen(ALModule)
})

const ActiveLicense = types.model('ActiveLicense',{
  id: types.optional(types.string, ''),
  count: types.maybeNull(types.number),
  isActive: types.optional(types.boolean, false),
  status: types.maybeNull(types.string),

  program: types.frozen(ALProgram),
  programVersion: types.frozen(ALProgramVersion),

  licenseType: types.frozen(ALLicenseType),

  modules: types.array(ALModules)
})

//Target License
const TLProgram = types.model('TLProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const TLProgramVersion = types.model('TLProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const TLLicenseType = types.model('TLLicenseType',{
  id: types.optional(types.string,''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const TLModule = types.model('TLModule',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const TLModules = types.model('TLModules',{
  count: types.maybeNull(types.number),
  module: types.frozen(TLModule)
})

const TargetLicense = types.model('TargetLicense',{
  id: types.optional(types.string, ''),
  count: types.maybeNull(types.number),
  isActive: types.optional(types.boolean, false),
  status: types.maybeNull(types.string),

  program: types.frozen(TLProgram),
  programVersion: types.frozen(TLProgramVersion),

  licenseType: types.frozen(TLLicenseType),

  modules: types.array(TLModules)
})

//Customer
const Customer = types.model('Customer',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  companyRegistrationNumber: types.optional(types.string, ''),
  taxIdentificationNumber: types.optional(types.string, ''),
  isTaxAble: types.optional(types.boolean, false),
  isActive: types.optional(types.boolean, false)
})

//Scénář
const SProgram = types.model('SProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const SProgramVersion = types.model('SProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const UpgradeScenario = types.model('UpgradeScenario',{
  id: types.optional(types.string, ''),
  program: types.frozen(SProgram),
  programVersion: types.frozen(SProgramVersion),
  distributionDate: types.maybeNull(types.string),
  isActive: types.optional(types.boolean, false),
  })

//Cena licence
const LModule = types.model('LModule',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const LSubItems = types.model('LSubItems',{
  price: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  isFirst: types.optional(types.boolean, false), 
  priceType: types.maybeNull(types.string),
  
})

const LItems = types.model('LItems',{
  totalPrice: types.maybeNull(types.number),
  module: types.frozen(LModule),
  items: types.array(LSubItems),
  
})

const LicensePrice = types.model('LicensePrice',{
  totalPrice: types.maybeNull(types.number),
  items: types.array(LItems),
})

//Individuální položky
const IItems = types.model('IItems',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  offerId: types.maybeNull(types.string),
  price: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  vatType: types.maybeNull(types.string),
  vatRate: types.maybeNull(types.number),
})

//Služby
const Service = types.model('Service',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
})
const Services = types.model('Services',{
  price: types.maybeNull(types.number),
  service: types.frozen(Service), 
})

const Submission = types.model('Submission',{
  id: types.optional(types.string,''),
  distributionDate: types.maybeNull(types.string),
  isExported: types.optional(types.boolean, false),
  isSubmitted: types.optional(types.boolean, false),
})

const Packets = types.model('Packets',{
  id: types.optional(types.string,''),
  submissionId: types.optional(types.string,''),
  packetCode: types.optional(types.string,''),
  returnDate: types.maybeNull(types.string),
  returnNote: types.maybeNull(types.string),
  submission: types.maybeNull(types.frozen(Submission))
})
.actions(self => ({
  setSubmission(data: any) {
    self.submission = data
  }
}))

const Invoices = types.model('Invoices',{
  id: types.optional(types.string,''),
  packets: types.optional(types.array(Packets),[])
})
.actions(self => ({
  setPackets(data: any) {
    self.packets = data
  }

}))
.views(self => ({
  get packetsCount() {
    return Object.keys(self.packets).length
  },
}))

// SAMOTNÁ NABÍDKA
export const UpgradeOffers = types
  .model({
    id: types.optional(types.string, ''),
    defaultLicense: types.maybeNull(types.frozen(DefaultLicense)),
    actualLicense: types.frozen(ActiveLicense),
    targetLicense: types.frozen(TargetLicense),
    customer: types.frozen(Customer), 
    createdDate: types.maybeNull(types.string),
    distributionMethod: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    totalWithVat: types.maybeNull(types.number),
    totalVat: types.maybeNull(types.number),
    totalWithoutVat: types.maybeNull(types.number),
    upgradeScenario: types.frozen(UpgradeScenario),
    licensePrice: types.frozen(LicensePrice),
    offerItems: types.array(IItems),
    offerServices: types.array(Services),
    invoices: types.optional(types.array(Invoices),[]),
    tempDate: types.maybeNull(types.string),
    finalState: types.maybeNull(types.string),
    loadingState: types.optional(types.boolean, false),
    lastReturnNote: types.optional(types.string,''),
  })
  .views(self => ({
    get servicesCount() {
      return Object.keys(self.offerServices).length
    },
    get invoicesCount() {
      return Object.keys(self.invoices).length
    },

    get finalState2() {
      let state = ''

      if (self.state === 'D' && self.distributionMethod === 'D' && Object.keys(self.invoices).length > 0) {
        let countPacketsTotal = 0
        //let countPacketsExported = 0
        let countPacketsSubmited = 0

        
          countPacketsTotal = Object.keys(self.invoices[0].packets!).length
          countPacketsSubmited = Object.keys(self.invoices[0].packets!.filter(p => p.submission?.isSubmitted === true)).length

          if (countPacketsTotal > 0) {
            if (countPacketsSubmited > 0) {
              state = 'G'
            }
            else {
              state = 'T'
            }
          }
          else {
            state = 'D'
          }
        
      }
      else {
        state = self.state!
      }
      
      return state
    },

    get showControlButtons() {
      let state:boolean = false
      
      if (self.state === 'C') state = true
      if (self.state === 'P') state = true
      

      return state
    },
    get isUpgradeFree() {
       
      //let upgradeModuleCount:number = Object.keys(self.licensePrice.items!.filter(data => Object.keys(data.items!.filter(d => d?.priceType! === 'P')).length > 0 )).length
      /*
      if (upgradeModuleCount === 0) {
        return true
      }
      else {
        return false
      }
      */
      if (self.totalWithVat === 0) { return true } else {return false} 

    },
    get isBeforeDistributionDate() {
      if (new Date(self.upgradeScenario.distributionDate!) >= new Date(moment(new Date()).add(14,'days').toISOString())) { return true } else { return false }
    }
  }))
  .actions(self => ({
    setData(data: any) {
      self = data
      
      //console.log(self)      
      /*
      const index = self.contacts.findIndex(contact => contact.id === id)
      self.contacts[index].type = newType
      self.contacts[index].value = newValue
      self.contacts[index].note = newNote
      self.contacts[index].isBilling = newIsBilling
      */
    },
    setInvoices(data: any) {
      self.invoices = data
      
      //console.log(self)      
      /*
      const index = self.contacts.findIndex(contact => contact.id === id)
      self.contacts[index].type = newType
      self.contacts[index].value = newValue
      self.contacts[index].note = newNote
      self.contacts[index].isBilling = newIsBilling
      */
    },
    setTempDate() {
      self.tempDate = String(new Date())
    },
    setFinalState() {
      let state = ''

      if (self.state === 'D' && self.distributionMethod === 'D' && Object.keys(self.invoices).length > 0) {
        let countPacketsTotal = 0
        //let countPacketsExported = 0
        let countPacketsSubmited = 0
        let countPacketsReturned = 0
        
          countPacketsTotal = Object.keys(self.invoices[0].packets!).length
          countPacketsSubmited = Object.keys(self.invoices[0].packets!.filter(p => p.submission?.isSubmitted === true)).length
          countPacketsReturned = Object.keys(self.invoices[0].packets!.filter(p => p.returnDate !== null)).length

          /*
          if (countPacketsTotal > 0) {
            if (countPacketsSubmited > 0) {
              if (countPacketsSubmited === countPacketsReturned) {
                state = 'E'

                self.lastReturnNote = self.invoices[0].packets!.sort(function(a:any, b:any) {
                  var sort1A = a.returnDate; 
                  var sort1B = b.returnDate; 
              
                   
                  if (sort1A! < sort1B!) {
                    return -1;
                  }
                  if (sort1A! > sort1B!) {
                    return 1;
                  }          
                  return 0;
                }).find(p => p.returnDate !== null)?.returnNote!


              }
              else {
                state = 'G'
              }  
            }
            else {
              state = 'T'
            }
          }
          else {
            state = 'D'
          }
*/

          if (countPacketsTotal > 0) {





                if (countPacketsTotal === countPacketsSubmited) {
                  if (countPacketsSubmited === countPacketsReturned) {
                    state = 'E'

                    self.lastReturnNote = self.invoices[0].packets!.sort(function(a:any, b:any) {
                      var sort1A = a.returnDate; 
                      var sort1B = b.returnDate; 
                  
                      
                      if (sort1A! < sort1B!) {
                        return 1;
                      }
                      if (sort1A! > sort1B!) {
                        return -1;
                      }          
                      return 0;
                    }).find(p => p.returnDate !== null)?.returnNote!


                  }
                  else {
                    state = 'G'
                  }  
                }
                else {
                  state = 'T'
                }



          }
          else {
            state = 'D'
          }




        
      }
      else {
        state = self.state!
      }
      
      self.finalState = state
    },

  }))
