import { authFetch, logout } from '../authProvider'
import moment from 'moment'
import axios from 'axios'
import { sleep } from '../helpers/sleep';
//import { useUpgradeStore } from '../store'



export const getUrl = (to:string) => {
  let url = ''
  let URL_API = sessionStorage.getItem('URL')
  let URL_DOKLADY_API = sessionStorage.getItem('DOKLADY_URL')

  /* PRO DUEL */
  if (to === 'URL_DOKLADY_API') url = URL_DOKLADY_API+""
  if (to === 'URL_DOKLADY_CUSTOMERS') url = URL_DOKLADY_API+"customers"




  if (to === 'URL_API') url = URL_API+""
  /* ACCOUNTS */
  if (to === 'URL_ACCOUNT') url = URL_API+"accounts/"
  if (to === 'URL_ACCOUNTS_LIST') url = URL_API+"accounts"
  if (to === 'URL_LOGIN') url = URL_API+"accounts/login"
  if (to === 'URL_REFRESH_TOKEN') url = URL_API+"accounts/refresh-token"
  if (to === 'URL_LOGOUT') url = URL_API+"accounts/logout"
  if (to === 'URL_FORGOTTEN_PASSWORD') url = URL_API+"accounts/forgotten-password"
  if (to === 'URL_RESET_PASSWORD') url = URL_API+"accounts/reset-password"
  if (to === 'URL_REGISTRATION') url = URL_API+"accounts/registration"
  if (to === 'URL_CONFIRM_REGISTRATION') url = URL_API+"accounts/confirm-registration"
  if (to === 'URL_CHANGE_PASSWORD') url = URL_API+"accounts/change-password"
  if (to === 'URL_RESEND_CONFIRMATION_EMAIL') url = URL_API+"accounts/resend-confirmation-email"

  /* INVITES */
  if (to === 'URL_INVITES') url = URL_API+"invites"
  if (to === 'URL_INVITES_EMAIL_FROM_TOKEN') url = URL_API+"accounts/email-from-invite/"

  /* CUSTOMERS */
  if (to === 'URL_CUSTOMER_LIST') url = URL_API+"customers"
  if (to === 'URL_CUSTOMER_OPEN') url = URL_API+"customers/open/"
  if (to === 'URL_CUSTOMER_CLOSE') url = URL_API+"customers/close/"
  if (to === 'URL_CUSTOMER_CATEGORIES') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_USAGE_MODE') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_USERS') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_OFFER_COUNT') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_CHANGE_STATE') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_LICENSES') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_SERVICES') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_GDPR') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_UPGRADE_OFFERS') url = URL_API+"customers/"
  if (to === 'URL_CUSTOMER_EMAILS_SENT') url = URL_API+"admin/emails/customers/"
  if (to === 'URL_CUSTOMER_NOTES') url = URL_API+"customers/"

  /* OVERVIEWS */
  if (to === 'URL_OVERVIEWS_CUSTOMERS') url = URL_API+"overviews/customers"
  if (to === 'URL_OVERVIEWS_OFFERS') url = URL_API+"overviews/offers"

  /* DATAFILTERS */
  if (to === 'URL_DATAFILTERS_CUSTOMERS') url = URL_API+"datafilters/customers/"
  if (to === 'URL_DATAFILTERS_OFFERS') url = URL_API+"datafilters/offers/"

  if (to === 'URL_DATAFILTERS_INVOICES') url = URL_API+"invoices/upgrade/"

  /* TEMPLATES */
  if (to === 'URL_TEMPLATES') url = URL_API+"templates"

  /* OPERATIONS */
  if (to === 'URL_OPERATIONS') url = URL_API+"operations"

  /* BULK OPERATIONS */
  if (to === 'URL_BULK_INVITES') url = URL_API+"bulkoperations/invites"
  if (to === 'URL_BULK_REMINDERS') url = URL_API+"bulkoperations/reminders"
  if (to === 'URL_BULK_EMAILS') url = URL_API+"emailing/send"
  if (to === 'URL_BULK_OFFERS') url = URL_API+"bulkoperations/offers"

  /* CUSTOMER LICENSES */
  //if (to === 'URL_LICENSES_TRANSFERS_LIST') url = URL_API+"licenses/"
  if (to === 'URL_LICENSES_STATE') url = URL_API+"licenses/"
  if (to === 'URL_TRANSFER_CUSTOMER_LICENSES') url = URL_API+"licenses/customer/"
  if (to === 'URL_TRANSFER_LICENSE') url = URL_API+"licenses/transfer"
  if (to === 'URL_LICENSES_CHANGE') url = URL_API+"licenses/"
  if (to === 'URL_LICENSES_ADD') url = URL_API+"licenses"
 
  /* CUSTOMER SERVICES */
  if (to === 'URL_SERVICES') url = URL_API+"services/customer/"

  /* OFFERS */
  if (to === 'URL_OFFERS') url = URL_API+"offers/"
  if (to === 'URL_OFFERS_SCENARIOS') url = URL_API+"offers/scenarios"
  if (to === 'URL_OFFERS_CALCULATE') url = URL_API+"offers/calculate"

  /* DIALS */
  if (to === 'URL_DIALS_CATEGORIES') url = URL_API+"dials/categories"
  if (to === 'URL_DIALS_PROGRAMS') url = URL_API+"dials/programs"
  if (to === 'URL_DIALS_PROGRAM_VERSIONS') url = URL_API+"dials/program-versions"
  if (to === 'URL_DIALS_LICENSE_TYPES') url = URL_API+"dials/license-types"
  if (to === 'URL_DIALS_SERVICES') url = URL_API+"dials/services"
  if (to === 'URL_DIALS_MODULES') url = URL_API+"dials/modules"
  if (to === 'URL_DIALS_PRICE_LISTS_MODULES') url = URL_API+"dials/price-lists/modules/actual/"
  if (to === 'URL_DIALS_PRICE_LISTS_SERVICES') url = URL_API+"dials/price-lists/services/actual/"

  if (to === 'URL_DIALS_PRICE_LISTS_MODULES_ALL') url = URL_API+"dials/price-lists/modules"
  if (to === 'URL_DIALS_PRICE_LISTS_SERVICES_ALL') url = URL_API+"dials/price-lists/services/"

  if (to === 'URL_DIALS_SUPPORT_TYPES') url = URL_API+"dials/support-type"
  if (to === 'URL_DIALS_SUPPORT_THEMES') url = URL_API+"dials/support-theme"
  if (to === 'URL_DIALS_FREETEXTS') url = URL_API+"freetexts"
  if (to === 'URL_DIALS_OPERATIONS_PREFERENCES') url = URL_API+"processes/preferences"

  if (to === 'URL_DIALS_DELIVERY_CZECH_POST_SERVICES') url = URL_API+"delivery/czech-post/services"
  if (to === 'URL_DIALS_DELIVERY_CZECH_POST_PRODUCTS') url = URL_API+"delivery/czech-post/products"
  if (to === 'URL_DIALS_DELIVERY_CZECH_POST_TEMPLATES') url = URL_API+"delivery/czech-post/packets/templates"

  if (to === 'URL_DIALS_MEETINGS_CATEGORIES') url = URL_API+"dials/meeting-categories"
  if (to === 'URL_DIALS_USER_GROUPS') url = URL_API+"accounts/user-group"

  if (to === 'URL_DIALS_INVOICES_PAYMENT_TERMS') url = URL_API+"dials/invoices/payment-terms"
  if (to === 'URL_DIALS_INVOICES_VAT_ROUNDING_TYPES') url = URL_API+"dials/invoices/vat-rounding-types"
  if (to === 'URL_DIALS_INVOICES_UNITS') url = URL_API+"dials/invoices/units"

  if (to === 'URL_SUBMISSIONS') url = URL_API+"delivery/czech-post/submissions"
  if (to === 'URL_PACKETS') url = URL_API+"delivery/czech-post/packets"

  /* DOCUMENTS */
  if (to === 'URL_DOCUMENTS') url = URL_API+"customers/"
  if (to === 'URL_DOCUMENTS_BH') url = URL_API+"documents/"

  /* SUPPORT */
  if (to === 'URL_SUPPORT_CONTACTS_ADD') url = URL_API+"supports"
  if (to === 'URL_SUPPORT_CONTACTS') url = URL_API+"supports"

  /* FILES */
  if (to === 'URL_FILES') url = URL_API+"files"

  /* MEEETINGS */
  if (to === 'URL_MEETINGS') url = URL_API+"meetings"

  /* TASKS */
  if (to === 'URL_TASKS') url = URL_API+"tasks"  

  /* ADMIN */
  if (to === 'URL_ADMIN_EMAILS_SENT') url = URL_API+"admin/emails/email"
  if (to === 'URL_ADMIN_EMAILS') url = URL_API+"admin/emails"
  if (to === 'URL_CHANGES') url = URL_API+"changes/"
  if (to === 'URL_ADMIN_VERIFY_CODES_DUEL') url = URL_API+"admin/verifyCodes/duel/"
  if (to === 'URL_ADMIN_VERIFY_CODES_STEREO') url = URL_API+"admin/verifyCodes/stereo/"
  if (to === 'URL_ADMIN_VERIFY_CODES') url = URL_API+"admin/verifyCodes/"

  /* EMAILING */
  if (to === 'URL_EMAILING_WHITELIST') url = URL_API+"emailing/white-list"
  if (to === 'URL_EMAILING_BLACKLIST') url = URL_API+"emailing/black-list"

  if (to === 'URL_EMAILING_UNSUBSCRIBE') url = URL_API+"emailing/black-list/unsubscribe/"
  if (to === 'URL_EMAILING_EMAIL_CUSTOMERS_COUNT') url = URL_API+"emailing/"
  

  if (to === 'URL_DATAFILTERS_CUSTOMERS_CUSTOM_LIST') url = URL_API+"datafilters/customers/custom"
  if (to === 'URL_DATAFILTERS_CUSTOMERS_CUSTOM_VARIABLES') url = URL_API+"datafilters/customers/custom/filters"
  if (to === 'URL_DATAFILTERS_CUSTOMERS_CUSTOM_DESIGNER_PREVIEW') url = URL_API+"datafilters/customers/custom/designer/execute"
  if (to === 'URL_DATAFILTERS_CUSTOMERS_CUSTOM_FILTER_PREVIEW') url = URL_API+"datafilters/customers/custom/"

  /* INVOICES */
  if (to === 'URL_INVOICES') url = URL_API+"invoices"

  /* CALCULATORS */

/*
  export const JEJDA = "Nastala chyba!"
  export const LOGIN_JEJDA = "Nastala chyba!"

  export const CLIENT_URL = "https://ts-sway.cz/files/apiUsers.php"
  export const CLIENT_UPDATE_URL = "https://ts-sway.cz/files/apiUsersUpdate.php"
  export const ORDER_URL = "https://ts-sway.cz/files/apiOrders.php"
  export const ORDER_UPDATE_URL = "https://ts-sway.cz/files/apiOrdersUpdate.php"
*/
  return url

}


// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

const jwtExp = (token?: any): number | null => {
  if (!(typeof token === 'string')) {
      return null;
  }

  const split = token.split('.');

  if (split.length < 2) {
      return null;
  }

  try {
      const jwt = JSON.parse(atob(token.split('.')[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
          //console.log(jwt.exp * 1000)
          return jwt.exp * 1000;
      } else {
          return null;
      }
  } catch (e) {
      return null;
  }
};

const getExpire = (token: string | null) => {
  if (!token) {
      return null;
  }

  return jwtExp(token);
};

const isExpired = (exp: number | null) => {
  if (!exp) {
      return false;
  }
  //console.log('A')
  //console.log(Date.now())
  //console.log('B')
  //console.log(exp)
  return Date.now() > exp;
};

// Test na Expiraci tokenu
export const checkIsTokenExpired = () => {
  const localStorageDataTest = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
  const atokenTest = ((JSON.parse(localStorageDataTest!)) )
  //console.log('Expirace')
  //console.log(atokenTest)
  if (atokenTest === null)
    { return true }
  else { return isExpired(getExpire(atokenTest.accessToken)!) }  
}

// return the token from the session storage
export const getToken = async () => {
  if (localStorage.getItem('REACT_TOKEN_AUTH_KEY') !== null) {
    if (checkIsTokenExpired()) {
      await authFetch('helpers/fData.json')
      //const temp = await authFetch('helpers/fData.json')
      //console.log(temp)  
    }

    if (localStorage.getItem('REACT_TOKEN_AUTH_KEY') !== null) {
    const localStorageData = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    const atoken = ((JSON.parse(localStorageData!)) )
    //console.log(atoken.accessToken)
    return atoken.accessToken;
    }
    else {
      return null  
    }
  }
  else {
    return null
  }
    
  
}
/*
export async function checkVersion() {
  await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmm')).then(response => {
    (async () => {
      if (sessionStorage.getItem('version') !==  response.data.version) { await window.location.reload() }
    })()

    //if (sessionStorage.getItem('version') !==  response.data.version) { window.location.reload() }
    //sessionStorage.setItem('version', response.data.version)
  })
  await console.log('Check version')
  return null  
}
*/
export const checkVersionFromFile = async () => {
  const version = await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    return response.data.version
    //if (sessionStorage.getItem('version') !==  response.data.version) { window.location.reload() }
    //sessionStorage.setItem('version', response.data.version)
  })
  await console.log(version)
  
  return version  
  
}


export const checkVersion2 = async () => {
  console.log('A1')
  const version = await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    console.log('A2')
    return response.data.version
    //if (sessionStorage.getItem('version') !==  response.data.version) { window.location.reload() }
    //sessionStorage.setItem('version', response.data.version)
  }).catch(error => {
    console.log('A3')
    // eslint-disable-next-line
    window.location.href = window.location.href; window.location.reload();

  });
  console.log('A4')
  await console.log(version)
  
  //await sleep(10000)
  // eslint-disable-next-line
  if (sessionStorage.getItem('version') !== version) { window.location.href = window.location.href; await window.location.reload(); await sleep(1000) }
  return null  
  
}

export const checkVersionData = async () => {
  console.log('D1')
  sessionStorage.setItem('versionFromJSON', '0.0.0')
  //let version:string = '0.0.0'
  await  fetch('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS'))
  .then((response) => response.json())
  .then((data) => {console.log(data.version); sessionStorage.setItem('versionFromJSON',data.version)});

}

export const checkVersion = async () => {
  console.log('A1')
  //let version:string = ''
  //const version =await  fetch('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS'))
  //.then((response) => response.json())
  //.then((data) => {console.log(data.version);return data.version});

  //let version:string = '0.0.0'

  await checkVersionData()

  console.log('A2')

  /*const version = await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    console.log('A2')
    return response.data.version
    //if (sessionStorage.getItem('version') !==  response.data.version) { window.location.reload() }
    //sessionStorage.setItem('version', response.data.version)
  }).catch(error => {
    console.log('A3')
    // eslint-disable-next-line
    window.location.href = window.location.href; window.location.reload();

  });*/
  //console.log('A4')
  await console.log(sessionStorage.getItem('versionFromJSON'))
  
  //await sleep(10000)
  if (sessionStorage.getItem('version') !== sessionStorage.getItem('versionFromJSON')) { 
    await console.log('Restartuji FE')
    
    
    //this.document.reload(true)
    // eslint-disable-next-line
    sessionStorage.setItem('restarted', '1')
    // eslint-disable-next-line
    window.location.href = window.location.href; 
    window.location.reload(); 
    await sleep(10000)
     }

  //console.log(sessionStorage.getItem('URL'))
     
  /*
  if (sessionStorage.getItem('restarted') !== '1') { 
  await console.log('Restartuji FE')
  //this.document.reload(true)
  // eslint-disable-next-line
  sessionStorage.setItem('restarted', '1')
  // eslint-disable-next-line
  window.location.href = window.location.href; 
  window.location.reload(); 
  await sleep(1000)
    }
  */

  return null  
  
}

export const checkVersionWithoutRestart = async () => {
  console.log('A1')

  await checkVersionData()

  console.log('A2')

  await console.log(sessionStorage.getItem('versionFromJSON'))
  
  //await sleep(10000)
  if (sessionStorage.getItem('version') !== sessionStorage.getItem('versionFromJSON')) { 
    await console.log('Restartuji FE')
    
    
    //this.document.reload(true)
    // eslint-disable-next-line
    sessionStorage.setItem('restarted', '1')
    // eslint-disable-next-line
    window.location.href = window.location.href; 
    window.location.reload(); 
    await sleep(10000)
     }

  return null  
  
}



// remove the token and user from the session storage
export const removeUserSession = () => {

  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('username')
  localStorage.removeItem('isLogged')
  localStorage.removeItem('customersTotalCount')
  localStorage.removeItem('customersTotalCountInit')
  localStorage.removeItem('customersTooMany')
  localStorage.removeItem('customersTooManyInit')
  localStorage.removeItem('customersFirstId')
  localStorage.removeItem('openedCustomerId')

  localStorage.removeItem('BigHedgehog')
  localStorage.removeItem('BigHedgehogBefore')
  localStorage.removeItem('SmallHedgehog')
  localStorage.removeItem('SmallHedgehogBefore')
  localStorage.removeItem('BigUser')
  localStorage.removeItem('BigUserBefore')
  localStorage.removeItem('SmallUser')

  localStorage.removeItem('Emailing')
  localStorage.removeItem('Meeting')
  localStorage.removeItem('Management')

  localStorage.removeItem('invitesTotalCount')
  localStorage.removeItem('isLogged')
  sessionStorage.removeItem('isIn')
  sessionStorage.removeItem('customersSearchValue')
  sessionStorage.removeItem('customersSearchTypeValue')
  sessionStorage.removeItem('customersSearchPage')

  localStorage.removeItem('lastDatafilter')
  localStorage.removeItem('lastDatafilterType')
  localStorage.removeItem('lastDatafilterTotalCount')
  localStorage.removeItem('lastDatafilterOfferTotalCount')
  localStorage.removeItem('lastDatafilterTimeStamp')
  localStorage.removeItem('lastDatafilterTime')
  localStorage.removeItem('openedFromOverviews')

  sessionStorage.removeItem('selectionDetailSearchValue')
  sessionStorage.removeItem('selectionDetailSearchTypeValue')
  sessionStorage.removeItem('selectionDetailPage')

  sessionStorage.removeItem('selectionDetailOfferSearchTextValue')
  sessionStorage.removeItem('selectionDetailOfferSearchTypeValue')
  sessionStorage.removeItem('selectionDetailOfferSearchScenarioValue')
  sessionStorage.removeItem('selectionDetailOfferPage')

  sessionStorage.removeItem('historySearchTypeValue')
  sessionStorage.removeItem('historySearchOperationTypeValue')
  sessionStorage.removeItem('historySearchPage')

  sessionStorage.removeItem('emailsSentSearchValue')
  sessionStorage.removeItem('emailsSentSearchTypeValue')
  sessionStorage.removeItem('emailsSentSearchTIDValue')
  sessionStorage.removeItem('emailsSentSearchPage')
  localStorage.removeItem('customerEmailsSentTotalCount')
  localStorage.removeItem('lastTemplatesSearchTotalCount')
  
  localStorage.removeItem('customerHistoryTotalCount')
  localStorage.removeItem('customerHistoryNextSiteTotalCount')

  localStorage.removeItem('lastSupportTotalCount')
  localStorage.removeItem('supportSearchArray')
  
  sessionStorage.removeItem('supportSearchPage')
  sessionStorage.removeItem('contactDetailEdited')

  localStorage.removeItem('supportSearchPageLast')
  localStorage.removeItem('showtemplatesFilter')
  localStorage.removeItem('templatesSearchArray')
  localStorage.removeItem('customerOpenSearchArray')
  localStorage.removeItem('showcontactsFilter')
  localStorage.removeItem('supportSearchArrayLast')
  localStorage.removeItem('licensesTransfersTotalCount')
  localStorage.removeItem('showSupportFilter')
  localStorage.removeItem('showcustomerOpenFilter')
  localStorage.removeItem('checkOperationsPreferences')
  
  localStorage.setItem('userNameMenu', 'nepřihlášen')
}


// set the token and user from the local storage
export const setUserSession = (token:string, user:any) => {
  localStorage.setItem('token', token)
  localStorage.setItem('user', JSON.stringify(user))
  //console.log(localStorage.getItem('user'))
  //localStorage.setItem('userNameMenu', user.firstName+' '+user.surName)

  localStorage.removeItem('BigHedgehog')
  localStorage.removeItem('BigHedgehogBefore')
  localStorage.removeItem('SmallHedgehog')
  localStorage.removeItem('SmallHedgehogBefore')
  localStorage.removeItem('BigUser')
  localStorage.removeItem('BigUserBefore')
  localStorage.removeItem('SmallUser')
  localStorage.removeItem('Emailing')
  localStorage.removeItem('Meeting')
  localStorage.removeItem('Management')
  localStorage.removeItem('Admin')

  if (user.roles.includes("BigHedgehog")) {localStorage.setItem('BigHedgehog', '1')}
  if (user.roles.includes("SmallHedgehog")) {localStorage.setItem('SmallHedgehog', '1')}

  if (user.roles.includes("BigUser")) {localStorage.setItem('BigUser', '1')}
  if (user.roles.includes("SmallUser")) {localStorage.setItem('SmallUser', '1')}

  if (user.roles.includes("Emailing")) {localStorage.setItem('Emailing', '1')}

  if (user.roles.includes("Meeting")) {localStorage.setItem('Meeting', '1')}
  if (user.roles.includes("Management")) {localStorage.setItem('Management', '1')}

  if (user.roles.includes("Admin")) {localStorage.setItem('Admin', '1')}
  
  localStorage.setItem('isLogged', 'is')
}

export const setUserRoles = (roles:any) => {
  
  localStorage.removeItem('BigHedgehog')
  localStorage.removeItem('BigHedgehogBefore')
  localStorage.removeItem('SmallHedgehog')
  localStorage.removeItem('SmallHedgehogBefore')
  localStorage.removeItem('BigUser')
  localStorage.removeItem('BigUserBefore')
  localStorage.removeItem('SmallUser')
  localStorage.removeItem('Emailing')
  localStorage.removeItem('Meeting')
  localStorage.removeItem('Management')
  localStorage.removeItem('Admin')

  if (roles.includes("BigHedgehog")) {localStorage.setItem('BigHedgehog', '1')}
  if (roles.includes("SmallHedgehog")) {localStorage.setItem('SmallHedgehog', '1')}

  if (roles.includes("BigUser")) {localStorage.setItem('BigUser', '1')}
  if (roles.includes("SmallUser")) {localStorage.setItem('SmallUser', '1')}

  if (roles.includes("Emailing")) {localStorage.setItem('Emailing', '1')}
  if (roles.includes("Meeting")) {localStorage.setItem('Meeting', '1')}
  if (roles.includes("Management")) {localStorage.setItem('Management', '1')}
  if (roles.includes("Admin")) {localStorage.setItem('Admin', '1')}

  
}

export const handleMenuContent = () => {
  if (document.body.classList.contains('sb-sidenav-toggled') && window.innerWidth < 992) {
    document.body.classList.toggle('sb-sidenav-toggled');
  }
  
}



export const errorEvaluation = (errorData:any) => {
  if (errorData.response !== undefined && errorData.response.data.statusCode === 403) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }
  if (errorData.response !== undefined && errorData.response.data.statusCode === 404) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }  
  if (errorData.response !== undefined && errorData.response.data.statusCode === 500) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }
  if ( !!errorData.isAxiosError && !errorData.response ) {
    sessionStorage.setItem('automaticLogout', '3')
    removeUserSession()
    logout()
  }
}

export const getModuleOrder = (moduleCode:string) => {
  let order:number = 99
  if (moduleCode === "Y") order = 1
  if (moduleCode === "K") order = 2
  if (moduleCode === "U") order = 3
  if (moduleCode === "F") order = 4
  if (moduleCode === "M") order = 5
  if (moduleCode === "S") order = 6
  if (moduleCode === "P") order = 7
  if (moduleCode === "E") order = 8
  if (moduleCode === "J") order = 9
  if (moduleCode === "A") order = 10
  if (moduleCode === "R") order = 11
  return order
}

export const getPriceListDetailOrder = (priceType:string, isFirst:boolean) => {
  let order:number = 99
  //console.log(priceType)
  //console.log(isFirst)
  if (priceType === "P" && isFirst)  order = 1
  if (priceType === "P" && !isFirst) order = 2
  if (priceType === "F" && isFirst)  order = 3
  if (priceType === "F" && !isFirst) order = 4
  if (priceType === "S" && isFirst)  order = 5
  if (priceType === "S" && !isFirst) order = 6
  if (priceType === "E" && isFirst)  order = 7
  if (priceType === "E" && !isFirst) order = 8  
 
  return order
}

export const getNumberFormated = (price:number) => {
  let tisice:number = Math.floor(price/1000)
  //console.log(tisice)
  //console.log(price-(tisice*1000))
  let stovky:number = Math.floor(price-(tisice*1000))
  let stovkyTxt:string = stovky === 0 ? '000': stovky.toString()

  let halere:number = price-Math.floor(price)

  let tisiceTxtPart1:string = tisice !== 0 ? tisice.toString() : ''
  let tisiceTxtPart2:string = tisice !== 0 ? '.' : ''
  let textove:string = tisiceTxtPart1+tisiceTxtPart2+stovkyTxt+","+halere.toString()

  if (halere < 10) {
    textove = tisiceTxtPart1+tisiceTxtPart2+stovky.toString()+",0"+halere.toString()
  }
  
  return textove
}



export const getDataFiltersNewProgramId = (filterId:number) => {
  
  let upgScDuel:string = localStorage.getItem('upgradeScenarioDuelSelected')!
  let upgScStereo:string = localStorage.getItem('upgradeScenarioStereoSelected')!
  
  let scenarioId:string = upgScStereo
  
  if (filterId >= 1001 && filterId <= 1006) scenarioId = upgScDuel 
  if (filterId >= 1019 && filterId <= 1024) scenarioId = upgScDuel 
  if (filterId >= 1037 && filterId <= 1039) scenarioId = upgScDuel
  
  return scenarioId
}
/*
export const getDataFiltersNewProgramId = (filterId:number) => {
  
  let programId:string = '2b158389-2f71-4fa1-8778-5a4d179cd777' //STEREO
  
  if (filterId >= 1001 && filterId <= 1006) programId = '58d3ca24-b1e0-469e-b752-a602054d8be5' 
  if (filterId >= 1019 && filterId <= 1024) programId = '58d3ca24-b1e0-469e-b752-a602054d8be5' 
  if (filterId >= 1037 && filterId <= 1039) programId = '58d3ca24-b1e0-469e-b752-a602054d8be5' 
  
  return programId
  
}
*/

export const getDataFiltersNewIsStereoLan = (filterId:number) => {
  let isStereoLan:boolean = false 

  if (filterId >= 1013 && filterId <= 1018) isStereoLan = true
  if (filterId >= 1031 && filterId <= 1036) isStereoLan = true
  if (filterId >= 1043 && filterId <= 1045) isStereoLan = true
  
  return isStereoLan
}

export const getDataFiltersNewFirstUrlPart = (filterId:number) => {
  let url:string = '' 

  if (filterId >= 1001 && filterId <= 1018) url = 'cash/'
  if (filterId >= 1019 && filterId <= 1036) url = 'electronic/'
  if (filterId >= 1037 && filterId <= 1045) url = 'free/'
  
  return url
}

export const getDataFiltersNewLastUrlPart = (filterId:number) => {
  let url:string = '' 

  if (filterId === 1038 || filterId === 1041 || filterId === 1044) url = '/final'
  if (filterId === 1039 || filterId === 1042 || filterId === 1045) url = '/finalize'

  if (filterId === 1020 || filterId === 1026 || filterId === 1032) url = '/proforma'
  if (filterId === 1021 || filterId === 1027 || filterId === 1033) url = '/proforma/publish'
  if (filterId === 1022 || filterId === 1028 || filterId === 1034) url = '/paid'
  if (filterId === 1023 || filterId === 1029 || filterId === 1035) url = '/final'
  if (filterId === 1024 || filterId === 1030 || filterId === 1036) url = '/finalize'
  
  if (filterId === 1002 || filterId === 1008 || filterId === 1014) url = '/final'
  if (filterId === 1003 || filterId === 1009 || filterId === 1015) url = '/distribution-started'
  if (filterId === 1004 || filterId === 1010 || filterId === 1016) url = '/given-to-carrier'
  if (filterId === 1005 || filterId === 1011 || filterId === 1017) url = '/return-from-carrier'
  if (filterId === 1006 || filterId === 1012 || filterId === 1018) url = '/finalize'

  return url
}

export const getDataFiltersNewOperationNameForControl = (filterId:number) => {
  let operationName:string = '' 
  //console.log(filterId)
  
  // Fyzická
  

  // Elektronická
  if (Number(filterId) === 1020) operationName = 'CreateElectronicProformaInvoiceForDuel'
  if (Number(filterId) === 1021) operationName = 'PublishElectronicProformaInvoicesForDuel'
  if (Number(filterId) === 1022) operationName = ''
  if (Number(filterId) === 1023) operationName = 'CreateElectronicFinalDocumentsForDuel'

  if (Number(filterId) === 1026) operationName = 'CreateElectronicProformaInvoiceForStereo'
  if (Number(filterId) === 1027) operationName = 'PublishElectronicProformaInvoicesForStereo'
  if (Number(filterId) === 1028) operationName = ''
  if (Number(filterId) === 1029) operationName = 'CreateElectronicFinalDocumentsForStereo'

  if (Number(filterId) === 1032) operationName = 'CreateElectronicProformaInvoiceForStereoLan'
  if (Number(filterId) === 1033) operationName = 'PublishElectronicProformaInvoicesForStereoLan'
  if (Number(filterId) === 1034) operationName = ''
  if (Number(filterId) === 1035) operationName = 'CreateElectronicFinalDocumentsForStereoLan'

  // Zadarmisti
  if (Number(filterId) === 1038) operationName = 'CreateTotalZeroFinalDocumentsForDuel'
  if (Number(filterId) === 1041) operationName = 'CreateTotalZeroFinalDocumentsForStereo'
  if (Number(filterId) === 1044) operationName = 'CreateTotalZeroFinalDocumentsForStereoLan'
  
  
  //console.log(operationName)
  return operationName
}

export const showColumnDataFiltersNew = (filterId:string, columnName:string) => {
  let show:boolean = false 

  if (columnName === 'accountNumber') {
    show = true
  }
  if (columnName === 'businessName') {
    show = true
  }
  if (columnName === 'programName') {
    show = false
    if (Number(filterId) === 1019 || Number(filterId) === 1025 || Number(filterId) === 1031) show=true
    if (Number(filterId) === 1001 || Number(filterId) === 1007 || Number(filterId) === 1013) show=true
  }
  if (columnName === 'isDiscount') {
    if (Number(filterId) === 1019 || Number(filterId) === 1025 || Number(filterId) === 1031) show = true
    if (Number(filterId) === 1020 || Number(filterId) === 1026 || Number(filterId) === 1032) show = true
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true

    if (Number(filterId) === 1001 || Number(filterId) === 1007 || Number(filterId) === 1013) show=true
    if (Number(filterId) === 1002 || Number(filterId) === 1008 || Number(filterId) === 1014) show=true
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show=true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show=true
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true
    if (Number(filterId) === 1006 || Number(filterId) === 1012 || Number(filterId) === 1018) show=true
  }
  if (columnName === 'isLicenseChanged') {
    if (Number(filterId) === 1019 || Number(filterId) === 1025 || Number(filterId) === 1031) show = true
    if (Number(filterId) === 1020 || Number(filterId) === 1026 || Number(filterId) === 1032) show = true
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true

    if (Number(filterId) === 1001 || Number(filterId) === 1007 || Number(filterId) === 1013) show=true
    if (Number(filterId) === 1002 || Number(filterId) === 1008 || Number(filterId) === 1014) show=true
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show=true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show=true  
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true  
    if (Number(filterId) === 1006 || Number(filterId) === 1012 || Number(filterId) === 1018) show=true
  }
  if (columnName === 'isLicenseChangedInProtectedPeriod') {
    if (Number(filterId) === 1019 || Number(filterId) === 1025 || Number(filterId) === 1031) show = true
    if (Number(filterId) === 1020 || Number(filterId) === 1026 || Number(filterId) === 1032) show = true
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true

    if (Number(filterId) === 1001 || Number(filterId) === 1007 || Number(filterId) === 1013) show=true
    if (Number(filterId) === 1002 || Number(filterId) === 1008 || Number(filterId) === 1014) show=true
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show=true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show=true
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true    
    if (Number(filterId) === 1006 || Number(filterId) === 1012 || Number(filterId) === 1018) show=true
  }
  if (columnName === 'isNewNetworkLicense') {
    if (Number(filterId) === 1031) show = true
    if (Number(filterId) === 1032) show = true
    if (Number(filterId) === 1033) show = true
    if (Number(filterId) === 1034) show = true
    if (Number(filterId) === 1035) show = true
    if (Number(filterId) === 1036) show = true

    if (Number(filterId) === 1013) show = true
    if (Number(filterId) === 1014) show = true
    if (Number(filterId) === 1015) show = true
    if (Number(filterId) === 1016) show = true
    if (Number(filterId) === 1017) show = true
    if (Number(filterId) === 1018) show = true
  }

  if (columnName === 'totalWithoutVat') {
    show = true
  }

  if (columnName === 'vatBase') {
    show = true
  }

  if (columnName === 'invoiceNumber') {
    if (Number(filterId) === 1020 || Number(filterId) === 1026 || Number(filterId) === 1032) show = true
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
  }
  if (columnName === 'dateOfIssue') {
    if (Number(filterId) === 1020 || Number(filterId) === 1026 || Number(filterId) === 1032) show = true
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
  }
  if (columnName === 'dateOfPublishing') {
    if (Number(filterId) === 1021 || Number(filterId) === 1027 || Number(filterId) === 1033) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true
  }
  if (columnName === 'dateOfPayment') {
    if (Number(filterId) === 1022 || Number(filterId) === 1028 || Number(filterId) === 1034) show = true
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true
  }
  if (columnName === 'proformaNumber') {
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true
  }
  if (columnName === 'invoiceNumber2') {
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true
    if (Number(filterId) === 1002 || Number(filterId) === 1008 || Number(filterId) === 1014) show = true
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show=true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show=true
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true
    if (Number(filterId) === 1006 || Number(filterId) === 1012 || Number(filterId) === 1018) show = true
  }
  if (columnName === 'dateOfIssue2') {
    if (Number(filterId) === 1023 || Number(filterId) === 1029 || Number(filterId) === 1035) show = true
    if (Number(filterId) === 1024 || Number(filterId) === 1030 || Number(filterId) === 1036) show = true
    if (Number(filterId) === 1002 || Number(filterId) === 1008 || Number(filterId) === 1014) show = true
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show=true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show=true
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true
    //if (Number(filterId) === 1006 || Number(filterId) === 1012 || Number(filterId) === 1018) show = true
  }
  if (columnName === 'distributionDate') {
    if (Number(filterId) === 1003 || Number(filterId) === 1009 || Number(filterId) === 1015) show = true
    if (Number(filterId) === 1004 || Number(filterId) === 1010 || Number(filterId) === 1016) show = true
  }

  if (columnName === 'returnDate') {
    if (Number(filterId) === 1005 || Number(filterId) === 1011 || Number(filterId) === 1017) show=true
  }

  //if (filterId === 1038 || filterId === 1041 || filterId === 1044) show = true
  
  return show
}

export const getDataFiltersNextList = (filterId:number) => {
  let url:string = '' 
  
  //FYZICKÁ
  if (Number(filterId) === 1001) url = '1002'
  if (Number(filterId) === 1002) url = '1003'
  if (Number(filterId) === 1003) url = '1004'
  if (Number(filterId) === 1004) url = '1005'
  if (Number(filterId) === 1005) url = '1006'

  if (Number(filterId) === 1007) url = '1008'
  if (Number(filterId) === 1008) url = '1009'
  if (Number(filterId) === 1009) url = '1010'
  if (Number(filterId) === 1010) url = '1011'
  if (Number(filterId) === 1011) url = '1012'

  if (Number(filterId) === 1013) url = '1014'
  if (Number(filterId) === 1014) url = '1015'
  if (Number(filterId) === 1015) url = '1016'
  if (Number(filterId) === 1016) url = '1017'
  if (Number(filterId) === 1017) url = '1018'

  //ELEKTRONICKÁ
  if (Number(filterId) === 1019) url = '1020'
  if (Number(filterId) === 1020) url = '1021'
  if (Number(filterId) === 1021) url = '1022'
  if (Number(filterId) === 1022) url = '1023'
  if (Number(filterId) === 1023) url = '1024'

  if (Number(filterId) === 1025) url = '1026'
  if (Number(filterId) === 1026) url = '1027'
  if (Number(filterId) === 1027) url = '1028'
  if (Number(filterId) === 1028) url = '1029'
  if (Number(filterId) === 1029) url = '1030'

  if (Number(filterId) === 1031) url = '1032'
  if (Number(filterId) === 1032) url = '1033'
  if (Number(filterId) === 1033) url = '1034'
  if (Number(filterId) === 1034) url = '1035'
  if (Number(filterId) === 1035) url = '1036'

  //ZADARMISTI
  if (Number(filterId) === 1037) url = '1038'
  if (Number(filterId) === 1038) url = '1039'

  if (Number(filterId) === 1040) url = '1041'
  if (Number(filterId) === 1041) url = '1042'

  if (Number(filterId) === 1043) url = '1044'
  if (Number(filterId) === 1044) url = '1045'
  
  return url
}

export const getDataFiltersPreviousList = (filterId:number) => {
  let url:string = '' 
  
  //FYZICKÁ
  if (Number(filterId) === 1002) url = '1001'
  if (Number(filterId) === 1003) url = '1002'
  if (Number(filterId) === 1004) url = '1003'
  if (Number(filterId) === 1005) url = '1004'
  if (Number(filterId) === 1006) url = '1005'

  if (Number(filterId) === 1008) url = '1007'
  if (Number(filterId) === 1009) url = '1008'
  if (Number(filterId) === 1010) url = '1009'
  if (Number(filterId) === 1011) url = '1010'
  if (Number(filterId) === 1012) url = '1011'

  if (Number(filterId) === 1014) url = '1013'
  if (Number(filterId) === 1015) url = '1014'
  if (Number(filterId) === 1016) url = '1015'
  if (Number(filterId) === 1017) url = '1016'
  if (Number(filterId) === 1018) url = '1017'

  //ELEKTRONICKÁ
  if (Number(filterId) === 1020) url = '1019'
  if (Number(filterId) === 1021) url = '1020'
  if (Number(filterId) === 1022) url = '1021'
  if (Number(filterId) === 1023) url = '1022'
  if (Number(filterId) === 1024) url = '1023'

  if (Number(filterId) === 1026) url = '1025'
  if (Number(filterId) === 1027) url = '1026'
  if (Number(filterId) === 1028) url = '1027'
  if (Number(filterId) === 1029) url = '1028'
  if (Number(filterId) === 1030) url = '1029'

  if (Number(filterId) === 1032) url = '1031'
  if (Number(filterId) === 1033) url = '1032'
  if (Number(filterId) === 1034) url = '1033'
  if (Number(filterId) === 1035) url = '1034'
  if (Number(filterId) === 1036) url = '1035'

  //ZADARMISTI
  if (Number(filterId) === 1038) url = '1037'
  if (Number(filterId) === 1039) url = '1038'

  if (Number(filterId) === 1041) url = '1040'
  if (Number(filterId) === 1042) url = '1041'

  if (Number(filterId) === 1044) url = '1043'
  if (Number(filterId) === 1045) url = '1044'
  
  return url
}

export const getDataFiltersNextListText = (filterId:number) => {
  let url:string = '' 
  
  //FYZICKÁ
  if (Number(filterId) === 1001) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1002) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1003) url = 'Zásilky předané dopravci'
  if (Number(filterId) === 1004) url = 'Vrácené zásilky'
  if (Number(filterId) === 1005) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1007) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1008) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1009) url = 'Zásilky předané dopravci'
  if (Number(filterId) === 1010) url = 'Vrácené zásilky'
  if (Number(filterId) === 1011) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1013) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1014) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1015) url = 'Zásilky předané dopravci'
  if (Number(filterId) === 1016) url = 'Vrácené zásilky'
  if (Number(filterId) === 1017) url = 'Vyřízené objednávky'

  //ELEKTRONICKÁ
  if (Number(filterId) === 1019) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1020) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1021) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1022) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1023) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1025) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1026) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1027) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1028) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1029) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1031) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1032) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1033) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1034) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1035) url = 'Vyřízené objednávky'

  //ZADARMISTI
  if (Number(filterId) === 1037) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1038) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1040) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1041) url = 'Vyřízené objednávky'

  if (Number(filterId) === 1043) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1044) url = 'Vyřízené objednávky'
  
  return url
}

export const getDataFiltersPreviousListText = (filterId:number) => {
  let url:string = '' 
  
  //FYZICKÁ
  if (Number(filterId) === 1002) url = 'Schválené objednávky'
  if (Number(filterId) === 1003) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1004) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1005) url = 'Zásilky předané dopravci'
  //if (Number(filterId) === 1006) url = 'Zaplacené objednávky'
  if (Number(filterId) === 1006) url = 'Vrácené zásilky'

  if (Number(filterId) === 1008) url = 'Schválené objednávky'
  if (Number(filterId) === 1009) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1010) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1011) url = 'Zásilky předané dopravci'
  //if (Number(filterId) === 1012) url = 'Zaplacené objednávky'
  if (Number(filterId) === 1012) url = 'Vrácené zásilky'

  if (Number(filterId) === 1014) url = 'Schválené objednávky'
  if (Number(filterId) === 1015) url = 'Vytvořené finální dokumenty'
  if (Number(filterId) === 1016) url = 'Zahájená distribuce zásilek'
  if (Number(filterId) === 1017) url = 'Zásilky předané dopravci'
  //if (Number(filterId) === 1018) url = 'Zaplacené objednávky'
  if (Number(filterId) === 1018) url = 'Vrácené zásilky'

  //ELEKTRONICKÁ
  if (Number(filterId) === 1020) url = 'Schválené objednávky'
  if (Number(filterId) === 1021) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1022) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1023) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1024) url = 'Vytvořené finální dokumenty'

  if (Number(filterId) === 1026) url = 'Schválené objednávky'
  if (Number(filterId) === 1027) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1028) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1029) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1030) url = 'Vytvořené finální dokumenty'

  if (Number(filterId) === 1032) url = 'Schválené objednávky'
  if (Number(filterId) === 1033) url = 'Vytvořené zálohové faktury'
  if (Number(filterId) === 1034) url = 'Zveřejněné zálohové faktury'
  if (Number(filterId) === 1035) url = 'Zaplacené zálohové faktury'
  if (Number(filterId) === 1036) url = 'Vytvořené finální dokumenty'

  //ZADARMISTI
  if (Number(filterId) === 1038) url = 'Schválené objednávky'
  if (Number(filterId) === 1039) url = 'Vytvořené finální dokumenty'

  if (Number(filterId) === 1041) url = 'Schválené objednávky'
  if (Number(filterId) === 1042) url = 'Vytvořené finální dokumenty'

  if (Number(filterId) === 1044) url = 'Schválené objednávky'
  if (Number(filterId) === 1045) url = 'Vytvořené finální dokumenty'
  
  return url
}

export const getDataFiltersNewNumberByState = (program:string,isStereoLan:boolean,section:string,state:string) => {
  let listNumber:string = '' 

  if (program === 'DUEL') {
    if (section === 'E') {
      if (state === 'A') listNumber = '1019' 
      if (state === 'O') listNumber = '1020' 
      if (state === 'M') listNumber = '1021' 
      if (state === 'I') listNumber = '1022' 
      if (state === 'D') listNumber = '1023'
      if (state === 'F') listNumber = '1024' 
    }
    if (section === 'F') {
      if (state === 'A') listNumber = '1001'  
      if (state === 'D') listNumber = '1002'
      if (state === 'T') listNumber = '1003'
      if (state === 'G') listNumber = '1004'
      if (state === 'E') listNumber = '1005'
      if (state === 'F') listNumber = '1006' 
    }
    if (section === 'Z') {
      if (state === 'A') listNumber = '1037'  
      if (state === 'D') listNumber = '1038'
      if (state === 'F') listNumber = '1039' 
    }

  }

  if (program === 'STEREO' && !isStereoLan) {
    if (section === 'E') {
      if (state === 'A') listNumber = '1025' 
      if (state === 'O') listNumber = '1026' 
      if (state === 'M') listNumber = '1027' 
      if (state === 'I') listNumber = '1028' 
      if (state === 'D') listNumber = '1029'
      if (state === 'F') listNumber = '1030' 
    }
    if (section === 'F') {
      if (state === 'A') listNumber = '1007'  
      if (state === 'D') listNumber = '1008'
      if (state === 'T') listNumber = '1009'
      if (state === 'G') listNumber = '1010'
      if (state === 'E') listNumber = '1011'
      if (state === 'F') listNumber = '1012' 
    }
    if (section === 'Z') {
      if (state === 'A') listNumber = '1040'  
      if (state === 'D') listNumber = '1041'
      if (state === 'F') listNumber = '1042' 
    }

  }

  if (program === 'STEREO' && isStereoLan) {
    if (section === 'E') {
      if (state === 'A') listNumber = '1031' 
      if (state === 'O') listNumber = '1032' 
      if (state === 'M') listNumber = '1033' 
      if (state === 'I') listNumber = '1034' 
      if (state === 'D') listNumber = '1035'
      if (state === 'F') listNumber = '1036' 
    }
    if (section === 'F') {
      if (state === 'A') listNumber = '1013'  
      if (state === 'D') listNumber = '1014'
      if (state === 'T') listNumber = '1015'
      if (state === 'G') listNumber = '1016'
      if (state === 'E') listNumber = '1017'
      if (state === 'F') listNumber = '1018' 
    }
    if (section === 'Z') {
      if (state === 'A') listNumber = '1043'  
      if (state === 'D') listNumber = '1044'
      if (state === 'F') listNumber = '1045' 
    }

  }

  return listNumber
}

export const getDataForDFNC = () => {

  return [
    {id: "1001", count: null},
    {id: "1002", count: null},
    {id: "1003", count: null},
    {id: "1004", count: null},
    {id: "1005", count: null},
    {id: "1006", count: null},
    {id: "1007", count: null},
    {id: "1008", count: null},
    {id: "1009", count: null},
    {id: "1010", count: null},
    {id: "1011", count: null},
    {id: "1012", count: null},
    {id: "1013", count: null},
    {id: "1014", count: null},
    {id: "1015", count: null},
    {id: "1016", count: null},
    {id: "1017", count: null},
    {id: "1018", count: null},
    {id: "1019", count: null},
    {id: "1020", count: null},
    {id: "1021", count: null},
    {id: "1022", count: null},
    {id: "1023", count: null},
    {id: "1024", count: null},
    {id: "1025", count: null},
    {id: "1026", count: null},
    {id: "1027", count: null},
    {id: "1028", count: null},
    {id: "1029", count: null},
    {id: "1030", count: null},
    {id: "1031", count: null},
    {id: "1032", count: null},
    {id: "1033", count: null},
    {id: "1034", count: null},
    {id: "1035", count: null},
    {id: "1036", count: null},
    {id: "1037", count: null},
    {id: "1038", count: null},
    {id: "1039", count: null},
    {id: "1040", count: null},
    {id: "1041", count: null},
    {id: "1042", count: null},
    {id: "1043", count: null},
    {id: "1044", count: null},
    {id: "1045", count: null}

  ]

}

export const getOperatorForFiltering = (filterType:string) => {
  let operator:string = 'like' 

  if (filterType === 'CustomerId') operator = 'eq'
  if (filterType === 'UserId') operator = 'eq'
  if (filterType === 'AuthorId') operator = 'eq'
  if (filterType === 'ResponsibleUserId') operator = 'eq'
  if (filterType === 'SupportTypeId') operator = 'eq'
  if (filterType === 'SupportThemeIds') operator = 'eq'
  if (filterType === 'SolverUserId') operator = 'eq'
  if (filterType === 'Solutions.UserId') operator = 'eq'
  if (filterType === 'Solutions.AuthorId') operator = 'eq'
  if (filterType === 'Solutions.SupportTypeId') operator = 'eq'
  if (filterType === 'IsActive') operator = 'eq'
  if (filterType === 'IsSystem') operator = 'eq'
  if (filterType === 'IsDemo') operator = 'eq'
  if (filterType === 'IsTrouble') operator = 'eq'
  if (filterType === 'IsFinished') operator = 'eq'
  if (filterType === 'Solutions.IsData') operator = 'eq'
  if (filterType === 'Solutions.ToInvoice') operator = 'eq'
  if (filterType === 'IsExported') operator = 'eq'
  if (filterType === 'IsSubmitted') operator = 'eq'
  if (filterType === 'ServiceId') operator = 'eq'
  if (filterType === 'UpgradeScenarioId') operator = 'eq'
  if (filterType === 'IsDiscount') operator = 'eq'
  if (filterType === 'IsLicenseChanged') operator = 'eq'
  if (filterType === 'IsLicenseChangedInProtectedPeriod') operator = 'eq'
  if (filterType === 'IsNewNetworkLicense') operator = 'eq'
  if (filterType === 'SubmissionId') operator = 'eq'  
  if (filterType === 'Id') operator = 'eq' 
  if (filterType === 'IsVerified') operator = 'eq'
  if (filterType === 'IsPublished') operator = 'eq'
  if (filterType === 'IsManagement') operator = 'eq'
  if (filterType === 'IsPrivate') operator = 'eq'
  if (filterType === 'IsDone') operator = 'eq'
  if (filterType === 'IsCompleted') operator = 'eq'
  if (filterType === 'IsDisabled') operator = 'eq'
  if (filterType === 'IsEmployee') operator = 'eq'
  if (filterType === 'IsTransferredToAccounting') operator = 'eq'
  if (filterType === 'IsClosed') operator = 'eq'


  return operator
}

export const getStatusSelectForFiltering = (filterType:string) => {
  let status:boolean = false 

  if (filterType === 'UserId') status = true
  if (filterType === 'AuthorId') status = true
  if (filterType === 'ResponsibleUserId') status = true
  if (filterType === 'SupportTypeId') status = true
  if (filterType === 'SupportThemeIds') status = true
  if (filterType === 'SolverUserId') status = true
  if (filterType === 'Solutions.UserId') status = true
  if (filterType === 'Solutions.AuthorId') status = true
  if (filterType === 'Solutions.SupportTypeId') status = true
  if (filterType === 'DistributionMethod') status = true
  if (filterType === 'ServiceId') status = true
  if (filterType === 'UpgradeScenarioId') status = true
  //console.log('X1')
  return status
}

export const getStatusStringForFiltering = (filterType:string) => {
  let status:boolean = false 

  if (filterType === 'Email') status = true
  if (filterType === 'AccountNumber') status = true
  if (filterType === 'CompanyRegistrationNumber') status = true
  if (filterType === 'BusinessName') status = true
  if (filterType === 'BusinessName2') status = true
  if (filterType === 'CompanyName') status = true
  if (filterType === 'Note') status = true
  if (filterType === 'Reason') status = true

  if (filterType === 'Subject') status = true
  if (filterType === 'Request') status = true
  if (filterType === 'Feeling') status = true
  if (filterType === 'Solutions.Text') status = true
  if (filterType === 'Name') status = true
  if (filterType === 'Code') status = true
  if (filterType === 'PacketCode') status = true
  if (filterType === 'Packets.PacketCode') status = true
  if (filterType === 'Prefix') status = true
  if (filterType === 'CustomerFulltext') status = true
  if (filterType === 'CompanyName') status = true
  if (filterType === 'FirstName') status = true
  if (filterType === 'SurName') status = true
  if (filterType === 'ReturnNote') status = true
  if (filterType === 'Source') status = true
  if (filterType === 'Text') status = true
  if (filterType === 'Signature') status = true
  if (filterType === 'VariableSymbol') status = true
  

  //console.log('X2')
  return status
}

export const getStatusBooleanForFiltering = (filterType:string) => {
  let status:boolean = false 

  if (filterType === 'IsDemo') status = true
  if (filterType === 'IsTrouble') status = true
  if (filterType === 'IsFinished') status = true
  if (filterType === 'Solutions.IsData') status = true
  if (filterType === 'Solutions.ToInvoice') status = true
  if (filterType === 'IsActive') status = true
  if (filterType === 'IsSystem') status = true
  if (filterType === 'IsDiscount') status = true
  if (filterType === 'IsLicenseChanged') status = true
  if (filterType === 'IsLicenseChangedInProtectedPeriod') status = true
  if (filterType === 'IsNewNetworkLicense') status = true
  if (filterType === 'IsExported') status = true
  if (filterType === 'IsSubmitted') status = true
  if (filterType === 'IsVerified') status = true
  if (filterType === 'IsPublished') status = true
  if (filterType === 'IsManagement') status = true
  if (filterType === 'IsPrivate') status = true
  if (filterType === 'IsDone') status = true
  if (filterType === 'IsCompleted') status = true
  if (filterType === 'IsDisabled') status = true
  if (filterType === 'IsEmployee') status = true
  if (filterType === 'IsTransferredToAccounting') status = true
  if (filterType === 'IsClosed') status = true

  //console.log('X3')
  return status
}

export const getStatusNumberForFiltering = (filterType:string) => {
  let status:boolean = false 

  if (filterType === 'Duration') status = true
  if (filterType === 'Solutions.Duration') status = true
  if (filterType === 'InvoiceNumber') status = true
  if (filterType === 'ReturnCount') status = true
  if (filterType === 'Count') status = true
  if (filterType === 'Weight') status = true
  if (filterType === 'Amount') status = true
  if (filterType === 'InvoiceNumber') status = true
  if (filterType === 'Total') status = true
  //console.log('X4')
  return status
}

export const getStatusDateForFiltering = (filterType:string) => {
  let status:boolean = false 

  if (filterType === 'CreateDate') status = true
  if (filterType === 'LastEditDate') status = true
  if (filterType === 'DeadLineDate') status = true
  if (filterType === 'DeadLine') status = true
  if (filterType === 'Solutions.CreateDate') status = true
  if (filterType === 'ReturnDate') status = true
  if (filterType === 'DistributionDate') status = true
  if (filterType === 'Date') status = true
  if (filterType === 'ReceiptDate') status = true
  if (filterType === 'DateOfIssue') status = true

  //console.log('X5')
  return status
}

export const getOverviewsListNameById = (id:string) => {
  let name:string = ''
  
  if (id === '1') name = 'Seznam zákazníků - Pozvaní - neregistrovaní '
  if (id === '2') name = 'Seznam zákazníků - Dosud nepozvaní '
  if (id === '3') name = 'Seznam zákazníků - Registrovaní '
  if (id === '4') name = 'Seznam zákazníků - Registrovaní - bez aktivace '
  if (id === '5') name = 'Seznam zákazníků - Neaktivní '
  if (id === '6') name = 'Seznam zákazníků - Aktivní - bez objednávek upgrade ' 

  if (id === '7') name = 'Seznam objednávek - Vytvořené '
  if (id === '8') name = 'Seznam objednávek - Nabídnuté '
  if (id === '9') name = 'Seznam objednávek - Schválené '
  if (id === '10') name = 'Seznam objednávek - Zneplatněné '
  if (id === '11') name = 'Seznam objednávek - Pozastavené '
  if (id === '12') name = 'Seznam objednávek - Odmítnuté '
  if (id === '13') name = 'Seznam objednávek - Vyřízené '
  if (id === '14') name = 'Seznam objednávek - S vytvořenou zálohovou fakturou '
  if (id === '15') name = 'Seznam objednávek - Se zveřejněnou zálohovou fakturou '
  if (id === '16') name = 'Seznam objednávek - Zaplacené '
  if (id === '17') name = 'Seznam objednávek - S vytvořenými finálními dokumenty '

  if (id === '1037') name = 'Zadarmisté - DUEL - Schválené objednávky '
  if (id === '1038') name = 'Zadarmisté - DUEL - Vytvořené finální dokumenty '
  if (id === '1039') name = 'Zadarmisté - DUEL - Vyřízené objednávky '
  if (id === '1040') name = 'Zadarmisté - STEREO - Schválené objednávky '
  if (id === '1041') name = 'Zadarmisté - STEREO - Vytvořené finální dokumenty '
  if (id === '1042') name = 'Zadarmisté - STEREO - Vyřízené objednávky '
  if (id === '1043') name = 'Zadarmisté - STEREO LAN - Schválené objednávky '
  if (id === '1044') name = 'Zadarmisté - STEREO LAN - Vytvořené finální dokumenty '
  if (id === '1045') name = 'Zadarmisté - STEREO LAN - Vyřízené objednávky '

  if (id === '1019') name = 'Elektronická distribuce - DUEL - Schválené objednávky '
  if (id === '1020') name = 'Elektronická distribuce - DUEL - Vytvořené zálohové faktury '
  if (id === '1021') name = 'Elektronická distribuce - DUEL - Zveřejněné zálohové faktury '
  if (id === '1022') name = 'Elektronická distribuce - DUEL - Zaplacené zálohové faktury '
  if (id === '1023') name = 'Elektronická distribuce - DUEL - Vytvořené finální dokumenty '
  if (id === '1024') name = 'Elektronická distribuce - DUEL - Vyřízené objednávky '
  if (id === '1025') name = 'Elektronická distribuce - STEREO - Schválené objednávky '
  if (id === '1026') name = 'Elektronická distribuce - STEREO - Vytvořené zálohové faktury '
  if (id === '1027') name = 'Elektronická distribuce - STEREO - Zveřejněné zálohové faktury '
  if (id === '1028') name = 'Elektronická distribuce - STEREO - Zaplacené zálohové faktury '
  if (id === '1029') name = 'Elektronická distribuce - STEREO - Vytvořené finální dokumenty '
  if (id === '1030') name = 'Elektronická distribuce - STEREO - Vyřízené objednávky '
  if (id === '1031') name = 'Elektronická distribuce - STEREO LAN - Schválené objednávky '
  if (id === '1032') name = 'Elektronická distribuce - STEREO LAN - Vytvořené zálohové faktury '
  if (id === '1033') name = 'Elektronická distribuce - STEREO LAN - Zveřejněné zálohové faktury '
  if (id === '1034') name = 'Elektronická distribuce - STEREO LAN - Zaplacené zálohové faktury '
  if (id === '1035') name = 'Elektronická distribuce - STEREO LAN - Vytvořené finální dokumenty '
  if (id === '1036') name = 'Elektronická distribuce - STEREO LAN - Vyřízené objednávky '

  if (id === '1001') name = 'Fyzická distribuce - DUEL - Schválené objednávky '
  if (id === '1002') name = 'Fyzická distribuce - DUEL - Vytvořené finální dokumenty '
  if (id === '1003') name = 'Fyzická distribuce - DUEL - Zahájená distribuce zásilek '
  if (id === '1004') name = 'Fyzická distribuce - DUEL - Zásilky předané dopravci '
  if (id === '1005') name = 'Fyzická distribuce - DUEL - Vrácené zásilky '
  if (id === '1006') name = 'Fyzická distribuce - DUEL - Vyřízené objednávky '
  if (id === '1007') name = 'Fyzická distribuce - STEREO - Schválené objednávky '
  if (id === '1008') name = 'Fyzická distribuce - STEREO - Vytvořené finální dokumenty '
  if (id === '1009') name = 'Fyzická distribuce - STEREO - Zahájená distribuce zásilek '
  if (id === '1010') name = 'Fyzická distribuce - STEREO - Zásilky předané dopravci '
  if (id === '1011') name = 'Fyzická distribuce - STEREO - Vrácené zásilky '
  if (id === '1012') name = 'Fyzická distribuce - STEREO - Vyřízené objednávky '
  if (id === '1013') name = 'Fyzická distribuce - STEREO LAN - Schválené objednávky '
  if (id === '1014') name = 'Fyzická distribuce - STEREO LAN - Vytvořené finální dokumenty '
  if (id === '1015') name = 'Fyzická distribuce - STEREO LAN - Zahájená distribuce zásilek '
  if (id === '1016') name = 'Fyzická distribuce - STEREO LAN - Zásilky předané dopravci '
  if (id === '1017') name = 'Fyzická distribuce - STEREO LAN - Vrácené zásilky '
  if (id === '1018') name = 'Fyzická distribuce - STEREO LAN - Vyřízené objednávky '

  return name
}

export const humanFileSize = (bytes:number, si=true, dp=2) => {
  const thresh = si ? 1024 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


export const deadLineBadgeState = (deadLineDate:string) => {
  let state:string = 'secondary'

  if (new Date(deadLineDate!) >= new Date()) state='warning'
  if (new Date(deadLineDate!) >= new Date(moment(new Date()).add(7,'days').toISOString())) state='secondary'
  if (new Date(deadLineDate!) <  new Date()) state='danger'

  return state

}

export const getRoleName = (role:string) => {
  let newRoleName:string = role
  if (role === "BigHedgehog") newRoleName = 'Velký ježek'
  if (role === "SmallHedgehog") newRoleName = 'Malý ježek'
  if (role === "SmallUser") newRoleName = 'Uživatel firmy'
  if (role === "BigUser") newRoleName = 'Správce firmy'
  if (role === "Management") newRoleName = 'Vedení'
  if (role === "Meeting") newRoleName = 'Porady'
  
  return newRoleName
}