import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

//import ErrorAlert from '../../../errorAlert/ErrorAlert.part'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, Table } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
import { useStore, useDialsStore } from '../../../store'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { confirm } from "../../../Utils/Confirmation";

//import moment from 'moment'


import SelectOptionsDeliveryCzechPostProducts from '../../../sharedComponents/filterPanel/components/SelectOptions.DeliveryCzechPostProducts'


interface SubmissionPacketEditProps {
  submissionId: string,
  mode: string,
  packetId?: string
  showAs: string,
  info: string
}

const SubmissionPacketEdit: React.FC<SubmissionPacketEditProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  //const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)

  // eslint-disable-next-line
  const [accountNumber, setAccountNumber] = useState('')
  const [customerDeepLoading, setCustomerDeepLoading] = useState(false)
 
  // Připojení store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()

  const editedPacket = rootStore.submissions.find(s => s.id === props.submissionId)?.packets?.find(p => p.id === props.packetId)

  const templates = rootDialsStore.dialsDeliveryCzechPostTemplatesAll
  .slice()
  .map(tmpl => (
    <option key={tmpl.id} value={tmpl.id}>{tmpl.name}&nbsp;-&nbsp;{tmpl.note}</option>
  ))  

  // Dotaz před smazáním pozvánky
  const handleOnClickUnlockItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Pro tuto distribuci byl již vyexportován TXT soubor pro Podání online! Opravdu chcete do této distribuce zasahovat? Pokud ano, příznak Exportováno bude zrušen!",'Ano, zrušit příznak Exportováno', 'Ne', {title: 'Stav distribuce'})) {
        unlockItem(id)
      } else {
      
      }  
    })()
  }

  const handleOnClickAddedToCarrierItem = (id:string, text:string) => {
    (async () => {
      if (await confirm("Tato distribuce již byla předána dopravci! Zásilku nelze měnit ani přidat!",'OK', 'Zavřít', {title: 'Stav distribuce'})) {
        //unlockItem(id)
      } else {
      
      }  
    })()
  }
  
  // Funkce pro smazání pozvánky
  const unlockItem = (id:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      
      // Zavolání API
      
      await axios.delete(getUrl('URL_SUBMISSIONS')+'/'+id+'/czech-post-file', { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam pozvánek
          //rootStore.fetchPacketsForSubmission(props.submissionId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
          rootStore.getSubmissionById(id)?.setExportedFlag(false)
          handleShow()

        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          setShowE(true)          
        });



   
      })()    
  }



  const [stateNew, setStateNew] = useState({
    activeItem: 0,
    filteredItems: [],
    displayItems: false,
    inputValue: ''
  })

  const customerListAllF = rootStore.customerListAll
  const [customersNew, setCustomersNew] = useState(customerListAllF.filter(cl => cl.accountNumber?.includes('') || cl.businessName?.includes('')))  

  // Filtrování zákazníků v seznamu
  const filterCustomersNew = (value:string) => {
    //setCustomersNew(rootStore.getCustomerListAllByFilter(value))
    if (Object.keys(rootStore.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))).length === 0 && value.length > 2 && value.length < 50 && customerDeepLoading === false) {

      (async () => {
        setCustomerDeepLoading(true)
        await rootStore.fetchCustomerListAllNoCache(value,'',1)
        //setCustomers(rootStore.getCustomerListAllByFilter(value))
        console.log(localStorage.getItem('customerQuickSearchValue'))
        setCustomersNew(rootStore.getCustomerListAllByFilter(localStorage.getItem('customerQuickSearchValue')!))
        setCustomerDeepLoading(false)
      })();
    }
    else {
      setCustomersNew(rootStore.getCustomerListAllByFilter(value))
    }
  }


  const scrollToBeVisible = function (ele:any, container:any) {
    
    if (ele !== null) {
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
          // Scroll to the top of container
          container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
          // Scroll to the bottom of container
          container.scrollTop += eleBottom - containerBottom;
      }
    }
  }; 

  const handleNew = (licenseNumber:string, setFieldValue:any) => {
    (async () => {
      //setLoadingNew(true)  
      await rootStore.fetchLicensesTransferNewCustomer(licenseNumber)
      if (Object.keys(rootStore.licensesTransferNewCustomer).length === 0 && rootStore.licensesTransferOldCustomer[0]?.id === undefined) {alert('Nenalezeno!')}
      else {
        console.log(rootStore.licensesTransferNewCustomer[0]?.deliveryAddress)
      setFieldValue("companyName", rootStore.licensesTransferNewCustomer[0]?.businessName)
      setFieldValue("title", rootStore.licensesTransferNewCustomer[0]?.title)
      setFieldValue("firstName", rootStore.licensesTransferNewCustomer[0]?.firstName)
      setFieldValue("surName", rootStore.licensesTransferNewCustomer[0]?.surName)
      setFieldValue("titleAfter", rootStore.licensesTransferNewCustomer[0]?.titleAfter)
      setFieldValue("street", rootStore.licensesTransferNewCustomer[0]?.deliveryAddress !== null ? rootStore.licensesTransferNewCustomer[0]?.deliveryAddress?.street : rootStore.licensesTransferNewCustomer[0]?.billingAddress.street)
      setFieldValue("houseNumber", rootStore.licensesTransferNewCustomer[0]?.deliveryAddress !== null ? rootStore.licensesTransferNewCustomer[0]?.deliveryAddress?.houseNumber : rootStore.licensesTransferNewCustomer[0]?.billingAddress.houseNumber)
      setFieldValue("sequenceNumber", rootStore.licensesTransferNewCustomer[0]?.deliveryAddress !== null ? rootStore.licensesTransferNewCustomer[0]?.deliveryAddress?.identificationNumber : rootStore.licensesTransferNewCustomer[0]?.billingAddress.identificationNumber)
      setFieldValue("zipCode", rootStore.licensesTransferNewCustomer[0]?.deliveryAddress !== null ? rootStore.licensesTransferNewCustomer[0]?.deliveryAddress?.postalCode : rootStore.licensesTransferNewCustomer[0]?.billingAddress.postalCode)
      setFieldValue("city", rootStore.licensesTransferNewCustomer[0]?.deliveryAddress !== null ? rootStore.licensesTransferNewCustomer[0]?.deliveryAddress?.city : rootStore.licensesTransferNewCustomer[0]?.billingAddress.city)
      }
      //setLoadingNew(false) 
    })()
  }  

  const handleKeyDownCustomerNew =  (e:any, setFieldValue:any, nextInput:number) => {
    const { activeItem, filteredItems } = stateNew;
  
    if (e.keyCode === 13) {     //Stisknutá klávesa ENTER
      e.preventDefault();         // Vyrušit standardní funkci tlačítka
  
        // Pokud je pole prázdné, tak focus nepošlem na pole s indexem dle parametru funkce, ale na údaj Demo a ve funkci nepokračujeme
        if (!stateNew.displayItems) { 
          //var form = e.currentTarget.form;
          //form.elements[nextInput-1].focus()
          return
        }  
  
        setStateNew({
            activeItem: 0,
            filteredItems: [],
            displayItems: false,
            inputValue: filteredItems[activeItem]
        });
        
        if (customersNew[activeItem]?.accountNumber !== undefined) {
          setFieldValue('customerId',customersNew[activeItem].accountNumber)
          setFieldValue('customerIdToSend',customersNew[activeItem].id)
          handleNew(customersNew[activeItem].accountNumber!,setFieldValue)
  
        // Posun na pole s indexem dle parametru funkce
        if (e.target.nodeName === "INPUT") {
          var form = e.currentTarget.form;
          form.elements[nextInput].focus()
        }
      }
  
    }
    else if (e.keyCode === 27) { // ESC (Vyprázdnit)
        e.preventDefault();
        
        setStateNew({
            activeItem: 0,
            filteredItems: [],
            displayItems: false,
            inputValue: e.currentTarget.value
        });
        setFieldValue('customerId','')
        setFieldValue('customerIdToSend','')
        
    }
  
    else if (e.keyCode === 38) { // Šipka nahoru (posun v seznamu)
        e.preventDefault();
        if (activeItem === 0) {
            return;
        }
  
        //scrollToBeVisible(document.getElementById('cust'+String(activeItem-1)), document.getElementById('scrollcustomer'))
  
        setStateNew({
            activeItem: activeItem - 1,
            filteredItems,
            displayItems: true,
            inputValue: e.currentTarget.value
        });
  
        scrollToBeVisible(document.getElementById('custN'+String(activeItem-1)), document.getElementById('scrollcustomerN'))
    }
    else if (e.keyCode === 40) { // Šipka dolu (posun v seznamu)
        e.preventDefault();
        if ((customersNew && activeItem === customersNew.length - 1) || activeItem >= 49) { // Pokud jsem na poslední pozici, již se nikam neposunu
          return;
        }
  
        //scrollToBeVisible(document.getElementById('cust'+String(activeItem+1)), document.getElementById('scrollcustomer'))
  
        setStateNew({
            activeItem: activeItem + 1,
            filteredItems,
            displayItems: true,
            inputValue: e.currentTarget.value
        });
  
        scrollToBeVisible(document.getElementById('custN'+String(activeItem+1)), document.getElementById('scrollcustomerN'))
    }
    
  
  };
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleCloseE = () => { 
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  const handleShow = async () => {
    //await rootStore.fetchOperations()
    //await rootStore.fetchSubmissionsAll()
    //console.log(props.submissionId)
    //console.log(props.packetId)
    await rootDialsStore.fetchDialsDeliveryCzechPostProductsAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostServicesAll()
    await rootDialsStore.fetchDialsDeliveryCzechPostTemplatesAll()
    sessionStorage.removeItem('packet-first-useful-number')
    await rootStore.getLastNumberForPacket()

    //if (Object.keys(rootStore.customerListAll).length < 100) await rootStore.fetchCustomerListAll('', '', 1)
    if (rootStore.customerListAll.length < 100) await rootStore.fetchCustomerListAll('', '', 1)  

    //console.log(props.submissionId)
    //console.log(props.packetId)
    console.log(editedPacket)

    if (props.mode === 'edit') {
      await rootStore.fetchLicensesTransferNewCustomerById(editedPacket?.customerId!)
      setAccountNumber(rootStore.licensesTransferNewCustomer[0].accountNumber! !== undefined ? rootStore.licensesTransferNewCustomer[0].accountNumber!:'')
    }

    rootDialsStore.dialsDeliveryCzechPostServicesAll.map(ds => 
      {
      if (props.mode === 'add') {  
        if (ds.code === '7') { ds.setChecked(true) }
        if (ds.code === '41') { ds.setChecked(true) }
        if (ds.code === '45') { ds.setChecked(true) }
        if (ds.code === '30') { ds.setChecked(true) }
        if (ds.code === 'S') { ds.setChecked(true) }
      }
      else {
        if (editedPacket?.services?.includes(ds.id)) {ds.setChecked(true)}

      }
      return ( '' )
      }
    )


    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }



  // Funkce pro odeslání e-mailu
  const handleCreatePacket = (values:any, setFieldValue:any) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }

      

      // Zavolání API
      if (props.mode === "add") {
      await axios.post(getUrl('URL_PACKETS'), 
            { 
              submissionId: props.submissionId,  

              customerId: values.customerIdToSend === '' ? null : values.customerIdToSend,
              companyName: values.companyName,
              title: values.title,
              firstName: values.firstName,
              surName: values.surName,
              titleAfter: values.titleAfter,

              street: values.street, 
              houseNumber: values.houseNumber,
              sequenceNumber: values.sequenceNumber,
              zipCode: values.zipCode,
              city: values.city,
              notePrint: values.notePrint,

              mobileNumber: values.mobileNumber,
              phoneNumber: values.phoneNumber,
              emailAddress: values.emailAddress,
              
              amount: values.amount,

              insuredValue: values.insuredValue,

              technologicalNumber: values.technologicalNumber,
              productId: values.productId, 
              weight: values.weight,
              packetNumber: values.packetNumber,
              note: values.note,

              currency: 'CZK',
              isoCountry: 'CZE',

              services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id), 
              
            }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response)


          axios.get(getUrl('URL_SUBMISSIONS')+'/'+props.submissionId,{
            headers: {
              'Authorization': 'Bearer ' + aToken
            }  }).then(response => {
  
              rootStore.submissions.find(s => s.id === props.submissionId)!.setValuesFromData(response.data)
            
          }).catch(error => {
            
          });

          rootStore.fetchPacketsForSubmission(props.submissionId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }
      else {
      // Zavolání API
      
      await axios.post(getUrl('URL_PACKETS')+'/'+props.packetId, 
            { 
              id: props.packetId,
              submissionId: props.submissionId,  

              customerId: values.customerIdToSend === '' ? null : values.customerIdToSend,
              companyName: values.companyName,
              title: values.title,
              firstName: values.firstName,
              surName: values.surName,
              titleAfter: values.titleAfter,

              street: values.street, 
              houseNumber: values.houseNumber,
              sequenceNumber: values.sequenceNumber,
              zipCode: values.zipCode,
              city: values.city,
              notePrint: values.notePrint,

              mobileNumber: values.mobileNumber,
              phoneNumber: values.phoneNumber,
              emailAddress: values.emailAddress,
              
              amount: values.amount,

              insuredValue: values.insuredValue,

              technologicalNumber: values.technologicalNumber,
              productId: values.productId, 
              weight: values.weight,
              packetNumber: values.packetNumber,
              note: values.note,

              currency: 'CZK',
              isoCountry: 'CZE',

              services: rootDialsStore.dialsDeliveryCzechPostServicesAll.filter(items => items.checked === true).map(item => item.id),               
            }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');
          console.log(response.data)

          axios.get(getUrl('URL_SUBMISSIONS')+'/'+props.submissionId,{
            headers: {
              'Authorization': 'Bearer ' + aToken
            }  }).then(response => {
  
              rootStore.submissions.find(s => s.id === props.submissionId)!.setValuesFromData(response.data)
            
          }).catch(error => {
            
          });

          rootStore.fetchPacketsForSubmission(props.submissionId,Number(sessionStorage.getItem('submissionPacketsSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('submissionPacketsSearchPage')))
          

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
      }

    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    
    packetNumber: Yup.number()
      .min(1, 'Minimální hodnota je 1')
      .max(9999, 'Maximální hodnota je 9999'),
  })   
  return (
    <>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => {  rootStore.getSubmissionById(props.submissionId)?.isSubmitted === true ? handleOnClickAddedToCarrierItem(props.submissionId, props.info) : rootStore.getSubmissionById(props.submissionId)?.isExported === true ? handleOnClickUnlockItem(props.submissionId, props.info) : handleShow() } }>
            {props.mode === 'add' ? 'Přidat zásilku':'Upravit zásilku' }
          </Dropdown.Item>}
          {(props.showAs === 'button') &&<>
          {props.mode === 'add' ?
          <Button size="sm" style={{marginBottom: '15px', marginLeft: '0px'}} variant={props.mode === 'add' ? "success" : "outline-primary"} onClick={() => { rootStore.getSubmissionById(props.submissionId)?.isSubmitted === true ? handleOnClickAddedToCarrierItem(props.submissionId, props.info) : rootStore.getSubmissionById(props.submissionId)?.isExported === true ? handleOnClickUnlockItem(props.submissionId, props.info) : handleShow() } }>
            {props.mode === 'add' ? <><i className="fas fa-plus"></i> Přidat zásilku</>:<>Upravit</>}
          </Button>
          :
          <Button size="sm" style={{}} variant={props.mode === 'add' ? "success" : "primary"} onClick={() => { rootStore.getSubmissionById(props.submissionId)?.isSubmitted === true ? handleOnClickAddedToCarrierItem(props.submissionId, props.info) : rootStore.getSubmissionById(props.submissionId)?.isExported === true ? handleOnClickUnlockItem(props.submissionId, props.info) : handleShow() } }>
            {props.mode === 'add' ? <><i className="fas fa-plus"></i> Přidat zásilku</>:<>Upravit zásilku</>}
          </Button>}
          </>
          }
      </>}

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mode === 'add' && <>Přidání nové zásilky</>}
            {props.mode === 'edit' && <>Editace zásilky</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.mode === 'selected' && props.multiselect !== true) &&<div style={{fontWeight: 'bold', fontSize: '120%'}}>
            {props.info}<br /><hr /><br />
          </div>}
          
          <Alert variant="success" show={showInfo}>
            <strong>{props.mode === 'add' ? 'Zásilka byla úspěšně vytvořena!':'Zásilka byla úspěšně upravena!'}​</strong>
            
          </Alert>  
          <div className={showForm} >
            
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                customerId: props.mode === 'add' ? '' : accountNumber!,
                customerIdToSend: props.mode === 'add' ? '' : editedPacket?.customerId!,
                companyName: props.mode === 'add' ? '' : editedPacket?.companyName!,
                title: props.mode === 'add' ? '' : editedPacket?.title!,
                firstName: props.mode === 'add' ? '' : editedPacket?.firstName!,
                surName: props.mode === 'add' ? '' : editedPacket?.surName!,
                titleAfter: props.mode === 'add' ? '' : editedPacket?.titleAfter!,

                street: props.mode === 'add' ? '' : editedPacket?.street!, 
                houseNumber: props.mode === 'add' ? '' : editedPacket?.houseNumber!,
                sequenceNumber: props.mode === 'add' ? '' : editedPacket?.sequenceNumber!,
                zipCode: props.mode === 'add' ? '' : editedPacket?.zipCode!,
                city: props.mode === 'add' ? '' : editedPacket?.city!,
                notePrint: props.mode === 'add' ? '' : editedPacket?.notePrint!,


                mobileNumber: props.mode === 'add' ? '' : editedPacket?.mobileNumber!,
                phoneNumber: props.mode === 'add' ? '' : editedPacket?.phoneNumber!,
                emailAddress: props.mode === 'add' ? '' : editedPacket?.emailAddress!,
                
                
                productId: props.mode === 'add' ? rootDialsStore.dialsDeliveryCzechPostProductsAll.find(p => p.prefix === 'DR')?.id : editedPacket?.productId,
                
                technologicalNumber: props.mode === 'add' ? 'M60388' : editedPacket?.technologicalNumber!,
                weight: props.mode === 'add' ? 0.500 : editedPacket?.weight,
                packetNumber: props.mode === 'add' ? 0 : editedPacket?.packetNumber!,

                //packetNumber: props.mode === 'add' ? 0 : 0,

                amount: props.mode === 'add' ? 0 : editedPacket?.amount,

                insuredValue: props.mode === 'add' ? 0 : editedPacket?.insuredValue,

                note: props.mode === 'add' ? '' : editedPacket?.note!,
                changes:'',
                template: ''
              }}
              onSubmit={(values, { setSubmitting, setFieldValue }) => {
                setTimeout(() => {
                  handleCreatePacket(values,setFieldValue)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                setFieldValue,
                
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Příjemce</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 

                  <FormGroup controlId="customerId">
                    <Form.Row>
                      <FormLabel column lg={3}>Zákazník</FormLabel>
                      <Col>
                      <FormControl 
                          type="text"
                          name="customerId"
                          
                          value={values.customerId!}
                          onChange={(e) => {handleChange(e);  stateNew.displayItems = true; localStorage.setItem('customerQuickSearchValue', e.target.value);    
                            //setCustomers(customerListAllF.filter(cl => cl.accountNumber?.toLowerCase().includes(e.target.value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(e.target.value!.toLowerCase())))
                             filterCustomersNew(e.target.value) 
                            }}
                          //tabIndex={1}
                          autoComplete="off" 
                          autoFocus={true}
                          isValid={touched.customerId && !errors.customerId} 
                          isInvalid={!!errors.customerId}
                          onKeyDown={(e:any) => handleKeyDownCustomerNew(e, setFieldValue, 1)}
                          onBlur={(e:any) => {setTimeout(() => {setStateNew({activeItem: 0, filteredItems: stateNew.filteredItems, displayItems: false, inputValue: stateNew.inputValue}); },200 ) }}
                          
                        />
                        {values.customerId! !== '' && stateNew.displayItems ?
                            <div id="scrollcustomerN" className="list-panel uk-panel uk-padding-remove uk-box-shadow-medium">
                                <ul className="uk-list" style={{ padding: '0px', marginTop: '0px', width: '400px', cursor: 'pointer'}}>
                                {
                                    customersNew.map((dp,index) => {
                                        return (
                                            <li id={'custN'+String(index)} className={`${stateNew.activeItem === index ? "active-item" : "default-item"}`}
                                                onClick={async (e:any) => {await setFieldValue("licenseNumber",dp.accountNumber); await setFieldValue('customerIdToSend',dp.id); stateNew.displayItems = false; handleNew(dp.accountNumber!,setFieldValue)}}
                                                key = {dp.id}
                                                >
                                                {dp.accountNumber} - {dp.businessName}
                                            </li>
                                        )
                                    }).slice(0, 50)
                                }
                                </ul>
                            </div>
                            : null
                        }
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.customerId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <Form.Row>
                    <FormGroup as={Col} controlId="companyName">
                      <FormLabel >Název firmy</FormLabel>
                        <FormControl 
                          type="text"
                          name="companyName"
                          value={values.companyName!}
                          onChange={handleChange}
                          isValid={touched.companyName && !errors.companyName} 
                          isInvalid={!!errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="title">
                      <FormLabel >Titul</FormLabel>
                        <FormControl 
                          type="text"
                          name="title"
                          value={values.title!}
                          onChange={handleChange}
                          
                          isValid={touched.title && !errors.title} 
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} sm={4}  controlId="name">
                      <FormLabel>Jméno</FormLabel>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName!}
                          onChange={handleChange}
                          
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                        </FormGroup> 
                        <FormGroup as={Col} sm={4} md={4}  controlId="surName">
                        <FormLabel>Příjmení</FormLabel>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName!}
                          onChange={handleChange}
                          
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                    </FormGroup> 
                    <FormGroup as={Col} lg={2} md={2} sm={3} xs={4} controlId="titleAfter">
                      <FormLabel >Titul za</FormLabel>
                        <FormControl 
                          type="text"
                          name="titleAfter"
                          value={values.titleAfter!}
                          onChange={handleChange}
                          
                          isValid={touched.titleAfter && !errors.titleAfter} 
                          isInvalid={!!errors.titleAfter}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.titleAfter}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Adresa</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 

                  <Form.Row>
                    <FormGroup as={Col}  controlId="street">
                      <FormLabel>Ulice</FormLabel>
                      <FormControl 
                        type="text"
                        name="street"
                        value={values.street!}
                        onChange={handleChange}
                        
                        isValid={touched.street && !errors.street} 
                        isInvalid={!!errors.street}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={2} controlId="houseNumber">
                      <FormLabel>Číslo popisné</FormLabel>
                        <FormControl 
                          type="text"
                          name="houseNumber"
                          value={values.houseNumber!}
                          onChange={handleChange}
                          
                          isValid={touched.houseNumber && !errors.houseNumber} 
                          isInvalid={!!errors.houseNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.houseNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} lg={2} controlId="sequenceNumber">
                        <FormLabel>Číslo orientační</FormLabel>
                        <FormControl 
                          type="text"
                          name="sequenceNumber"
                          value={values.sequenceNumber!}
                          onChange={handleChange}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sequenceNumber}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <Form.Row>  
                    <FormGroup as={Col} lg={3} controlId="zipCode">
                      <FormLabel>PSČ</FormLabel>
                      <FormControl 
                        type="text"
                        name="zipCode"
                        value={values.zipCode!}
                        onChange={handleChange}
                        isValid={touched.zipCode && !errors.zipCode} 
                        isInvalid={!!errors.zipCode}
                      />
                        
                      <Form.Control.Feedback type="invalid">
                        {errors.zipCode}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} controlId="city">
                      <FormLabel>Město</FormLabel>
                      <FormControl 
                        type="text"
                        name="city"
                        value={values.city!}
                        onChange={handleChange}
                        
                        isValid={touched.city && !errors.city} 
                        isInvalid={!!errors.city}
                      />
                      
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </FormGroup> 
                  </Form.Row>
                  <FormGroup controlId="notePrint">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka k tisku</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="notePrint"
                          value={values.notePrint}
                          onChange={handleChange}
                          isValid={touched.notePrint && !errors.notePrint} 
                          isInvalid={!!errors.notePrint}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.notePrint}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>   
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Kontakt</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="mobileNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Mobil</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="mobileNumber"
                          value={values.mobileNumber}
                          onChange={handleChange}
                          isValid={touched.mobileNumber && !errors.mobileNumber} 
                          isInvalid={!!errors.mobileNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.mobileNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="phoneNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Telefon</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isValid={touched.phoneNumber && !errors.phoneNumber} 
                          isInvalid={!!errors.phoneNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="emailAddress">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="emailAddress"
                          value={values.emailAddress}
                          onChange={handleChange}
                          isValid={touched.emailAddress && !errors.emailAddress} 
                          isInvalid={!!errors.emailAddress}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.emailAddress}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Zásilka</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="template">
                    <Form.Row>
                      <FormLabel column lg={3}>Šablona</FormLabel>
                      <Col lg={6}>
                        <Form.Control as="select" 
                          name="template" 
                          onChange={handleChange}
                          //onChange={(e) => {handleChange(e); if (e.target.value !== '') { setFieldValue('isFinished', false) } }}
                          value={values.template!}
                          isInvalid={!!errors.template}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            templates
                            }
                            
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.template}
                        </Form.Control.Feedback>
                      </Col>
                      <Col>
                        <Button 
                          disabled={values.template !== '' ? false:true}
                          block 
                          onClick={async () => { 
                            setFieldValue("technologicalNumber", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.technologicalNumber);
                            setFieldValue("weight", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.weight); 
                            setFieldValue("productId", rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.productId); 
                            await rootDialsStore.dialsDeliveryCzechPostServicesAll.map(ds => 
                              {
                                if (rootDialsStore.getDialsDeliveryCzechPostTemplateAllById(values.template)?.services?.includes(ds.id)) {ds.setChecked(true)}
                                else {ds.setChecked(false)}
                                return ( '' )
                              }
                                 
                            )
                            setFieldValue("changes", "A");
                          }}  
                        >Načíst</Button>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="technologicalNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Identifikátor podavatele</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="technologicalNumber"
                          value={values.technologicalNumber}
                          onChange={handleChange}
                          isValid={touched.technologicalNumber && !errors.technologicalNumber} 
                          isInvalid={!!errors.technologicalNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.technologicalNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="packetNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Číslo zásilky:</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="packetNumber"
                          value={values.packetNumber}
                          onChange={handleChange}
                          isValid={touched.packetNumber && !errors.packetNumber} 
                          isInvalid={!!errors.packetNumber}
                        />
                        {props.mode === 'add' &&<span title='Nabízí se nejvyšší použité číslo zásilky + 1 z datumově poslední distribuce.'><i className="fas fa-info-circle"></i>&nbsp;<i>Doporučené číslo: {sessionStorage.getItem('packet-first-useful-number')}</i></span>}
                        <Form.Control.Feedback type="invalid">
                          {errors.packetNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="weight">
                    <Form.Row>
                      <FormLabel column lg={3}>Váha v kg ($,$$$)</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="weight"
                          value={values.weight}
                          onChange={handleChange}
                          isValid={touched.weight && !errors.weight} 
                          isInvalid={!!errors.weight}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.weight}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="amount">
                    <Form.Row>
                      <FormLabel column lg={3}>Dobírka</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="amount"
                          value={values.amount}
                          onChange={handleChange}
                          isValid={touched.amount && !errors.amount} 
                          isInvalid={!!errors.amount}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.amount}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="insuredValue">
                    <Form.Row>
                      <FormLabel column lg={3}>Udaná cena</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="insuredValue"
                          value={values.insuredValue}
                          onChange={handleChange}
                          isValid={touched.insuredValue && !errors.insuredValue} 
                          isInvalid={!!errors.insuredValue}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.insuredValue}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Poznámka zásilky</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                          
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="productId">
                    <Form.Row>
                      <FormLabel column lg={3}>Produkt</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="productId" 
                          onChange={handleChange}
                          value={values.productId!}
                          isInvalid={!!errors.productId}
                          >
                            <option value="">--- Vyberte ---</option>
                            {
                            <SelectOptionsDeliveryCzechPostProducts />
                            }
                        </Form.Control>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.productId}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  
                  <FormGroup controlId="information">
                    <Form.Row>
                      <FormLabel column ><strong>Služby</strong></FormLabel>
                    </Form.Row>  
                  </FormGroup>  

                  <Table hover>
                    <tbody>
                      {rootDialsStore.dialsDeliveryCzechPostServicesAll.map(item => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item.checked}
                              className=""
                              id={'rowcheck'+item.id}
                              onChange={() => {item.setChecked(!item.checked); setFieldValue("changes","")}}
                              style={{height: '18px', width: '18px'}}
                            />
                          </td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ?  'Ukládám zásilku...' : props.mode === 'add' ? 'Přidat zásilku':'Uložit zásilku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>

      <Modal 
        show={showE} 
        onHide={handleCloseE} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Změna stavu zásilky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(SubmissionPacketEdit))
