// eslint-disable-next-line
import { getUser, getToken, getUrl, errorEvaluation, getDataFiltersNewFirstUrlPart, getDataFiltersNewLastUrlPart, getDataFiltersNewProgramId, getDataFiltersNewIsStereoLan, getDataForDFNC, getOperatorForFiltering, getStatusNumberForFiltering, getStatusDateForFiltering } from '../Utils/Common';
//import { useState } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'
import { LoggedUser } from './loggedUser.store'
import { LoggedUserPreferences } from './loggedUserPreferences.store'

import { CustomerList } from './customerList.store'
import { CustomerListAll } from './customerListAll.store'
import { CustomerOpened } from './customerOpened.store'
import { CustomerUsers } from './customerUsers.store'
import { HedgehogUsers } from './hedgehogUsers.store'
import { CustomerOfferCount } from './customerOffersCount.store'
import { CustomerOpenedCategories } from './customerOpenedCategories.store'
import { CustomerOpenedLicenses } from './customerOpenedLicenses.store'
import { CustomerOpenedServices } from './customerOpenedServices.store'
import { CustomerOpenedDocuments } from './customerOpenedDocuments.store'
import { CustomerOpenedEmailsSent } from './customerOpenedEmailsSent.store'
import { CustomerOpenedHistory } from './customerOpenedHistory.store'
import { CustomerOpenedHistoryNextSite } from './customerOpenedHistoryNextSite.store'
import { CustomerOpenedUsageMode } from './customerOpenedUsageMode.store'
import { CustomerOpenedNotes } from './customerOpenedNotes.store'
import { Invites } from './invites.store'
import { OverviewsCustomers } from './overviewsCustomers.store'
import { DatafiltersCustomers } from './datafiltersCustomers.store'
import { DatafiltersCustomersForFilteredActions } from './datafiltersCustomersForFilteredActions.store'
import { OverviewsOffers } from './overviewsOffers.store'
import { DatafiltersOffers } from './datafiltersOffers.store'
import { DatafiltersOffersForFilteredActions } from './datafiltersOffersForFilteredActions.store'
import { DatafiltersOffersNew } from './datafiltersOffersNew.store'
import { DatafiltersOffersNewForFilteredActions } from './datafiltersOffersNewForFilteredActions.store'
import { DatafiltersOffersCounts } from './datafiltersOffersNewCounts.store'
import { Templates } from './templates.store'
import { Operations } from './operations.store'
import { LicensesTransfers } from './licensesTransfers.store'
import { LicensesTransferOldCustomer } from './licensesTransferOldCustomer.store'
import { LicensesTransferNewCustomer } from './licensesTransferNewCustomer.store'
import { LicensesTransferOldCustomerLicenses } from './licensesTransferOldCustomerLicenses.store'

import { Submissions } from './submissions.store'
import { Packets } from './packets.store'

import { Supports } from './supports.store'
import { Files } from './files.store'

import { Meetings } from './meetings.store'
import { Tasks } from './tasks.store'

import { Accounts } from './accounts.store'


import { Order } from './order.store'
import { types, Instance, flow, applySnapshot, destroy } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

//import * as Constants from '../Constants/Constants'
import { getFilterStringToBE } from '../sharedComponents/filterPanel/components/FilterPanelFunctions'

import axios from 'axios';

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootStore = types
  .model({
    loggedUsers: types.array(LoggedUser),
    loggedUserPreferences: types.array(LoggedUserPreferences),
    order: types.array(Order),
    customerList: types.array(CustomerList),
    customerListAll: types.array(CustomerListAll),
    customerOpened: types.array(CustomerOpened),
    customerOffersCount: types.array(CustomerOfferCount),
    customerOpenedCategories: types.array(CustomerOpenedCategories),
    customerOpenedLicenses: types.array(CustomerOpenedLicenses),
    customerOpenedServices: types.array(CustomerOpenedServices),
    customerOpenedDocuments: types.array(CustomerOpenedDocuments),
    customerOpenedEmailsSent: types.array(CustomerOpenedEmailsSent),
    customerOpenedHistory: types.array(CustomerOpenedHistory),
    customerOpenedHistoryNextSite: types.array(CustomerOpenedHistoryNextSite),
    customerOpenedUsageMode: types.array(CustomerOpenedUsageMode),
    customerOpenedNotes: types.array(CustomerOpenedNotes),
    hedgehogUsers: types.array(HedgehogUsers),   
    customerUsers: types.array(CustomerUsers),   
    invite: types.array(Invites),
    overviewsCustomer: types.array(OverviewsCustomers),
    datafiltersCustomers: types.array(DatafiltersCustomers),
    datafiltersCustomersForFilteredActions: types.array(DatafiltersCustomersForFilteredActions),
    overviewsOffer: types.array(OverviewsOffers),
    datafiltersOffers: types.array(DatafiltersOffers),
    datafiltersOffersForFilteredActions: types.array(DatafiltersOffersForFilteredActions),
    datafiltersOffersNew: types.array(DatafiltersOffersNew),
    datafiltersOffersNewForFilteredActions: types.array(DatafiltersOffersNewForFilteredActions),
    datafiltersOffersCounts: types.array(DatafiltersOffersCounts),
    templates: types.array(Templates),
    templatesForLookup: types.array(Templates),
    operations: types.array(Operations),
    licensesTransfers: types.array(LicensesTransfers),
    licensesTransferOldCustomer: types.array(LicensesTransferOldCustomer),
    licensesTransferNewCustomer: types.array(LicensesTransferNewCustomer),
    licensesTransferOldCustomerLicenses: types.array(LicensesTransferOldCustomerLicenses),
    supports: types.array(Supports),
    files: types.array(Files),

    submissions: types.array(Submissions),
    submissionsAll: types.array(Submissions),
    packets: types.array(Packets),

    meetings: types.array(Meetings),
    meetingsUnread: types.array(types.string),
    tasks: types.array(Tasks),

    accounts: types.array(Accounts),
    

  })
  .views(self => ({
    getAccountById(id: string) {
      return self.accounts.find(item => item.id === id)
    },
    getCustomerOfferCountByState(state: string) {
      return Object.keys(self.customerOffersCount.filter(offer => offer.offerState === state)).length 
    },
    getCustomerOfferMaxYearByState(state: string) {
      //return Math.max.apply(Math, self.customerOffersCount.filter(offer => offer.offerState === state).map(function(o)  { return new Date(o.distributionDate).getFullYear; } ))
      return new Date(self.customerOffersCount.filter(offer => offer.offerState === state).reduce((max, offer) => (new Date(offer.distributionDate) > new Date(max.distributionDate)) ? offer : max).distributionDate).getFullYear();
      //return Object.keys(self.customerOffersCount.filter(offer => offer.offerState === state)).length 
    },
    getCustomerDocumentsCountByScenarioId(scenarioId: string) {
      return Object.keys(self.customerOpenedDocuments.filter(document => document.upgradeScenarioId === scenarioId)).length 
    },
    getCustomerById(id: string) {
      return self.customerList.find(customer => customer.id === id)
    },
    getOpenedCustomerById(id: string) {
      return self.customerOpened.find(customer => customer.id === id)
    },    
    getLoggedUserById(id: string) {
      return self.loggedUsers.find(loggedUser => loggedUser.id === id)
    },
    getCustomerUserById(id: string) {
      return self.customerUsers.find(customerUser => customerUser.id === id)
    },
    getInviteById(id: string) {
      return self.invite.find(invite => invite.id === id)
    }, 
    getInvitesUnused() {
      return self.invite.filter(invite => invite.usedAt === null && new Date(invite.expiredAt!.replace(' ', 'T')) > new Date())
    },    
    getDataFiltersCustomerById(id: string) {
      return self.datafiltersCustomers.find(datafiltersCustomers => datafiltersCustomers.id === id)
    },
    getTemplatesActive() {
      return self.templates.find(templates => templates.isActive === true)
    },
    getTemplateById(id: string) {
      return self.templates.find(templates => templates.id === id)
    },
    getOperationById(id: string) {
      return self.operations.find(operations => operations.id === id)
    },
    getRuningOperationsByName(name: string) {
      return self.operations.filter(operations => operations.name === name && operations.state < 3)
    },
    getCustomerOpenedLicenseById(id: string) {
      return self.customerOpenedLicenses.find(customerOpenedLicense => customerOpenedLicense.license.id === id)
    },
    getCustomerOpenedLicensesActive() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === true).sort(function(a:any, b:any) {
        var nameB = a.license.program.name; 
        var nameA = b.license.program.name; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesActiveStereo() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === true && customerOpenedLicensesActive.license.program.code === 'S').sort(function(a:any, b:any) {
        var nameA = a.license.status; 
        var nameB = b.license.status; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesActiveDuel() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === true && customerOpenedLicensesActive.license.program.code === 'D').sort(function(a:any, b:any) {
        var nameA = a.license.status; 
        var nameB = b.license.status; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesNonactiveStereoActual() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === false && customerOpenedLicensesActive.license.program.code === 'S' && customerOpenedLicensesActive.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesStereoActual() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.program.code === 'S' && customerOpenedLicensesActive.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesDuelActual() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.program.code === 'D' && customerOpenedLicensesActive.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesStereoDefault() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.program.code === 'S' && customerOpenedLicensesActive.license.status === 'V').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesDuelDefault() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.program.code === 'D' && customerOpenedLicensesActive.license.status === 'V').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesNonactiveStereoDefaultByVersion(version:number) {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === false && customerOpenedLicensesActive.license.program.code === 'S' && customerOpenedLicensesActive.license.status === 'V' && customerOpenedLicensesActive.license.programVersion.version === version).sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerOpenedLicensesNonactiveDuelActual() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === false && customerOpenedLicensesActive.license.program.code === 'D' && customerOpenedLicensesActive.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesNonactiveDuelDefaultByVersion(version:number) {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActive => customerOpenedLicensesActive.license.isActive === false && customerOpenedLicensesActive.license.program.code === 'D' && customerOpenedLicensesActive.license.status === 'V' && customerOpenedLicensesActive.license.programVersion.version === version).sort(function(a:any, b:any) {
        var nameB = a.license.programVersion.version; 
        var nameA = b.license.programVersion.version; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesNonActive() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesNonActive => customerOpenedLicensesNonActive.license.isActive === false && customerOpenedLicensesNonActive.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.program.name; 
        var nameA = b.license.program.name; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesNonActiveDefault() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesNonActive => customerOpenedLicensesNonActive.license.isActive === false && customerOpenedLicensesNonActive.license.status === 'V').sort(function(a:any, b:any) {
        var nameB = a.license.program.name; 
        var nameA = b.license.program.name; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesActiveActual() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActiveActual => customerOpenedLicensesActiveActual.license.isActive === true && customerOpenedLicensesActiveActual.license.status === 'A').sort(function(a:any, b:any) {
        var nameB = a.license.program.name; 
        var nameA = b.license.program.name; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getCustomerOpenedLicensesActiveDefault() {
      return self.customerOpenedLicenses.filter(customerOpenedLicensesActiveDefault => customerOpenedLicensesActiveDefault.license.isActive === true && customerOpenedLicensesActiveDefault.license.status === 'V').sort(function(a:any, b:any) {
        var nameB = a.license.program.name; 
        var nameA = b.license.program.name; 
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    },
    getOrderById(id: string) {
      return self.order.find(datafiltersCustomers => datafiltersCustomers.id === id)
    },
    getDatafiltersNewById(id: string) {
      return self.datafiltersOffersNew.find(dfon => dfon.offerId === id)
    },
    getDatafiltersCountById(id: string) {
      return self.datafiltersOffersCounts.find(dfonc => dfonc.id === id)
    },
    getLicensesTransferById(id: string) {
      return self.licensesTransfers.find(lt => lt.id === id)
    },
    getSupportById(id: string) {
      return self.supports.find(s => s.id === id)
    },
    getMeetingById(id: string) {
      return self.meetings.find(item => item.id === id)
    },
    getTaskById(id: string) {
      return self.tasks.find(item => item.id === id)
    },
    getSubmissionById(id: string) {
      return self.submissions.find(s => s.id === id)
    },
    getPacketById(id: string) {
      return self.packets.find(p => p.id === id)
    },
    getCustomerListAllByFilter(value: string) {
      /*
      if (Object.keys(self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase())).slice().length === 0)) {
        
        (async () => {
        const accessToken = await getToken()

        const cl = await axios.get(getUrl('URL_CUSTOMER_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          self.customerList.clear()
          applySnapshot(self.customerListAll,cl) 
      }

        )();
      }*/
      
      return self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase())).slice().sort(function(a:any, b:any) {
        var sort1A = a.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
        var sort1B = b.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
    
        if (sort1A! < sort1B!) {
          return -1;
        }
        if (sort1A! > sort1B!) {
          return 1;
        }
        return 0;
      })
    },

    getCustomerListAllByFilterWithoutICO(value: string) {
      
      return self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessName?.toLowerCase().includes(value!.toLowerCase())).slice().sort(function(a:any, b:any) {
        var sort1A = a.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
        var sort1B = b.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
    
        if (sort1A! < sort1B!) {
          return -1;
        }
        if (sort1A! > sort1B!) {
          return 1;
        }
        return 0;
      })
    },
    
    getLoggedUserPreferenceByKey(key: string) {
      return self.loggedUserPreferences.find(lup => lup.key === key)
    }



  }))
  .actions(self => ({
    getLastNumberForPacket: flow(function* fetchProjects() {
      try {

       yield sleep(100)
      
       let url = getUrl('URL_PACKETS')+'/first-useful-number'
      
       const accessToken = yield getToken()
       
       yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          sessionStorage.setItem('packet-first-useful-number', response.data)
            //console.log(response.data)
          //return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst číslo!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchDocumentTypesForSubmission: flow(function* fetchProjects(id:string) {
      try {
        //self.packets.clear()
        
        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_SUBMISSIONS')+'/'+id+'/document-types',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          } }).then(response => {

            
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          //self.packets.clear()
          //applySnapshot(self.packets,cl) 
          self.submissions.find(s => s.id === id)?.setDocumentTypes(cl)
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst zásilky. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchPacketsForSubmission: flow(function* fetchProjects(id:string,pageNumber:number) {
      try {
        //self.packets.clear()

        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('submissionPacketsSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('submissionPacketsOrderBy') === 'PacketCode') order = 'PacketCode~asc'
        if (localStorage.getItem('submissionPacketsOrderBy') === 'PacketCodeDesc') order = 'PacketCode~desc'

        const page = pageNumber
        const pagesize = 10
        
        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_SUBMISSIONS')+'/'+id+'/packets',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastSubmissionPacketsSearchTotalCount',response.data.totalCount)  
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastSubmissionPacketsSearchTotalCount') === '0') {
          self.packets.clear()
          self.submissions.find(s => s.id === id)?.setPackets(null)
        }
        else {
          //self.submissions.clear()
          applySnapshot(self.packets,cl)
          self.submissions.find(s => s.id === id)?.setPackets(cl)
        } 

          
           
          
        
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst zásilky. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchPacketsById: flow(function* fetchProjects(id:string) {
      try {
        self.packets.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_PACKETS')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        self.packets.clear()
        
        applySnapshot(self.packets,[cl])
         

        
          
    } catch (error) {
      console.error('Nepodařilo se načíst zásilky. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    fetchSubmissions: flow(function* fetchProjects(pageNumber:number) {
      try {
        //self.submissions.clear()

        type PacketsSearchArray = {filterName: string, filterValue: string, displayValue: string}
        type SubmissionsSearchArray = {filterName: string, filterValue: string, displayValue: string}
        
        let packetsSearchArray = new Array<PacketsSearchArray>()
        let submissionsSearchArray = new Array<SubmissionsSearchArray>()
        let submissionsSearchArrayWithoutDuplicates = new Array<SubmissionsSearchArray>()

        //const[searchInPackets, setSearchInPackets]  = useState(false)
        //let searchInPackets = false

        let searchDataArray = [{filterName:'', filterValue:'',displayValue:''}]

        if (localStorage.getItem('submissionsSearchArray') !== null) {
          searchDataArray = JSON.parse(localStorage.getItem('submissionsSearchArray')!)
        }

        searchDataArray.map((data,index) => {
          if (data.filterName === 'Packets.PacketCode') packetsSearchArray.push({filterName: 'PacketCode', filterValue: data.filterValue, displayValue: data.displayValue})
          if (data.filterName !== 'Packets.PacketCode') submissionsSearchArray.push({filterName: data.filterName, filterValue: data.filterValue, displayValue: data.displayValue})
          return null
        })

        const accessToken = yield getToken()

        if (Object.keys(searchDataArray.filter(data => data.filterName === 'Packets.PacketCode')).length > 0) {
          let filterForPackets:string = ''
          filterForPackets = getFilterStringToBE(JSON.stringify(packetsSearchArray))

          const pa = yield axios.get(getUrl('URL_PACKETS'),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              filter: filterForPackets,
              page: 1,
              pagesize: 999999 
            }  }).then(response => {

            return response.data.items
            
          }).catch(error => {
            //errorEvaluation(error)
          });

          pa.map((data: {id:string, submissionId:string}) => {
            submissionsSearchArray.push({filterName: 'Id', filterValue: data.submissionId, displayValue: ''})
            return null
          })

          //console.log(Object.keys(pa).length)
          if (Object.keys(pa).length === 0) {
            submissionsSearchArray.push({filterName: 'Id', filterValue: '99999999-9999-9999-9999-999999999999', displayValue: ''})
          }

          //console.log(submissionsSearchArray)
          //submissionsSearchArray = [...new Set(submissionsSearchArray) ]

          submissionsSearchArray.map(sa => {
            if (Object.keys(submissionsSearchArrayWithoutDuplicates.filter(sad => sad.filterName === sa.filterName && sad.filterValue === sa.filterValue)).length === 0) {
              submissionsSearchArrayWithoutDuplicates.push(sa)
            }
            
            return null
          })

          //console.log(submissionsSearchArrayWithoutDuplicates)
        }
        else {
          submissionsSearchArrayWithoutDuplicates = submissionsSearchArray
        }
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(JSON.stringify(submissionsSearchArrayWithoutDuplicates))
        //filter = getFilterStringToBE(localStorage.getItem('submissionsSearchArray'))
        //console.log(filter)

        if (localStorage.getItem('submissionsOrderBy') === 'DistributionDate') order = 'DistributionDate~asc'
        if (localStorage.getItem('submissionsOrderBy') === 'DistributionDateDesc') order = 'DistributionDate~desc'
        if (localStorage.getItem('submissionsOrderBy') === 'Weight') order = 'weight~asc'
        if (localStorage.getItem('submissionsOrderBy') === 'WeightDesc') order = 'weight~desc'
        if (localStorage.getItem('submissionsOrderBy') === 'Count') order = 'count~asc'
        if (localStorage.getItem('submissionsOrderBy') === 'CountDesc') order = 'count~desc'
        if (localStorage.getItem('submissionsOrderBy') === 'ReturnCount') order = 'returnCount~asc'
        if (localStorage.getItem('submissionsOrderBy') === 'ReturnCountDesc') order = 'returnCount~desc'
        if (localStorage.getItem('submissionsOrderBy') === 'Amount') order = 'amount~asc'
        if (localStorage.getItem('submissionsOrderBy') === 'AmountDesc') order = 'amount~desc'

        const page = pageNumber
        const pagesize = 10



        const cl = yield axios.get(getUrl('URL_SUBMISSIONS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            localStorage.setItem('lastSubmissionsSearchTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastSubmissionsSearchTotalCount') === '0') {
          self.submissions.clear()
        }
        else {
          //self.submissions.clear()
          applySnapshot(self.submissions,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchSubmissionsAll: flow(function* fetchProjects() {
      try {
        //self.submissions.clear()
                
        let filter:string = ''
        let order:string = 'DistributionDate~asc'

        //console.log(filter)

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_SUBMISSIONS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.submissions.clear()
          //self.submissions.clear()
          applySnapshot(self.submissions,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    addSubmissionFromData: flow(function* fetchProjects(submissionData:any) {
      try {
        //self.submissions.clear()
          // eslint-disable-next-line      
          const accessToken = yield getToken()
        
          self.submissions.push(submissionData)
          //self.submissions.clear()
          //applySnapshot(self.submissions,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchSubmissionsAllToCopy: flow(function* fetchProjects() {
      try {
        //self.submissions.clear()
                
        let filter:string = ''
        let order:string = 'DistributionDate~asc'

        //console.log(filter)

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_SUBMISSIONS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          self.submissionsAll.clear()
          //self.submissions.clear()
          applySnapshot(self.submissionsAll,cl) 
         
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst distribuce. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    downloadSubmissionDocumentFromApi: flow(function* fetchProjects(id:string,fileName:any,fileType:any) {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        yield axios.get(getUrl('URL_SUBMISSIONS')+'/'+id+'/documents/'+fileType,{
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
          responseType: 'blob',
        })
        .then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'})
          const downloadLink = document.createElement("a")
          /*
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
          */
          
          downloadLink.href = URL.createObjectURL(blob)
          //downloadLink.target = "blank"
          downloadLink.download = fileName
          downloadLink.click()

          
        }).catch(error => {
          errorEvaluation(error)
        });

      } catch (error) {
        console.error('Nepodařilo se stáhnout soubor!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    downloadSubmissionFileFromApi: flow(function* fetchProjects(id:string,fileName:any) {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        yield axios.get(getUrl('URL_SUBMISSIONS')+'/'+id+'/czech-post-file',{
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
          responseType: 'blob',
        })
        .then(response => {
          let blob = new Blob([response.data], {type: 'text/plain'})
          const downloadLink = document.createElement("a")
          /*
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
          */
          
          downloadLink.href = URL.createObjectURL(blob)
          //downloadLink.target = "blank"
          downloadLink.download = fileName
          downloadLink.click()

          
        }).catch(error => {
          errorEvaluation(error)
        });

      } catch (error) {
        console.error('Nepodařilo se stáhnout soubor!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),
    
    downloadFileFromApi: flow(function* fetchProjects(fileName:any,fileAddress:any) {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        yield axios.get(getUrl('URL_DOCUMENTS')+localStorage.getItem('openedCustomerId')+'/documents/'+fileAddress,{
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
          responseType: 'blob',
        })
        .then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'})
          const downloadLink = document.createElement("a")
          /*
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");
          */
          
          downloadLink.href = URL.createObjectURL(blob)
          //downloadLink.target = "blank"
          downloadLink.download = fileName
          downloadLink.click()

          
        }).catch(error => {
          errorEvaluation(error)
        });

      } catch (error) {
        console.error('Nepodařilo se stáhnout soubor!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    openFileFromApi: flow(function* fetchProjects(fileAddress:any) {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        yield axios.get(getUrl('URL_DOCUMENTS')+localStorage.getItem('openedCustomerId')+'/documents/'+fileAddress,{
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
          responseType: 'blob',
        })
        .then(response => {
          let blob = new Blob([response.data], {type: 'application/pdf'})
          //const downloadLink = document.createElement("a")
          
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL, "_blank");

          /*
          downloadLink.href = URL.createObjectURL(blob)
          //downloadLink.target = "blank"
          downloadLink.download = fileName
          downloadLink.click()
          */
          
        }).catch(error => {
          errorEvaluation(error)
        });

      } catch (error) {
        console.error('Nepodařilo se stáhnout soubor!', error)
        
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    fetchAccounts: flow(function* fetchProjects(pageNumber:number) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('accountsSearchArray'))

        if (localStorage.getItem('accountsOrderBy') === 'Email') order = 'Email~asc'
        if (localStorage.getItem('accountsOrderBy') === 'EmailDesc') order = 'Email~desc'
        if (localStorage.getItem('accountsOrderBy') === 'FullName') order = 'SurName~asc|firstName~asc'
        if (localStorage.getItem('accountsOrderBy') === 'FullNameDesc') order = 'SurName~desc|firstName~desc'
        
        const page = pageNumber
        const pagesize = 10



        const cl = yield axios.get(getUrl('URL_ACCOUNTS_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastAccountsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastAccountsSearchTotalCount') === '0') {
            self.accounts.clear()
        }
        else {
          
          applySnapshot(self.accounts,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatelské účty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchAccountsUserGroups: flow(function* fetchProjects(accountId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        
        
        const page = 1
        const pagesize = 9999


        //eslint-disable-next-line
        const cl = yield axios.get(getUrl('URL_ACCOUNT')+'queries/user-group/user/'+accountId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastAccountsUserGroupsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        if (localStorage.getItem('lastAccountsUserGroupsSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getAccountById(accountId)?.setUserGroups([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getAccountById(accountId)?.setUserGroups(cl) 
        } 
        
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst skupiny uživatelů. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchAccountsCustomers: flow(function* fetchProjects(accountId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        
        
        const page = 1
        const pagesize = 9999


        //eslint-disable-next-line
        const cl = yield axios.get(getUrl('URL_ACCOUNTS_LIST')+'/'+accountId+'/customers',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastAccountsCustomersSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        if (localStorage.getItem('lastAccountsCustomersSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getAccountById(accountId)?.setCustomers([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getAccountById(accountId)?.setCustomers(cl) 
        } 
        
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníku pro uživatele. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchTasks: flow(function* fetchProjects(pageNumber:number) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('tasksSearchArray'))

        if (localStorage.getItem('tasksOrderBy') === 'Deadline') order = 'Deadline~asc'
        if (localStorage.getItem('tasksOrderBy') === 'DeadlineDesc') order = 'Deadline~desc'
        if (localStorage.getItem('tasksOrderBy') === 'AuthorId') order = 'AuthorId~asc'
        if (localStorage.getItem('tasksOrderBy') === 'AuthorIdDesc') order = 'AuthorId~desc'
        if (localStorage.getItem('tasksOrderBy') === 'CategoryId') order = 'CategoryId~asc'
        if (localStorage.getItem('tasksOrderBy') === 'CategoryIdDesc') order = 'CategoryId~desc'
        
        const page = pageNumber
        const pagesize = 10



        const cl = yield axios.get(getUrl('URL_TASKS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastTasksSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastTasksSearchTotalCount') === '0') {
            self.tasks.clear()
        }
        else {
          
          applySnapshot(self.tasks,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst úkoly. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchTaskById: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_TASKS')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const tasksMerge:any = self.tasks.filter(tas => tas.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.tasks,tasksMerge)
          
    } catch (error) {
      console.error('Failed to fetch task by ID', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),


    addTaskFromData: flow(function* fetchProjects(taskData:any) {
      try {
          // eslint-disable-next-line      
          const accessToken = yield getToken()        
          self.tasks.push(taskData)
          
    } catch (error) {
      console.error('Nepodařilo se načíst úkol. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchTasksForMeeting: flow(function* fetchProjects(meetingId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = '(meetingId~eq~'+meetingId+')'
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('tasksSearchArray'))
        
        const page = 1
        const pagesize = 9999

        const cl = yield axios.get(getUrl('URL_TASKS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastTasksSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastTasksSearchTotalCount') === '0') {
            self.tasks.clear()
        }
        else {
          
          applySnapshot(self.tasks,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst úkoly. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchTaskPoints: flow(function* fetchProjects(taskId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        
        
        const page = 1
        const pagesize = 9999



        const cl = yield axios.get(getUrl('URL_TASKS')+'/'+taskId+'/points',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastTaskPointsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastTaskPointsSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getTaskById(taskId)?.setPoints([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getTaskById(taskId)?.setPoints(cl) 
        } 
        
    } catch (error) {
      console.error('Nepodařilo se načíst body úkolu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchTaskSolutions: flow(function* fetchProjects(taskId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        
        
        const page = 1
        const pagesize = 9999



        const cl = yield axios.get(getUrl('URL_TASKS')+'/'+taskId+'/solutions',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastTaskSolutionsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastTaskSolutionsSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getTaskById(taskId)?.setSolutions([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getTaskById(taskId)?.setSolutions(cl) 
        } 


        self.getTaskById(taskId)?.points.map(point => {

          //let count = 0
          axios.get(getUrl('URL_TASKS')+'/'+taskId+'/points/'+point.id+'/solutions',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              filter,
              order,
              page,
              pagesize
            }  }).then(response => {
  
              if (response.data.totalCount === 0) { point.setSolutions([]) }          
              else { point.setSolutions(response.data.items) }
              //return response.data.items
            
          }).catch(error => {
            errorEvaluation(error)
          });
          
          //console.log(count)

          

          return null
        })
        
    } catch (error) {
      console.error('Nepodařilo se načíst body úkolu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchMeetings: flow(function* fetchProjects(pageNumber:number) {
      try {
        
        const accessToken = yield getToken()

        type MeetingsSearchArray = {filterName: string, filterValue: string, displayValue: string}

        let meetingsSearchArray = new Array<MeetingsSearchArray>()

        let searchDataArray = [{filterName:'', filterValue:'',displayValue:''}]

        if (localStorage.getItem('meetingsSearchArray') !== null) {
          searchDataArray = JSON.parse(localStorage.getItem('meetingsSearchArray')!)
        }

        searchDataArray.map((data,index) => {
          if (data.filterName === 'UnreadMeetings') {
            //self.fetchTasks(1)

            let unreadMeetingsFilterString:string = self.meetingsUnread.length === 0 ? '' : '('
            self.meetingsUnread.map((mu,index) => {
              if (index !== 0) unreadMeetingsFilterString = unreadMeetingsFilterString + '~or~'
              unreadMeetingsFilterString = unreadMeetingsFilterString + '(Id~eq~'+mu+')'
              return true
            })

            if (self.meetingsUnread.length === 0) { } else { unreadMeetingsFilterString = unreadMeetingsFilterString + ')~and~(EmployeeWithAccess.Id~eq~' + getUser().id + ')' } 

            meetingsSearchArray.push({filterName: 'UnreadMeetings', filterValue: unreadMeetingsFilterString, displayValue: 'Ano'})
          } 
          if (data.filterName !== 'UnreadMeetings') meetingsSearchArray.push({filterName: data.filterName, filterValue: data.filterValue, displayValue: data.displayValue})
          return null
        })

        
        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('meetingsSearchArray'))
        filter = getFilterStringToBE(JSON.stringify(meetingsSearchArray))

        if (localStorage.getItem('meetingsOrderBy') === 'Date') order = 'Date~asc'
        if (localStorage.getItem('meetingsOrderBy') === 'DateDesc') order = 'Date~desc'
        if (localStorage.getItem('meetingsOrderBy') === 'AuthorId') order = 'AuthorId~asc'
        if (localStorage.getItem('meetingsOrderBy') === 'AuthorIdDesc') order = 'AuthorId~desc'
        if (localStorage.getItem('meetingsOrderBy') === 'CategoryId') order = 'CategoryId~asc'
        if (localStorage.getItem('meetingsOrderBy') === 'CategoryIdDesc') order = 'CategoryId~desc'
        if (localStorage.getItem('meetingsOrderBy') === 'TaskCount') order = 'TaskCount~asc'
        if (localStorage.getItem('meetingsOrderBy') === 'TaskCountDesc') order = 'TaskCount~desc'
        if (localStorage.getItem('meetingsOrderBy') === 'CompletedTaskCount') order = 'CompletedTaskCount~asc'
        if (localStorage.getItem('meetingsOrderBy') === 'CompletedTaskCountDesc') order = 'CompletedTaskCount~desc'
        
        const page = pageNumber
        const pagesize = 10



        const cl = yield axios.get(getUrl('URL_MEETINGS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastMeetingsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastMeetingsSearchTotalCount') === '0') {
            self.meetings.clear()
        }
        else {
          
          applySnapshot(self.meetings,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst porady. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUnreadMeetingIds: flow(function* fetchProjects() {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        //filter = getFilterStringToBE(localStorage.getItem('meetingsSearchArray'))
        //filter = getFilterStringToBE(JSON.stringify(meetingsSearchArray))

        //const page = 1
        //const pagesize = 999999

        const cl = yield axios.get(getUrl('URL_MEETINGS')+'/queries/user/'+getUser().id+'/unread-list',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            //page,
            //pagesize
          }  }).then(response => {

            //localStorage.setItem('lastMeetingsUnSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          
      applySnapshot(self.meetingsUnread,cl) 
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst nepřečtené porady. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchMeetingPoints: flow(function* fetchProjects(meetingId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = 'caption~asc'

        
        
        const page = 1
        const pagesize = 9999



        const cl = yield axios.get(getUrl('URL_MEETINGS')+'/'+meetingId+'/points',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastMeetingPointsSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastMeetingPointsSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getMeetingById(meetingId)?.setPoints([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getMeetingById(meetingId)?.setPoints(cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst porady. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchMeetingWhoRead: flow(function* fetchProjects(meetingId:string) {
      try {
        
        const accessToken = yield getToken()
        
        let filter:string = ''
        let order:string = ''

        
        
        const page = 1
        const pagesize = 9999


        //eslint-disable-next-line
        const cl = yield axios.get(getUrl('URL_MEETINGS')+'/'+meetingId+'/mark-as-read',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            localStorage.setItem('lastMeetingReadersSearchTotalCount',response.data.totalCount)          
            return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        if (localStorage.getItem('lastMeetingReadersSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getMeetingById(meetingId)?.setReaders([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getMeetingById(meetingId)?.setReaders(cl) 
        } 
        
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst kdo poradu četl. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    setMeetingAsRead: flow(function* fetchProjects(meetingId:string) {
      try {
        
        const accessToken = yield getToken()
        
        
        //eslint-disable-next-line
        const cl = yield axios.post(getUrl('URL_MEETINGS')+'/'+meetingId+'/mark-as-read', {id: meetingId}, {
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }  }).then(response => {

            //return response.data.items
            let newUnread = self.meetingsUnread.filter(mu => mu !== meetingId)
            applySnapshot(self.meetingsUnread,newUnread)
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        /*
        if (localStorage.getItem('lastMeetingPointsSearchTotalCount') === '0') {
            //self.meetings.clear()
            self.getMeetingById(meetingId)?.setPoints([]) 
        }
        else {
          //self.getMeetingById(meetingId)?.applySnapshot(points,cl)
          //applySnapshot(self.getMeetingById(meetingId)?.points,cl)
          self.getMeetingById(meetingId)?.setPoints(cl) 
        } 
        
       */
        
          
    } catch (error) {
      console.error('Nepodařilo se poradu označit jako přečtenou. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    

    deleteRootStore: flow(function* fetchProjects() {
      try {
        
        yield sleep(1)
        
        self.loggedUsers.clear()
        self.loggedUserPreferences.clear()
        self.order.clear()
        self.customerList.clear()
        self.customerListAll.clear()
        self.customerOpened.clear()
        self.customerOffersCount.clear()
        self.customerOpenedCategories.clear()
        self.customerOpenedLicenses.clear()
        self.customerOpenedServices.clear()
        self.customerOpenedDocuments.clear()
        self.customerOpenedEmailsSent.clear()
        self.customerOpenedHistory.clear()
        self.customerOpenedHistoryNextSite.clear()
        self.customerOpenedUsageMode.clear()
        self.customerOpenedNotes.clear()
        self.hedgehogUsers.clear()
        self.customerUsers.clear()
        self.invite.clear()
        self.overviewsCustomer.clear()
        self.datafiltersCustomers.clear()
        self.datafiltersCustomersForFilteredActions.clear()
        self.overviewsOffer.clear()
        self.datafiltersOffers.clear()
        self.datafiltersOffersForFilteredActions.clear()
        self.datafiltersOffersNew.clear()
        self.datafiltersOffersNewForFilteredActions.clear()
        self.datafiltersOffersCounts.clear()
        self.templates.clear()
        self.operations.clear()
        self.licensesTransfers.clear()
        self.licensesTransferOldCustomer.clear()
        self.licensesTransferNewCustomer.clear()
        self.licensesTransferOldCustomerLicenses.clear()
        self.supports.clear()
        self.files.clear()
        self.submissions.clear()

      } catch (error) {
        console.error('Nepodařilo se smazat stores!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    fetchLoggedUsers: flow(function* fetchProjects() {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ACCOUNT')+getUser().id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
            //console.log(response.data)
            const aId = response.data.id
            const aEmail = response.data.email
            const aSurName = response.data.surName !== null ? response.data.surName : ''
            const aFirstName = response.data.firstName !== null ? response.data.firstName : ''
            const aPhone = response.data.phone !== null ? response.data.phone : ''
            
            localStorage.setItem('userNameMenu', aFirstName+' '+aSurName)

            //console.log(aId)

          return  [{
            id: aId,
            email: aEmail,
            surName: aSurName,
            firstName: aFirstName,
            phone: aPhone
          }] 
          
        }).catch(error => {
          errorEvaluation(error)
        });
        applySnapshot(self.loggedUsers,cl) 

        if (localStorage.getItem('BigHedgehog')! === '1') {
          yield axios.get(getUrl('URL_DIALS_OPERATIONS_PREFERENCES'),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            }}).then(response => {
              
              if (Object.keys(response.data.items.filter((it: { value: string|null; }) => it.value === null)).length > 0)  { localStorage.setItem('checkOperationsPreferences', '1') }
              
              else { localStorage.setItem('checkOperationsPreferences', '0') }
              
              
          }).catch(error => {
            errorEvaluation(error)
          });





        }




      } catch (error) {
        console.error('Nepodařilo se načíst aktuálně přihlášeného uživatele. Může dojít k odhlášení!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    fetchLoggedUserPreferences: flow(function* fetchProjects() {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)
        self.loggedUserPreferences.clear()

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ACCOUNT')+getUser().id+'/preferences',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
            
          return  response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        applySnapshot(self.loggedUserPreferences,cl) 

      } catch (error) {
        console.error('Nepodařilo se načíst preference přihlášeného uživatele. Může dojít k odhlášení!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),
    
    fetchOrders: flow(function* fetchProjects() {
      try {
        
        const ret = yield axios.get(getUrl('ORDER_URL')).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        self.order = ret
        
      } catch (error) {
        console.error('Failed to fetch users', error)
      }
    }),
    
    fetchCustomerListNew: flow(function* fetchProjects(pageNumber:number) {
      try {

        self.customerList.clear()
        let filter:string = ''
        
        /*
        let filterGroup:string = ''

        let customerOpenSearchDataArray = [{filterName:'', filterValue:'',displayValue:''}]

        if (localStorage.getItem('customerOpenSearchArray') !== null) {
          customerOpenSearchDataArray = JSON.parse(localStorage.getItem('customerOpenSearchArray')!)
        }

        customerOpenSearchDataArray.filter(ssda => !(ssda.filterName === '' || ssda.filterValue === '')).slice().sort(function(a:any, b:any) {
          var nameB = a.filterName; 
          var nameA = b.filterName; 
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
          // eslint-disable-next-line
        }).map((ssda,index) => {
          if (filterGroup !== ssda.filterName) {
            if (filterGroup === '') {
              filter = filter + '('
            }
            else {
              filter = filter + ')~and~('
            }
          }
          
          if (filterGroup === ssda.filterName) {
            filter = filter + '~or~'
          }

          if (ssda.filterName !== '') {
            if (getStatusDateForFiltering(ssda.filterName) === true || getStatusNumberForFiltering(ssda.filterName) === true || ssda.filterName === 'CustomerFulltext') {
              filter = filter + ssda.filterValue
            }
            else {  
              filter = filter + '('+ ssda.filterName+'~'+getOperatorForFiltering(ssda.filterName)+'~'+ssda.filterValue + ')'
            }
          }

          filterGroup = ssda.filterName
        })
        
        if (filter !== '') filter = filter + ')'
        */
        
        filter = getFilterStringToBE(localStorage.getItem('customerOpenSearchArray'))
        
        
        ////yield sleep(300)
      
        //console.log(searchValue)
//        let searchFinalType:string = ''
//        let searchFinalString:string = ''


        //const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          if (response.data.totalCount > 5) {
            localStorage.setItem('customersTooMany','1')
          }
          else {localStorage.setItem('customersTooMany','0')}
          localStorage.setItem('customersTotalCount',response.data.totalCount)
          localStorage.setItem('customersFirstId',response.data.items[0].id)
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        if (localStorage.getItem('customersTotalCount') === '0') {
          self.customerList.clear()
        }
        else {
          applySnapshot(self.customerList,cl) 
        }
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníků. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    
    
    fetchCustomerList: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {

        ////yield sleep(300)
      
        //console.log(searchValue)
        let searchFinalType:string = ''
        let searchFinalString:string = ''

        if (searchType === 'AN') searchFinalType = 'AccountNumber'
        if (searchType === 'Name') searchFinalType = 'BusinessName'
        if (searchType === 'IC') searchFinalType = 'CompanyRegistrationNumber'
        if (searchType === 'e') searchFinalType = 'Email'

        if (searchType === 'q') searchFinalString = '((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+'))'




        const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          if (response.data.totalCount > 5) {
            localStorage.setItem('customersTooMany','1')
          }
          else {localStorage.setItem('customersTooMany','0')}
          localStorage.setItem('customersTotalCount',response.data.totalCount)
          localStorage.setItem('customersFirstId',response.data.items[0].id)
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        if (localStorage.getItem('customersTotalCount') === '0') {
          self.customerList.clear()
        }
        else {
          applySnapshot(self.customerList,cl) 
        }
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníků. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerListAll: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {
        //self.customerList.clear()
        ////yield sleep(300)
      
        //console.log(searchValue)
        /*
        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 99999
*/
        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          //self.customerList.clear()
          applySnapshot(self.customerListAll,cl) 
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníků. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),
    fetchCustomerListAllNoCache: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number, withoutIco?:boolean) {
      try {

        ////yield sleep(300)
      
        //console.log(searchValue)
        /*
        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 99999
*/
        //const params = search.concat(paging)

        const accessToken = yield getToken()

        let filter:string = ''

        if (withoutIco === true) {
          filter = '(((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')))'
        }
        else {
          filter = '(((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+')))'
        }    
        
        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'?filter='+filter+'&page=1&pagesize=999999',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
          //self.customerList.clear()
          //applySnapshot(self.customerListAll,cl) 
          cl.map((data: {id:string, businessName:string, accountNumber:string, companyRegistrationNumber:string, taxIdentificationNumber:string, isTaxAble:boolean, isActive:boolean}) => {
            if (Object.keys(self.customerListAll.filter(cla => cla.id === data.id)).length === 0) {
              self.customerListAll.push({id:data.id, businessName:data.businessName, accountNumber:data.accountNumber, companyRegistrationNumber:data.companyRegistrationNumber, taxIdentificationNumber: data.taxIdentificationNumber, isTaxAble:data.isTaxAble, isActive:data.isActive})
            }
            return null
          })
          //self.customerListAll.push(cl)
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníků. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchCustomerOpened: flow(function* fetchProjects() {
      try {

       ////yield sleep(300)
       self.customerOpenedLicenses.clear()
       self.customerOpenedServices.clear()
       self.customerUsers.clear()
       self.invite.clear()
       self.customerOpenedCategories.clear()
       self.customerOpenedDocuments.clear()

       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+localStorage.getItem('openedCustomerId'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpened,[cl]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst aktuálně otevřeného zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedCategories: flow(function* fetchProjects() {
      try {

       ////yield sleep(300)
      
       const accessToken = yield getToken()

       if (localStorage.getItem('openedCustomerId') !== null) {

        const cl = yield axios.get(getUrl('URL_CUSTOMER_CATEGORIES')+localStorage.getItem('openedCustomerId')+'/categories',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpenedCategories,cl) 
      }
    } catch (error) {
      console.error('Nepodařilo se načíst kategorie aktuálně otevřeného zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedLicenses: flow(function* fetchProjects(customerId:string) {
      try {

       ////yield sleep(300)
       //self.customerOpenedLicenses.clear()
       const accessToken = yield getToken()

       if (customerId !== null) {

       let urlEndpoint:string

       if (self.getOpenedCustomerById(customerId)?.isActive) {
        urlEndpoint = getUrl('URL_CUSTOMER_LICENSES')+customerId+'/licenses'
       }
       else {
        urlEndpoint = getUrl('URL_TRANSFER_CUSTOMER_LICENSES')+customerId
       }

        const cl = yield axios.get(urlEndpoint ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        self.customerOpenedLicenses.clear()
        applySnapshot(self.customerOpenedLicenses,cl) 
      
      }
          
    } catch (error) {
      self.customerOpenedLicenses.clear()
      console.error('Nepodařilo se načíst licence zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedServices: flow(function* fetchProjects(customerId:string) {
      try {

       ////yield sleep(300)
       
       if (customerId !== null) {
       self.customerOpenedServices.clear()
       const accessToken = yield getToken()

       let urlEndpoint:string

       if (self.getOpenedCustomerById(customerId)?.isActive) {
        urlEndpoint = getUrl('URL_CUSTOMER_SERVICES')+customerId+'/services'
       }
       else {
        urlEndpoint = getUrl('URL_SERVICES')+customerId
       }

        const cl = yield axios.get(urlEndpoint ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpenedServices,cl) 

      }
          
    } catch (error) {
      self.customerOpenedServices.clear()
      console.error('Nepodařilo se načíst služby zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedDocuments: flow(function* fetchProjects(customerId:string) {
      try {

       ////yield sleep(300)
       
       if (customerId !== null) {
       self.customerOpenedDocuments.clear()
       const accessToken = yield getToken()

       const urlBH:string = ((localStorage.getItem('BigHedgehog')! === '1')) ? '/admin-list' : ''

        const cl = yield axios.get(getUrl('URL_DOCUMENTS')+customerId+'/documents'+urlBH ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpenedDocuments,cl) 

      }
          
    } catch (error) {
      self.customerOpenedDocuments.clear()
      console.error('Nepodařilo se načíst Dokumenty zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchUsageMode: flow(function* fetchProjects(customerId:string,idS:string|null|undefined,idD:string|null|undefined) {
      try {

        ////yield sleep(300)
      
        const accessToken = yield getToken()

        let cl1 = []
        let cl2 = []

        if (!(idS === null || idS === undefined)) {
          cl1 = yield axios.get(getUrl('URL_CUSTOMER_USAGE_MODE')+customerId+'/profile/program/'+idS,{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            }}).then(response => {
            
            return [response.data]
            
          }).catch(error => {
            errorEvaluation(error)
          });
        }

        if (!(idD === null || idD === undefined)) {
          cl2 = yield axios.get(getUrl('URL_CUSTOMER_USAGE_MODE')+customerId+'/profile/program/'+idD,{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            }}).then(response => {
            
            return [response.data]
            
          }).catch(error => {
            errorEvaluation(error)
          });
        }
        const cl = cl1.concat(cl2)
        //console.log(cl)
        applySnapshot(self.customerOpenedUsageMode,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst režimy použití!', error)
    } 
    }),

    fetchCustomerOpenedNotes: flow(function* fetchProjects(customerId:string) {
      try {

       ////yield sleep(300)
       
       if (customerId !== null) {
       self.customerOpenedNotes.clear()
       const accessToken = yield getToken()

       let urlEndpoint:string

       if (self.getOpenedCustomerById(customerId)?.isActive) {
        urlEndpoint = getUrl('URL_CUSTOMER_NOTES')+customerId+'/notes'
       }
       else {
        //urlEndpoint = getUrl('URL_SERVICES')+customerId
        urlEndpoint = getUrl('URL_CUSTOMER_NOTES')+customerId+'/notes'
       }

        const cl = yield axios.get(urlEndpoint ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpenedNotes,cl) 

      }
          
    } catch (error) {
      self.customerOpenedNotes.clear()
      console.error('Nepodařilo se načíst poznámky zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCustomerOpenedDocuments: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedDocuments.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpenedDocuments', error)
      }
    }),     

    deleteCustomerOpened: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpened.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpened', error)
      }
    }),    
    deleteCustomerOpenedLicenses: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedLicenses.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpenedLicenses', error)
      }
    }), 
    deleteCustomerOpenedNotes: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedNotes.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpenedNotes', error)
      }
    }), 
    deleteCustomerList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerList.clear()
                
      } catch (error) {
        console.error('Failed to delete customerList', error)
      }
    }),
    deleteCustomerListAll: flow(function* fetchProjects() {
      try {
        yield sleep(50)
        self.customerListAll.clear()
                
      } catch (error) {
        console.error('Failed to delete customerList', error)
      }
    }),

    deleteLoggedUser: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.loggedUsers.clear()
                
      } catch (error) {
        console.error('Failed to delete loggedUsers', error)
      }
    }),

    deleteInvites: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.invite.clear()
                
      } catch (error) {
        console.error('Failed to delete invites', error)
      }
    }),

    deleteCustomerUsers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerUsers.clear()
                
      } catch (error) {
        console.error('Failed to delete customerUsers', error)
      }
    }),

    deleteOvervievsCustomers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.overviewsCustomer.clear()
                
      } catch (error) {
        console.error('Failed to delete overviewsCustomers', error)
      }
    }),

    deleteDatafiltersCustomers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersCustomers.clear()
                
      } catch (error) {
        console.error('Failed to delete datafiltersCustomers', error)
      }
    }),

    deleteDatafiltersOffers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.datafiltersOffers.clear()
        self.datafiltersOffersNew.clear()
                
      } catch (error) {
        console.error('Failed to delete datafiltersOffers', error)
      }
    }),

    fetchInvite: flow(function* fetchProjects() {
      try {

       //yield authFetch(getUrl('URL_INVITES'), {method: 'GET',headers: {'Content-Type': 'application/json'}})
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_INVITES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {

          localStorage.setItem('invitesTotalCount',response.data.count)
          //console.log(response.data)
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.invite,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst pozvánky. Dojde k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }

      
    }),


    fetchCustomerUsers: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_USERS')+localStorage.getItem('openedCustomerId')+'/users',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.customerUsers,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchHedgehogUsers: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_CUSTOMER_USERS')+'4959e642-e82a-4c8f-8b21-b408cb01bd9d/users',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.hedgehogUsers,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchHedgehogUsersFromAccounts: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)

       let filter:string = 'isEmployee~eq~true'
       let order:string = 'surName~asc'
       let page:number = 1
       let pageSize:number = 999999
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ACCOUNTS_LIST'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }, params: {
            filter,
            order,
            page,
            pageSize
          }
        }).then(response => {
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.hedgehogUsers,cl) 

          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchHedgehogUsersFromAccountsUserGroups: flow(function* fetchProjects() {
      try {
       yield sleep(1)

       
      
       const accessToken = yield getToken()

      self.hedgehogUsers.filter(hu => hu.isDisabled === false).map(hu => {
          
          axios.get(getUrl('URL_ACCOUNT')+'queries/user-group/user/'+hu.id,{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              
            }  }).then(response => {
              console.log(response.data.items)
              hu.setUserGroups(response.data.items)

            
          }).catch(error => {
            errorEvaluation(error)
          });
          
          return null
        })


          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchCustomerOfferCount: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()

       if (localStorage.getItem('openedCustomerId') !== null) {

        const cl = yield axios.get(getUrl('URL_CUSTOMER_OFFER_COUNT')+localStorage.getItem('openedCustomerId')+'/offers/state-info',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.customerOffersCount,cl) 

      }
          
    } catch (error) {
      console.error('Nepodařilo se načíst počty objednávek. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    deleteCustomerOfferCount: flow(function* fetchProjects() {
      try {
       yield sleep(1)
      
       self.customerOffersCount.clear()
          
    } catch (error) {
      console.error('Nepodařilo se načíst počty objednávek. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchOverviewsCustomers: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)

        let filter:string = ''
        filter = '((upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioDuelSelected')+')~or~(upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioStereoSelected')+'))'
      
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_OVERVIEWS_CUSTOMERS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }, params: {
            filter
          }
        }).then(response => {
          return response.data.countItems
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.overviewsCustomer,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam přehledů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),
    
    /*
    fetchDatafiltersCustomers: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, pageNumber:number) {
      try {

        let searchFinalType:string = ''
        let searchFinalString:string = ''

        if (searchType === 'AN') searchFinalType = 'AccountNumber'
        if (searchType === 'Name') searchFinalType = 'BusinessName'
        if (searchType === 'IC') searchFinalType = 'CompanyRegistrationNumber'
        if (searchType === 'e') searchFinalType = 'Email'

        if (searchType === 'q') searchFinalString = '((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+'))'

        const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','customers')
          localStorage.setItem('lastDatafilterTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterTotalCount') === '0') {
          self.datafiltersCustomers.clear()
        }
        else {
          applySnapshot(self.datafiltersCustomers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersCustomers', error)
      
    } 
    }),    

    fetchDatafiltersCustomersForFilteredActions: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string) {
      try {

        let searchFinalType:string = ''
        let searchFinalString:string = ''

        if (searchType === 'AN') searchFinalType = 'AccountNumber'
        if (searchType === 'Name') searchFinalType = 'BusinessName'
        if (searchType === 'IC') searchFinalType = 'CompanyRegistrationNumber'
        if (searchType === 'e') searchFinalType = 'Email'

        if (searchType === 'q') searchFinalString = '((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+'))'

        const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = 1
        const pagesize = 9999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }, params: {
            filter,
            page,
            pagesize
          } }).then(response => {

          localStorage.setItem('lastDatafilterCustomersTotalCountForFilteredActions',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterCustomersTotalCountForFilteredActions') === '0') {
          self.datafiltersCustomersForFilteredActions.clear()
        }
        else {
          applySnapshot(self.datafiltersCustomersForFilteredActions,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)

    } 
    }), */

    fetchDatafiltersCustomers: flow(function* fetchProjects(filterId:number,pageNumber:number) {
      try {
        console.log('fetchDatafiltersCustomers')
        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('overviewsCustomerSearchArray'))
        
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','customers')
          localStorage.setItem('lastDatafilterTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterTotalCount') === '0') {
          self.datafiltersCustomers.clear()
        }
        else {
          applySnapshot(self.datafiltersCustomers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersCustomers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),    

    fetchDatafiltersCustomersForFilteredActions: flow(function* fetchProjects(filterId:number) {
      try {
        console.log('fetchDatafiltersCustomersForFilteredActions')
        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('overviewsCustomerSearchArray'))

        const page = 1
        const pagesize = 9999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_CUSTOMERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }, params: {
            filter,
            page,
            pagesize
          } }).then(response => {

          localStorage.setItem('lastDatafilterCustomersTotalCountForFilteredActions',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterCustomersTotalCountForFilteredActions') === '0') {
          self.datafiltersCustomersForFilteredActions.clear()
        }
        else {
          applySnapshot(self.datafiltersCustomersForFilteredActions,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 

    fetchOverviewsOffers: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)
  
       /*
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_OVERVIEWS_OFFERS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.countItems
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.overviewsOffer,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam přehledů!', error)

    }
*/

      let filter:string = ''
      filter = '((upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioDuelSelected')+')~or~(upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioStereoSelected')+'))'
   
      const accessToken = yield getToken()

      const cl = yield axios.get(getUrl('URL_OVERVIEWS_OFFERS'),{
       headers: {
         'Authorization': 'Bearer ' + accessToken
       }, params: {
         filter
       }
      }).then(response => {
       return response.data.countItems
      }).catch(error => {
       errorEvaluation(error)
      });

      applySnapshot(self.overviewsOffer,cl) 
       
    } catch (error) {
      console.error('Nepodařilo se načíst seznam přehledů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),
    /*
    fetchDatafiltersOffers: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, searchScenario:string, pageNumber:number) {
      try {

        let searchFinalType:string = ''
        let searchFinalString:string = ''
        let searchFinalScenarioString:string = searchScenario === '' || searchScenario === null || searchScenario === undefined ? '' : '(UpgradeScenarioId~eq~'+searchScenario+')'
        let searchFinalScenarioStringWithNext:string = searchScenario === '' || searchScenario === null || searchScenario === undefined ? '' : '(UpgradeScenarioId~eq~'+searchScenario+')~and~'

        if (searchType === 'AN')    searchFinalType = 'AccountNumber'
        if (searchType === 'Name')  searchFinalType = 'BusinessName'
        if (searchType === 'IC')    searchFinalType = 'CompanyRegistrationNumber'
        if (searchType === 'e')     searchFinalType = 'Email'
        if (searchType === 'd')     searchFinalType = 'DistributionMethod'
        if (searchType === 'srv')   searchFinalString = '(ServiceId~eq~'+searchValue+')'
        if (searchType === 'dis')   searchFinalString = '(isDiscount~eq~'+searchValue+')'
        if (searchType === 'lic')   searchFinalString = '(isLicenseChanged~eq~'+searchValue+')'
        if (searchType === 'licp')  searchFinalString = '(isLicenseChangedInProtectedPeriod~eq~'+searchValue+')'
        if (searchType === 'lan')   searchFinalString = '(isNewNetworkLicense~eq~'+searchValue+')'
        if (searchType === 'in')    searchFinalString = '(invoiceNumber~eq~'+searchValue+')'

        if (searchType === 'q') searchFinalString = '((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+'))'

        

        const filter = searchValue === '' || searchValue === null ? searchFinalScenarioString : searchFinalString !== '' ? searchFinalScenarioStringWithNext+''+searchFinalString : searchFinalScenarioStringWithNext+''+searchFinalType+'~like~'+searchValue
        
        const upgradeScenarioId = searchScenario === '' || searchScenario === null || searchScenario === undefined ? '' : searchScenario
        const page = pageNumber
        const pagesize = 10

        let paramsToSend:any 

        if (upgradeScenarioId === '') {
          paramsToSend = {
            filter,
            page,
            pagesize
          }
        }
        else {
          paramsToSend = {
            filter,
            page,
            pagesize
          }          
        }

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_OFFERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','offers')
          localStorage.setItem('lastDatafilterOfferTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCount') === '0') {
          self.datafiltersOffers.clear()
        }
        else {
          applySnapshot(self.datafiltersOffers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    } 
    }),
    */
   /*
    fetchDatafiltersOffers: flow(function* fetchProjects(filterId:number,pageNumber:number) {
      try {

        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('overviewsOfferSearchArray'))

        const page = pageNumber
        const pagesize = 10

        

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_OFFERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: { filter, page, pagesize }  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','offers')
          localStorage.setItem('lastDatafilterOfferTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCount') === '0') {
          self.datafiltersOffers.clear()
        }
        else {
          applySnapshot(self.datafiltersOffers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)

    } 
    }),    */

    fetchDatafiltersOffers: flow(function* fetchProjects(filterId:number,pageNumber:number) {
      try {
        console.log('fetchDatafiltersOffers')
        let filter:string = ''
        let filterAllways:string = '((upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioDuelSelected')+')~or~(upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioStereoSelected')+'))'
        
        filter = getFilterStringToBE(localStorage.getItem('overviewsOfferSearchArray'))

        if (filter !== '') {
          filter = filter+'~and~'+filterAllways
        }
        else {
          filter = filterAllways
        }


        const page = pageNumber
        const pagesize = 10

        

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_OFFERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: { filter, page, pagesize }  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','offers')
          localStorage.setItem('lastDatafilterOfferTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCount') === '0') {
          self.datafiltersOffers.clear()
        }
        else {
          applySnapshot(self.datafiltersOffers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),    


    fetchDatafiltersOffersForFilteredActions: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, searchScenario:string) {
      try {
        console.log('fetchDatafiltersOffersForFilteredActions')
        let filter:string = ''
        let filterAllways:string = '((upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioDuelSelected')+')~or~(upgradeScenarioId~eq~'+localStorage.getItem('upgradeScenarioStereoSelected')+'))'

        filter = getFilterStringToBE(localStorage.getItem('overviewsOfferSearchArray'))

        if (filter !== '') {
          filter = filter+'~and~'+filterAllways
        }
        else {
          filter = filterAllways
        }

        const page = 1
        const pagesize = 999999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_OFFERS')+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {filter, page, pagesize}  }).then(response => {


            localStorage.setItem('lastDatafilterOfferOldTotalCountForFilteredActions',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferOldTotalCountForFilteredActions') === '0') {
          self.datafiltersOffersForFilteredActions.clear()
        }
        else {
          applySnapshot(self.datafiltersOffersForFilteredActions,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 
    
    fetchDatafiltersOffersNew: flow(function* fetchProjects(filterId:number, pageNumber:number) {
      try {

        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('overviewsOfferSearchArray'))

        const page = pageNumber
        const pagesize = 10
        const timeStamp = ''
        //const timeStamp = filterId.toString() === localStorage.getItem('lastDatafilter')! ? localStorage.getItem('lastDatafilterTimeStamp') : ''
        
        let isStereoLan:boolean = getDataFiltersNewIsStereoLan(filterId)

        let paramsToSend:any 

        if (timeStamp === '') {
          paramsToSend = {
            filter,
            page,
            pagesize,
            isStereoLan
          }
        }
        if (timeStamp !== '') {
          paramsToSend = {
            filter,
            page,
            pagesize,
            isStereoLan,
            timeStamp
          }
        }
              
        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(filterId)+getDataFiltersNewProgramId(filterId)+getDataFiltersNewLastUrlPart(filterId),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

            if (response.data.timeStamp.toString() !== localStorage.getItem('lastDatafilterTimeStamp')) localStorage.setItem('lastDatafilterTime',new Date().toISOString())
          localStorage.setItem('lastDatafilterTimeStamp',response.data.timeStamp.toString())
          
          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','offers')
          localStorage.setItem('lastDatafilterOfferTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCount') === '0') {
          self.datafiltersOffersNew.clear()
        }
        else {
          applySnapshot(self.datafiltersOffersNew,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffersNew', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 

    fetchDatafiltersOffersNewForFilteredActions: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, searchScenario:string) {
      try {

        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('overviewsOfferSearchArray'))

        const page = 1
        const pagesize = 9999999
        //const timeStamp = localStorage.getItem('lastDatafilterTimeStamp')!
        const timeStamp = filterId.toString() === localStorage.getItem('lastDatafilter')! ? localStorage.getItem('lastDatafilterTimeStamp') : ''
        
        let isStereoLan:boolean = getDataFiltersNewIsStereoLan(filterId)

        let paramsToSend:any 

        if (timeStamp === '') {
          paramsToSend = {
            filter,
            page,
            pagesize,
            isStereoLan
          }
        }
        if (timeStamp !== '') {
          paramsToSend = {
            filter,
            page,
            pagesize,
            isStereoLan,
            timeStamp
          }
        }

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(filterId)+getDataFiltersNewProgramId(filterId)+getDataFiltersNewLastUrlPart(filterId),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

          localStorage.setItem('lastDatafilterOfferTotalCountForFilteredActions',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCountForFilteredActions') === '0') {
          self.datafiltersOffersNewForFilteredActions.clear()
        }
        else {
          applySnapshot(self.datafiltersOffersNewForFilteredActions,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 


    fetchDatafiltersOffersNewEmptyCount: flow(function* fetchProjects() {
      try {

         yield sleep(1)
      
        
          applySnapshot(self.datafiltersOffersCounts,getDataForDFNC()) 
        
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 

    fetchDatafiltersOffersNewCountsForGroup: flow(function* fetchProjects(id1:string|null,id2:string|null,id3:string|null,id4:string|null,id5:string|null,id6:string|null) {
      try {

        //yield sleep(300)
      
        //console.log(searchValue)
        const filter = ''
        const page = 1
        const pagesize = 0
        //const timeStamp = ''

        if (id1 !== null ) self.getDatafiltersCountById(id1)?.setCount(null)
        if (id2 !== null ) self.getDatafiltersCountById(id2)?.setCount(null)
        if (id3 !== null ) self.getDatafiltersCountById(id3)?.setCount(null)
        if (id4 !== null ) self.getDatafiltersCountById(id4)?.setCount(null)
        if (id5 !== null ) self.getDatafiltersCountById(id5)?.setCount(null)
        if (id6 !== null ) self.getDatafiltersCountById(id6)?.setCount(null)

        const accessToken = yield getToken()
        
        let isStereoLan:boolean = getDataFiltersNewIsStereoLan(Number(id1))
        let paramsToSend:any = { filter,page,pagesize,isStereoLan }
        
        
        if (id1 !== null ) {
          const cl1 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id1))+getDataFiltersNewProgramId(Number(id1))+getDataFiltersNewLastUrlPart(Number(id1)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });

            self.getDatafiltersCountById(id1)?.setCount(cl1)
        }

        if (id2 !== null ) {
          isStereoLan = getDataFiltersNewIsStereoLan(Number(id2))
          paramsToSend = { filter,page,pagesize,isStereoLan }

          const cl2 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id2))+getDataFiltersNewProgramId(Number(id2))+getDataFiltersNewLastUrlPart(Number(id2)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });
            self.getDatafiltersCountById(id2)?.setCount(cl2)
        }

        if (id3 !== null ) {
          isStereoLan = getDataFiltersNewIsStereoLan(Number(id3))
          paramsToSend = { filter,page,pagesize,isStereoLan }

          const cl3 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id3))+getDataFiltersNewProgramId(Number(id3))+getDataFiltersNewLastUrlPart(Number(id3)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });

            self.getDatafiltersCountById(id3)?.setCount(cl3)
        
        }

        if (id4 !== null ) {
          isStereoLan = getDataFiltersNewIsStereoLan(Number(id4))
          paramsToSend = { filter,page,pagesize,isStereoLan }

          const cl4 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id4))+getDataFiltersNewProgramId(Number(id4))+getDataFiltersNewLastUrlPart(Number(id4)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });

            self.getDatafiltersCountById(id4)?.setCount(cl4)
        
        }

        if (id5 !== null ) {
          isStereoLan = getDataFiltersNewIsStereoLan(Number(id5))
          paramsToSend = { filter,page,pagesize,isStereoLan }

          const cl5 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id5))+getDataFiltersNewProgramId(Number(id5))+getDataFiltersNewLastUrlPart(Number(id5)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });

            self.getDatafiltersCountById(id5)?.setCount(cl5)
        
        }

        if (id6 !== null ) {
          isStereoLan = getDataFiltersNewIsStereoLan(Number(id6))
          paramsToSend = { filter,page,pagesize,isStereoLan }

          const cl6 = yield axios.get(getUrl('URL_DATAFILTERS_INVOICES')+getDataFiltersNewFirstUrlPart(Number(id6))+getDataFiltersNewProgramId(Number(id6))+getDataFiltersNewLastUrlPart(Number(id6)),{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: paramsToSend  }).then(response => {
              return response.data.totalCount
            }).catch(error => {
              errorEvaluation(error)
            });

            self.getDatafiltersCountById(id6)?.setCount(cl6)
        
        }


    } catch (error) {
      console.error('Failed to fetch datafiltersOffersCounts', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }), 

    fetchTemplates: flow(function* fetchProjects(templatesSearchValue:string,templatesSearchType:string, templatesSearchIsActive:boolean, templatesSearchWithSystem:boolean, pageSize: number, pageNumber:number) {
      try {
       ////yield sleep(300)

       let searchFinalType:string = ''
       //let searchFinalString:string = ''

       //let searchFinalActiveString:string = templatesSearchIsActive !== true  ? '' : '(IsActive~eq~'+templatesSearchIsActive+')'
       let searchFinalActiveStringWithNext:string = templatesSearchIsActive !== true  ? '' : '(IsActive~eq~'+templatesSearchIsActive+')~and~'

       let searchFinalSystemString:string = templatesSearchWithSystem === false  ? '(IsSystem~eq~'+templatesSearchWithSystem+')' : '((IsSystem~eq~'+templatesSearchWithSystem+')~or~(IsSystem~eq~'+!templatesSearchWithSystem+'))'
       let searchFinalSystemStringWithNext:string = templatesSearchWithSystem === false  ? '(IsSystem~eq~'+templatesSearchWithSystem+')~and~' : '((IsSystem~eq~'+templatesSearchWithSystem+')~or~(IsSystem~eq~'+!templatesSearchWithSystem+'))~and~'

       if (templatesSearchType === 'n')    searchFinalType = 'Name'
       if (templatesSearchType === 's')    searchFinalType = 'Subject'
       

       let filter = templatesSearchValue === '' || templatesSearchValue === null ?   searchFinalActiveStringWithNext+''+searchFinalSystemString  :  searchFinalActiveStringWithNext+''+searchFinalSystemStringWithNext+'('+searchFinalType+'~like~'+templatesSearchValue+')' 
       
       const page = pageNumber
       const pagesize = pageSize

       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            
            page,
            pagesize
          }

        
        }).then(response => {
          //console.log(response.data)
          localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.templates,cl) 
          
    } catch (error) {
      console.error('Failed to fetch templates', error)

    }  
    }),

    fetchTemplatesForLookup: flow(function* fetchProjects(templatesSearchValue:string,templatesSearchType:string, templatesSearchIsActive:boolean, templatesSearchWithSystem:boolean, pageSize: number, pageNumber:number) {
      try {
       ////yield sleep(300)

       let searchFinalType:string = ''
       //let searchFinalString:string = ''

       //let searchFinalActiveString:string = templatesSearchIsActive !== true  ? '' : '(IsActive~eq~'+templatesSearchIsActive+')'
       let searchFinalActiveStringWithNext:string = templatesSearchIsActive !== true  ? '' : '(IsActive~eq~'+templatesSearchIsActive+')~and~'

       let searchFinalSystemString:string = templatesSearchWithSystem === false  ? '(IsSystem~eq~'+templatesSearchWithSystem+')' : '((IsSystem~eq~'+templatesSearchWithSystem+')~or~(IsSystem~eq~'+!templatesSearchWithSystem+'))'
       let searchFinalSystemStringWithNext:string = templatesSearchWithSystem === false  ? '(IsSystem~eq~'+templatesSearchWithSystem+')~and~' : '((IsSystem~eq~'+templatesSearchWithSystem+')~or~(IsSystem~eq~'+!templatesSearchWithSystem+'))~and~'

       if (templatesSearchType === 'n')    searchFinalType = 'Name'
       if (templatesSearchType === 's')    searchFinalType = 'Subject'
       

       let filter = templatesSearchValue === '' || templatesSearchValue === null ?   searchFinalActiveStringWithNext+''+searchFinalSystemString  :  searchFinalActiveStringWithNext+''+searchFinalSystemStringWithNext+'('+searchFinalType+'~like~'+templatesSearchValue+')' 
       
       const page = pageNumber
       const pagesize = pageSize

       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            
            page,
            pagesize
          }

        
        }).then(response => {
          //console.log(response.data)
          //localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.templatesForLookup,cl) 
          
    } catch (error) {
      console.error('Failed to fetch templates', error)

    }  
    }),

    fetchTemplatesNew: flow(function* fetchProjects(pageNumber:number) {
      try {
       
        let filter:string = ''
        
        /*
        let filterGroup:string = ''

        let templatesSearchDataArray = [{filterName:'', filterValue:'',displayValue:''}]

        if (localStorage.getItem('templatesSearchArray') !== null) {
          templatesSearchDataArray = JSON.parse(localStorage.getItem('templatesSearchArray')!)
        }

        templatesSearchDataArray.filter(ssda => !(ssda.filterName === '' || ssda.filterValue === '')).slice().sort(function(a:any, b:any) {
          var nameB = a.filterName; 
          var nameA = b.filterName; 
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
          // eslint-disable-next-line
        }).map((ssda,index) => {
          if (filterGroup !== ssda.filterName) {
            if (filterGroup === '') {
              filter = filter + '('
            }
            else {
              filter = filter + ')~and~('
            }
          }
          
          if (filterGroup === ssda.filterName) {
            filter = filter + '~or~'
          }

          if (ssda.filterName !== '') {
            if (getStatusDateForFiltering(ssda.filterName) === true || getStatusNumberForFiltering(ssda.filterName) === true) {
              filter = filter + ssda.filterValue
            }
            else {  
              filter = filter + '('+ ssda.filterName+'~'+getOperatorForFiltering(ssda.filterName)+'~'+ssda.filterValue + ')'
            }
          }

          filterGroup = ssda.filterName
        })
        
        if (filter !== '') filter = filter + ')' 

        */

        filter = getFilterStringToBE(localStorage.getItem('templatesSearchArray'))

       const page = pageNumber
       const pagesize = 10

       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_TEMPLATES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            
            page,
            pagesize
          }

        
        }).then(response => {
          //console.log(response.data)
          localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.templates,cl) 
          
    } catch (error) {
      console.error('Failed to fetch templates', error)

    }  
    }),

    fetchTemplateById: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_TEMPLATES')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const templatesMerge:any = self.templates.filter(tmpl => tmpl.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.templates,templatesMerge)
          
    } catch (error) {
      console.error('Failed to fetch templates', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),

    fetchOperations: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)
      
       

       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_OPERATIONS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
          }

        
        }).then(response => {
          console.log(response.data)
          //localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.operations,cl) 
          
    } catch (error) {
      console.error('Failed to fetch operations', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),

    fetchStatesForOperations: flow(function* fetchProjects() {
      try {
       ////yield sleep(300)

       const accessToken = yield getToken()
      
       self.operations
       .filter(o => o.isFinished !== true)
       .map(o => {
          axios.get(getUrl('URL_OPERATIONS')+'/'+o.id+'/state',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              
            }

          
          }).then(response => {
            let newData = response.data.operation
            o.setNewData(newData.isError, newData.finishedCount, newData.totalCount, response.data.isFinished, newData.lastActivity)
            
            //console.log(response.data)
            //localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
            return response.data
            
          }).catch(error => {
            errorEvaluation(error)
          });

          return (null)
       } 
       )
  
    } catch (error) {
      console.error('Failed to fetch operations states', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }
 
    }),

    fetchStateForOperation: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
       self.operations.find(o => o.id === id)!.setNewLoadingState(false)

       const accessToken = yield getToken()
      
          axios.get(getUrl('URL_OPERATIONS')+'/'+id+'/state',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              
            }

          }).then(response => {
            let newData = response.data.operation
            self.operations.find(o => o.id === id)!.setNewData(newData.isError, newData.finishedCount, newData.totalCount, response.data.isFinished, newData.lastActivity)
            
          }).catch(error => {
            errorEvaluation(error)
          });          
  
    } catch (error) {
      console.error('Failed to fetch operations states', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }
 
    }),

    fetchErrorsForOperation: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
       self.operations.find(o => o.id === id)!.setNewLoadingErrorState(false)

       const accessToken = yield getToken()
      
          axios.get(getUrl('URL_OPERATIONS')+'/'+id+'/errors',{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              
            }

          }).then(response => {
            
            self.operations.find(o => o.id === id)!.setErrors(response.data)
            
          }).catch(error => {
            errorEvaluation(error)
          });          
  
    } catch (error) {
      console.error('Failed to fetch operations states', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }
 
    }),
    
    fetchCustomerOpenedEmailSent: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {

        ////yield sleep(300)
      
        let searchFinalString:string = ''
        

        if (searchType === 'UN')    searchFinalString = '(UserName~like~'+searchValue+')'
        if (searchType === 'TID')   searchFinalString = '(TemplateId~eq~'+searchValue+')'
        if (searchType === 'CID')   searchFinalString = '(CustomerId~eq~'+searchValue+')'
        if (searchType === 'E')     searchFinalString = '(Email~like~'+searchValue+')'
        
        let filter = searchValue === '' || searchValue === null ? '(CustomerId~eq~'+localStorage.getItem('openedCustomerId')+')' : '(CustomerId~eq~'+localStorage.getItem('openedCustomerId')+')~and~'+searchFinalString 

        //const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ADMIN_EMAILS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerEmailsSentTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerEmailsSentTotalCount') === '0') {
          self.customerOpenedEmailsSent.clear()
        }
        else {
          applySnapshot(self.customerOpenedEmailsSent,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedEmailsSent', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedEmailSentByEmail: flow(function* fetchProjects(email:string, searchValue:string,searchType:string, pageNumber:number) {
      try {

        //let searchFinalType:string = ''
        let searchFinalString:string = ''
        

        if (searchType === 'UN')    searchFinalString = '(UserName~like~'+searchValue+')'
        if (searchType === 'TID')   searchFinalString = '(TemplateId~eq~'+searchValue+')'
        if (searchType === 'CID')   searchFinalString = '(CustomerId~eq~'+searchValue+')'
        if (searchType === 'E')     searchFinalString = '(Email~like~'+searchValue+')'
        
        let filter = searchValue === '' || searchValue === null ? '(Email~like~'+email+')' : '(Email~like~'+email+')~and~'+searchFinalString 

        //const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_ADMIN_EMAILS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerEmailsSentTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerEmailsSentTotalCount') === '0') {
          self.customerOpenedEmailsSent.clear()
        }
        else {
          applySnapshot(self.customerOpenedEmailsSent,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedEmailsSent', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCustomerOpenedEmailSent: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedEmailsSent.clear()        
      } catch (error) {
        console.error('Failed to delete emails history', error)
      }
    }),
 
    fetchCustomerOpenedHistory: flow(function* fetchProjects(historyType:string, historyParam:string, pageNumber:number) {
      try {

        ////yield sleep(300)
      
        let page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        let url = ''
        let loadNextSite:boolean = false

        if (historyType === 'customer') { url = 'customers/'+localStorage.getItem('openedCustomerId')! }
        if (historyType === 'customerContact') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/contacts/'+historyParam }
        if (historyType === 'customerNote') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/notes/'+historyParam }
        if (historyType === 'offerByScenario') 
          { 
            url = 'offers/upgradeScenarios/'+historyParam+'/customers/'+localStorage.getItem('openedCustomerId')!
            loadNextSite = true
          }
        if (historyType === 'offer') { url = 'offers/'+historyParam }
        if (historyType === 'services') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/services' }
        if (historyType === 'categories') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories' }
        if (historyType === 'licenses') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/licenses' }
        if (historyType === 'license') { url = 'licenses/'+historyParam }
        if (historyType === 'template') { url = 'templates/'+historyParam }

        const cl = yield axios.get(getUrl('URL_CHANGES')+url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerHistoryTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerHistoryTotalCount') === '0') {
          self.customerOpenedHistory.clear()
          //applySnapshot(self.customerOpenedHistory,cl) 
        }
        else {
          applySnapshot(self.customerOpenedHistory,cl) 
        }

        //Načtení další strany do zásoby
        page = pageNumber+1
        
        if (loadNextSite === true)  {
          const clNext = yield axios.get(getUrl('URL_CHANGES')+url,{
            headers: {
              'Authorization': 'Bearer ' + accessToken
            },params: {
              page,
              pagesize
            }  }).then(response => {

            localStorage.setItem('customerHistoryNextSiteTotalCount',response.data.totalCount)
            
            return response.data.items
            
          }).catch(error => {
            errorEvaluation(error)
          });
          
          //console.log(cl)
          if (localStorage.getItem('customerHistoryNextSiteTotalCount') === '0') {
            self.customerOpenedHistoryNextSite.clear()
          }
          else {
            applySnapshot(self.customerOpenedHistoryNextSite,clNext) 
          }
        } 
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedHistory', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCustomerOpenedHistory: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedHistory.clear()
        self.customerOpenedHistoryNextSite.clear()        
      } catch (error) {
        console.error('Failed to delete history', error)
      }
    }),    

    
    fetchLicensesTransfersList: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {

        ////yield sleep(300)
      
        //console.log(searchValue)
        let searchFinalType:string = ''
        let searchFinalString:string = ''

        if (searchType === 'AN') searchFinalType = 'AccountNumber'
        if (searchType === 'Name') searchFinalType = 'BusinessName'
        if (searchType === 'IC') searchFinalType = 'CompanyRegistrationNumber'
        if (searchType === 'e') searchFinalType = 'Email'

        if (searchType === 'q') searchFinalString = '((AccountNumber~like~'+searchValue+')~or~(BusinessName~like~'+searchValue+')~or~(CompanyRegistrationNumber~like~'+searchValue+'))'

        const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_TRANSFER_LICENSE'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          
          
          localStorage.setItem('licensesTransfersTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        if (localStorage.getItem('licensesTransfersTotalCount') === '0') {
          self.licensesTransfers.clear()
        }
        else {
          applySnapshot(self.licensesTransfers,cl) 
        }
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam převodů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchLicensesTransfersListNew: flow(function* fetchProjects(pageNumber:number) {
      try {

        self.licensesTransfers.clear()
        let filter:string = ''

        filter = getFilterStringToBE(localStorage.getItem('licensesTransfersSearchArray'))

        
        //const filter = searchValue === '' || searchValue === null ? '' : searchFinalString !== '' ? searchFinalString : searchFinalType+'~like~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_TRANSFER_LICENSE'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          
          
          localStorage.setItem('licensesTransfersTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        if (localStorage.getItem('licensesTransfersTotalCount') === '0') {
          self.licensesTransfers.clear()
        }
        else {
          applySnapshot(self.licensesTransfers,cl) 
        }
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam převodů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),


    fetchLicensesTransferOldCustomer: flow(function* fetchProjects(accountNumber:string) {
      try {

       ////yield sleep(300)
       self.licensesTransferOldCustomer.clear()
       const accessToken = yield getToken()
       // eslint-disable-next-line
       const filter = 'AccountNumber~like~'+accountNumber
       const page = 1
       const pagesize = 1

         const searchData = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         },params: {
           filter,
           page,
           pagesize
         }  }).then(response => {


         //else {localStorage.setItem('customersTooMany','0')}
         
         //localStorage.setItem('customersFirstId',response.data.items[0].id)
         
         return response.data.items
         
       }).catch(error => {
         errorEvaluation(error)
       });

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+searchData[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferOldCustomer,[cl]) 
          
    } catch (error) {
      self.licensesTransferOldCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchLicensesTransferOldCustomerLicenses: flow(function* fetchProjects(customerId:string) {
      try {

       ////yield sleep(300)
       self.licensesTransferOldCustomerLicenses.clear()
       const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_TRANSFER_CUSTOMER_LICENSES')+customerId+'?status=A&onlyActive=true' ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferOldCustomerLicenses,cl) 
          
    } catch (error) {
      self.licensesTransferOldCustomerLicenses.clear()
      console.error('Nepodařilo se načíst licence zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchLicensesTransferNewCustomer: flow(function* fetchProjects(accountNumber:string) {
      try {

       ////yield sleep(300)
       self.licensesTransferNewCustomer.clear()
       const accessToken = yield getToken()
       // eslint-disable-next-line
       const filter = 'AccountNumber~like~'+accountNumber
       const page = 1
       const pagesize = 1

         const searchData = yield axios.get(getUrl('URL_CUSTOMER_LIST'),{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         },params: {
           filter,
           page,
           pagesize
         }  }).then(response => {


         //else {localStorage.setItem('customersTooMany','0')}
         
         //localStorage.setItem('customersFirstId',response.data.items[0].id)
         
         return response.data.items
         
       }).catch(error => {
         errorEvaluation(error)
       });

        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+searchData[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferNewCustomer,[cl]) 
          
    } catch (error) {
      self.licensesTransferNewCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),    

    fetchLicensesTransferNewCustomerById: flow(function* fetchProjects(id:string) {
      try {

       ////yield sleep(300)
       self.licensesTransferNewCustomer.clear()
       const accessToken = yield getToken()


        const cl = yield axios.get(getUrl('URL_CUSTOMER_LIST')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferNewCustomer,[cl]) 
          
    } catch (error) {
      self.licensesTransferNewCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),   

    deleteLicencesTransfer: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.licensesTransferOldCustomer.clear()
        self.licensesTransferOldCustomerLicenses.clear()
        self.licensesTransferNewCustomer.clear()
                
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),

    deleteLicencesTransferOldCustomerLicenses: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.licensesTransferOldCustomerLicenses.clear()        
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),

    fetchSupports: flow(function* fetchProjects(pageNumber:number, isCustomer?:boolean) {
      try {
        self.supports.clear()
        
        let filter:string = ''
        let order:string = ''

        filter = getFilterStringToBE(localStorage.getItem('supportSearchArray'), isCustomer)

        if (localStorage.getItem('supportOrderBy') === 'LastEditDate') order = 'LastEditDate~asc'
        if (localStorage.getItem('supportOrderBy') === 'LastEditDateDesc') order = 'LastEditDate~desc'

        //console.log(filter)

        const page = pageNumber
        const pagesize = isCustomer === true ? 10:10

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_SUPPORT_CONTACTS'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

            //console.log(response.data.totalCount)
            localStorage.setItem('lastSupportTotalCount',response.data.totalCount)
            //console.log(localStorage.getItem('lastSupportTotalCount'))
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        if (localStorage.getItem('lastSupportTotalCount') === '0') {
          self.supports.clear()
        }
        else {
          self.supports.clear()
          applySnapshot(self.supports,cl) 
        } 
        
       
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst kontakty. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchSupportById: flow(function* fetchProjects(id:string) {
      try {
       ////yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(getUrl('URL_SUPPORT_CONTACTS')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const supportsMerge:any = self.supports.filter(supp => supp.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.supports,supportsMerge)
          
    } catch (error) {
      console.error('Failed to fetch supports', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),

    deleteSupport: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.supports.clear()        
      } catch (error) {
        console.error('Failed to delete supports', error)
      }
    }),

    fetchFilesForSupport: flow(function* fetchProjects(supportId:string) {
      try {
        self.files.clear()
        let filter:string = '(linkId~eq~'+supportId+')'
        
        self.getSupportById(supportId)?.solutions.map(s => {
          filter = filter+'~or~(linkId~eq~'+s.id+')'
        })

        

        const page = 1
        const pagesize = 999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_FILES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        applySnapshot(self.files,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst soubory. Může dojít k odhlášení!', error)
    } 
    }),

    fetchFilesForMeeting: flow(function* fetchProjects(meetingId:string) {
      try {
        self.files.clear()
        let filter:string = '(linkId~eq~'+meetingId+')'
        
        self.getMeetingById(meetingId)?.points.map(p => {
          filter = filter+'~or~(linkId~eq~'+p.id+')'
        })

        

        const page = 1
        const pagesize = 999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_FILES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        applySnapshot(self.files,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst soubory. Může dojít k odhlášení!', error)
    } 
    }),

    fetchFilesForCustomer: flow(function* fetchProjects(customerId:string) {
      try {
        self.files.clear()
        let filter:string = '(linkId~eq~'+customerId+')'
        let order:string = 'CreatedDate~desc'
        
        const page = 1
        const pagesize = 999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_FILES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        applySnapshot(self.files,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst soubory. Může dojít k odhlášení!', error)
    } 
    }),

    fetchFilesCountForOpenedCustomer: flow(function* fetchProjects() {
      try {
        
        let filter:string = '(linkId~eq~'+localStorage.getItem('openedCustomerId')+')'
        let order:string = 'CreatedDate~desc'
        
        const page = 0
        const pagesize = 0

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_FILES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            order,
            page,
            pagesize
          }  }).then(response => {

          return response.data.totalCount
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        self.customerOpened.find(c => c.id === localStorage.getItem('openedCustomerId'))!.setFilesCount(cl)
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst soubory. Může dojít k odhlášení!', error)
    } 
    }),


    fetchFilesForTask: flow(function* fetchProjects(taskId:string) {
      try {
        self.files.clear()
        let filter:string = '(linkId~eq~'+taskId+')'
        
        self.getTaskById(taskId)?.points.map(p => {
          filter = filter+'~or~(linkId~eq~'+p.id+')'

          p.solutions.map(s => {
            filter = filter+'~or~(linkId~eq~'+s.id+')'
            return null
          })

        })

        self.getTaskById(taskId)?.solutions.map(s => {
          filter = filter+'~or~(linkId~eq~'+s.id+')'
        })

        

        const page = 1
        const pagesize = 999

        const accessToken = yield getToken()

        const cl = yield axios.get(getUrl('URL_FILES'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
        applySnapshot(self.files,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst soubory. Může dojít k odhlášení!', error)
    } 
    }),

    batchDeleteFilesByLinkId: flow(function* fetchProjects(linkId:string) {
      try {
        
        const accessToken = yield getToken()

        yield axios.post(getUrl('URL_FILES')+'/batch-delete', {deleteIds: self.files.filter(f => f.linkId === linkId).map(f => f.id)} ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {

            destroy(self.files.filter(f => f.linkId === linkId))
          //return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        
        
          
    } catch (error) {
      console.error('Nepodařilo se smazat soubory. Může dojít k odhlášení!', error)
    } 
    }),

    deleteFiles: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.files.clear()        
      } catch (error) {
        console.error('Failed to delete files', error)
      }
    }),

    openFile: flow(function* fetchProjects(id:string, contentType:string, fileName:string) {
      try {
        // ... get users from server emulation
        ////yield sleep(300)
        //console.log(getUser().id)

        const accessToken = yield getToken()

        yield axios.get(getUrl('URL_FILES')+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
          responseType: 'blob',
        })
        .then(response => {
          let blob = new Blob([response.data], {type: contentType})
          //const downloadLink = document.createElement("a")

          if (["image/jpeg","image/png","image/bmp","application/pdf"].includes(contentType)) {
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, "_blank");
          }
          else {
            const downloadLink = document.createElement("a")
          
            downloadLink.href = URL.createObjectURL(blob)
            //downloadLink.target = "blank"
            downloadLink.download = fileName
            downloadLink.click()
          }

          

          /*
          downloadLink.href = URL.createObjectURL(blob)
          //downloadLink.target = "blank"
          downloadLink.download = fileName
          downloadLink.click()
          */
          
        }).catch(error => {
          errorEvaluation(error)
        });

      } catch (error) {
        console.error('Nepodařilo se stáhnout soubor!', error)
        
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

  }))
  .views(self => ({
    getCustomerListAllByFilter2(value: string) {
      
      if (Object.keys(self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase()))).length === 0) {
        
        
        (async () => {
          await self.fetchCustomerListAllNoCache(value,'',1)
          console.log('test')
          return self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase())).slice().sort(function(a:any, b:any) {
            var sort1A = a.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
            var sort1B = b.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
        
            if (sort1A! < sort1B!) {
              return -1;
            }
            if (sort1A! > sort1B!) {
              return 1;
            }
            return 0;
          })


        })();
      }

      else {
      
        return self.customerListAll.filter(cl => cl.accountNumber?.toLowerCase().includes(value!.toLowerCase()) || cl.businessNameForSearch?.toLowerCase().includes(value!.toLowerCase())).slice().sort(function(a:any, b:any) {
          var sort1A = a.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
          var sort1B = b.accountNumber.toLowerCase().includes(value.toLowerCase()) ? 1 : 2; 
      
          if (sort1A! < sort1B!) {
            return -1;
          }
          if (sort1A! > sort1B!) {
            return 1;
          }
          return 0;
        })

      }

    },
  }))

export type RootStoreType = Instance<typeof RootStore>
export type CustomerListStoreType = Instance<typeof CustomerList>
export type CustomerOfferCountStoreType = Instance<typeof CustomerOfferCount>
export type CustomerOpenedCategoriesStoreType = Instance<typeof CustomerOpenedCategories>
export type CustomerOpenedLicensesStoreType = Instance<typeof CustomerOpenedLicenses>
export type CustomerOpenedServicesStoreType = Instance<typeof CustomerOpenedServices>
export type CustomerOpenedDocumentsStoreType = Instance<typeof CustomerOpenedDocuments>
export type CustomerUsersStoreType = Instance<typeof CustomerUsers>
export type InviteStoreType = Instance<typeof Invites>
export type LoggedUserStoreType = Instance<typeof LoggedUser>
export type OrderStoreType = Instance<typeof Order>
export type OverviewsCustomersStoreType = Instance<typeof OverviewsCustomers>
export type DatafiltersCustomersStoreType = Instance<typeof DatafiltersCustomers>
export type OverviewsOffersStoreType = Instance<typeof OverviewsOffers>
export type TemplatesStoreType = Instance<typeof Templates>
export type LicensesTransferOldCustomerStoreType = Instance<typeof LicensesTransferOldCustomer>
export type LicensesTransferNewCustomerStoreType = Instance<typeof LicensesTransferNewCustomer>
/*
export const setupApp = () => {
  const customerList = CustomerList.create({ id: '', accountNumber: "", businessName: "", companyRegistrationNumber: "", taxIdentificationNumber: "", isTaxAble: false  })
  
  const rootStoreTree = RootStore.create(
    {
      //customerList
    }
  )

  connectReduxDevtools(require('remotedev'), rootStoreTree)

  return rootStoreTree
}

const rootStore = setupApp()

	export const useStore = (): RootStoreType =>
  useContext(React.createContext(rootStore))

  */