import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import {Button, Modal, Alert, Form, Col} from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

interface UpgradeOfferAddIndividualItemProps {
  id: string,
}

const UpgradeOfferAddIndividualItem: React.FC<UpgradeOfferAddIndividualItemProps & RouteComponentProps> = (props:any) => {
  // Nastavení práv pro editaci
  //const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  
  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')

  // Otevírání a zavírání modálního okna Přidání nové pozvánky
  const handleClose = () => { 
    setShow(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }  

  // Načtení Store
  //const rootStore = useStore()
  const rootUpgradeStore = useUpgradeStore()

  
  // Funkce pro založení nového kontaktu
  const handleNewItem = (itemData:string, noteData:string, priceData:number, typeData:string, valueData:number, vatTypeData:string, vatRateData: number) => {
    setError(null)
    setLoading(true);

    // Zavolání API pro přidání
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      } 
      // Zavolání API
      
      await axios.post(getUrl('URL_OFFERS')+'/'+props.id+'/items', { offerId: props.id, name: itemData, note: noteData, value: valueData, type: typeData, vatType: vatTypeData, vatRate: vatRateData }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          (async () => {
            await rootUpgradeStore.getUpgradeOfferById(props.id)?.setData(response.data)
            const dataToSave = rootUpgradeStore.upgradeOffers.filter(data => data.id !== props.id).concat(response.data)
            await rootUpgradeStore.fetchUpgradeOffersFromData(dataToSave)

          //await rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          await setLoading(false)
          await setShowForm('nodisplay')
          await setShowInfo(true)
          await sessionStorage.setItem('autoCloseModal','1');

          //rootStore.fetchCustomerOpened()

          // Po 2800 ms celé modální okno zavřeme

          await setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);
          })()
        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
        

    })()
    
  }
 
  // Schéma validace formuláře
  const schemaNewItem = Yup.object().shape({
    item: Yup.string().required('Název musí být vyplněn!'),
    //value: Yup.number().required('Částka musí být vyplněna!'), 
    type: Yup.string().required('Typ individuální položky musí být vyplněn!'),

    value: Yup.number()
      .required('Hodnota musí být vyplněna!')
      .when('type', {
        is: 'L',
        then: Yup.number()
        .min(0, 'Minimální hodnota slevy je 0 %!')
        .max(100, 'Maximální hodnota slevy je 100 %!')
      })
      .when('type', {
        is: 'R',
        then: Yup.number()
        .max(0, 'Hodnota pro softwarovou rentu musí být záporná!')
      }),

  })   

  return (
    <span>
      <Button variant="success" size="sm" onClick={handleShow}>
        + Přidat individuální položku
      </Button>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Individuální položka objednávky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Individuální položka byla úspěšně přidána!​</strong>
          </Alert> 
          <div className={showForm} >
            <Formik
              validationSchema={schemaNewItem}
              initialValues={{ item: '', note: '', price: 0, type: '', value: 0, vatType: 'Základní', vatRate: 21 }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleNewItem(values.item, values.note, values.price, values.type, values.value, values.vatType, values.vatRate);              
                  setSubmitting(false);
                }, 1);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup controlId="type">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ individuální položky</FormLabel>
                      <Col>
                        <FormControl  as="select" 
                          name="type"
                          value={values.type!}
                          onChange={async(e) => {await handleChange(e); await setFieldValue('item', e.target.value === 'L' ? 'Sleva z ceny licence' : e.target.value === 'A' ? '' : 'Softwarová renta');  }}
                          isValid={touched.item && !errors.type} 
                          isInvalid={!!errors.type}
                        >
                          <option value="">--- Vyberte ---</option>
                          <option value="L">Procentní sleva z ceny licence (0 - 100 %)</option>
                          <option value="A">Individuální položka absolutní</option>
                          <option value="R">Softwarová renta</option>

                        </FormControl>
                        <Form.Control.Feedback type="invalid">
                          {errors.type}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="item">
                    <Form.Row>
                      <FormLabel column lg={3}>Název</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="item"
                          value={values.item!}
                          onChange={handleChange}
                          isValid={touched.item && !errors.item} 
                          isInvalid={!!errors.item}
                        />
                        <span style={{display: values.type === 'L' ? 'block':'none'}}><i className="fas fa-info-circle"></i>&nbsp;<i><strong>Náhled názvu položky:</strong> {values.item} ({values.value} %)</i></span>
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.item}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="note">
                    <Form.Row>
                      <FormLabel column lg={3}>Popis</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="note"
                          value={values.note!}
                          onChange={handleChange}
                          isValid={touched.note && !errors.note} 
                          isInvalid={!!errors.note}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.note}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup> 
                  <FormGroup controlId="value">
                    <Form.Row>
                      <FormLabel column lg={3}>Hodnota</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="value"
                          value={values.value!}
                          onChange={handleChange}
                          isValid={touched.value && !errors.value} 
                          isInvalid={!!errors.value}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.value}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>   

                  <FormGroup controlId="vatType">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ DPH</FormLabel>
                      <Col>
                        <FormControl  as="select" 
                          name="vatType"
                          value={values.vatType!}
                          //onChange={handleChange}
                          onChange={async (e) => {
                            const value = e.target.value;
                            await handleChange(e);
                            //console.log(e.target);
                          
                            if (value === 'Snížená') setFieldValue('vatRate', 12);
                            if (value === 'Základní') setFieldValue('vatRate', 21);
                            if (value === 'Nulová') setFieldValue('vatRate', 0);
                          }}
                          isValid={touched.vatType && !errors.vatType} 
                          isInvalid={!!errors.vatType}
                        >
                          <option value="Základní">Základní</option>
                          <option value="Snížená">Snížená</option>
                          <option value="Nulová">Nulová</option>
                        </FormControl>
                        <Form.Control.Feedback type="invalid">
                          {errors.vatType}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup>                   
                  <FormGroup controlId="vatRate">
                    <Form.Row>
                      <FormLabel column lg={3}>Sazba DPH</FormLabel>
                      <Col>
                        <FormControl 
                          type="number"
                          name="vatRate"
                          value={values.vatRate!}
                          onChange={handleChange}
                          isValid={touched.vatRate && !errors.vatRate} 
                          isInvalid={!!errors.vatRate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.vatRate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                  </FormGroup>

                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={loading} block>{loading ? 'Přidávám individuální položku...' : 'Přidat individuální položku'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>

    </span>

  )  
  
}

export default withRouter(observer(UpgradeOfferAddIndividualItem))
