import React, {useState}  from 'react'
import Loader from "react-loader"
import { useParams } from 'react-router'

import TopMenu from '../../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../../parts/TopMenu/SideMenu.parts'
import CustomerHeader from '../../../parts/customerHeader/CustomerHeader.parts'

import { UpgradeOfferDetailUrlParams } from '../../../Routes'

import { Container, Badge, Card, Button, Modal, Form } from 'react-bootstrap'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { useMount } from '../../../helpers/lifecycle-hooks'

//import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useStore, useUpgradeStore, useDialsStore } from '../../../store'
import { observer } from 'mobx-react'
import { getToken, getUrl, handleMenuContent, getModuleOrder, getPriceListDetailOrder, checkVersion } from '../../../Utils/Common'


import * as Yup from 'yup'

import NumberFormat from 'react-number-format'

import UpgradeOfferDetailEdit from '../components/Upgrade.OfferDetail.Edit.part'

function UpgradeOfferDetail(props:any) {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('BigUser')! === '1')  ) ? true : false
  const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  //const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false

  // eslint-disable-next-line
  const { id } = useParams<UpgradeOfferDetailUrlParams>()
  
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  
  // Nastavení stavů pro zobrazení  
  const [error, setError] = useState(null)
  const [showE, setShowE] = useState(false)
  
  // Pokud není nastaveno id otevřené firmy, přepneme stránku na Výběr firmy  
  if (!localStorage.getItem('openedCustomerId')) {
    props.history.push('/customers-open')
  }

  // Načtení Store
  const rootStore = useStore()
  const rootDialsStore = useDialsStore()
  const rootUpgradeStore = useUpgradeStore()

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    await checkVersion()
    // Kontrola přihlášení  
    const aToken = await getToken()
    if ((aToken === null) || (aToken === undefined)) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {    
      //Načtení souvisejících číselníků podle práv
      //if (rightsBH) await rootStore.fetchTemplates() 
      if (rightsBH) await rootUpgradeStore.fetchUpgradeOffersScenarios()   
      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers()

          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      }
      //Konec sekce F5

      //Pokud mám nastaveno ID otevřeného zákazníka
      if (localStorage.getItem('openedCustomerId') !== null) {
        //Pokud mi store nevrátí nic pro získání otevřeného zákazníka, tak přenačtu store
        if (rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!) === undefined) { await rootStore.fetchCustomerOpened() }
      }   
      //Při každém otevření stránky načítáme objednávky
      await rootUpgradeStore.fetchUpgradeOffers(id)
      //await rootUpgradeStore.fetchUpgradeOffersToCustomer(localStorage.getItem('openedCustomerId')!)
      if (rootUpgradeStore.upgradeOffers.find(uo => uo.id === id) !== undefined) {
        await rootDialsStore.fetchDialsPrograms()

        await rootDialsStore.fetchDialsLicenseTypesAll()
        //await rootDialsStore.fetchDialsLicenseTypes(rootUpgradeStore.upgradeOffers.find(uo => uo.id === id)!.upgradeScenario?.program.id!)
        await rootDialsStore.fetchDialsModulesAll()
        //await rootDialsStore.fetchDialsModules(rootUpgradeStore.upgradeOffers.find(uo => uo.id === id)!.upgradeScenario?.program.id!)
        await rootDialsStore.fetchDialsPriceListsModulesAll()
        //await rootDialsStore.fetchDialsPriceListsModules(rootUpgradeStore.upgradeOffers.find(uo => uo.id === id)!.upgradeScenario?.program.id!)
      }

      
    } 
    if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }     
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  // Načtení informací o aktuálním zákazníkovy do proměnné
  const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)

  const upgradeOffer = rootUpgradeStore.getUpgradeOfferById(id)
  const customerDeliveryAddressIsOK = customer?.deliveryAddress !== null ?  customer?.deliveryAddress.surName !== '' || customer.deliveryAddress.name !== '' ? true:false : true

  // eslint-disable-next-line
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    //setShowInfo(false)
    //setShowForm('')
    //setPrices()
    //setShow(true)
    
  }

  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    //setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteOffer = () => {
    (async () => {
      if (await confirm("Opravdu chcete objednávku smazat?",'Ano, smazat', 'Ne', {title: 'Smazání objednávky'})) {
        deleteOffer()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání objednávky
  const deleteOffer = () => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.delete(getUrl('URL_OFFERS')+id, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání také odebrat ze store
          rootUpgradeStore.deleteUpgradeOffers()
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  const getModuleCount = (moduleCode:string,programCode:string,values:any) => {
    let count:number = 99
    //console.log(values)
    if (programCode === "S") {
      if (moduleCode === "K") count = values.KCheck ? values.TCount : 0
      if (moduleCode === "U") count = values.UCheck ? values.TCount : 0
      if (moduleCode === "F") count = values.FCheck ? values.TCount : 0
      if (moduleCode === "M") count = values.MCheck ? values.TCount : 0
      if (moduleCode === "S") count = values.SCheck ? values.TCount : 0
      if (moduleCode === "P") count = values.PCheck ? values.TCount : 0
      if (moduleCode === "E") count = values.ECheck ? values.TCount : 0
      if (moduleCode === "J") count = values.JCheck ? values.TCount : 0
      if (moduleCode === "A") count = values.ACheck ? values.TCount : 0
      if (moduleCode === "Y") count = values.YCheck ? values.TCount : 0
    }
    else {
      if (moduleCode === "K") count = values.KCount
      if (moduleCode === "U") count = values.UCount
      if (moduleCode === "F") count = values.FCount
      if (moduleCode === "M") count = values.MCount
      if (moduleCode === "S") count = values.SCount
      if (moduleCode === "P") count = values.PCount
      if (moduleCode === "E") count = values.ECount
      if (moduleCode === "J") count = values.JCount
      if (moduleCode === "A") count = values.ACount
      if (moduleCode === "Y") count = values.YCount
    }

    return count
  }


  // Funkce pro uložení
  // eslint-disable-next-line
  const handleSave = (values:any,toClipboard:boolean) => {
    //console.log('calculating');
    //console.log(values);
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if ((aToken === null) || (aToken === undefined)) {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   

      //setLoading(true)
      //const upgradeScenario = rootCalculatorsStore.calculatorsUpgradeScenarios[0]
      //const priceList = rootDialsStore.dialsPriceListsModules

      const targetLicense = {
        id: rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.id,
        count: values.programCode === 'D' ? values.tYCount : values.tTotalCount,
        isActive: rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.isActive,
        status: rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.status,
        program: rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.program,
        programVersion: rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.programVersion,
        licenseType: rootDialsStore.dialsLicenseTypes.find(data => data.id === values.tLicenseType),
        modules: 
          rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.modules!
          .map(module => ({ 
            count: getModuleCount(module.module.code!, values.programCode, {TCount: values.tTotalCount, KCount: values.tKCount, UCount: values.tUCount, FCount: values.tFCount, MCount: values.tMCount, SCount: values.tSCount, PCount: values.tPCount, ECount: values.tECount, JCount: values.tJCount, ACount: values.tACount, YCount: values.tYCount, KCheck: values.tKCheck, UCheck: values.tUCheck, FCheck: values.tFCheck, MCheck: values.tMCheck, SCheck: values.tSCheck, PCheck: values.tPCheck, ECheck: values.tECheck, JCheck: values.tJCheck, ACheck: values.tACheck, YCheck: values.tYCheck}),
            module: module.module 
          }))
        
      }

      let dataToSend={}

      dataToSend = {
        offerId: id,  
        targetLicense: targetLicense
        }


      if (toClipboard === true) {
        /*
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = JSON.stringify(dataToSend) ;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        */
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(JSON.stringify(dataToSend));
        hiddenElement.target = '_blank';
        hiddenElement.download = 'JSON_calculator.txt';
        hiddenElement.click();
      }

      if (toClipboard === false) {
        // Zavolání API   
        await axios.post(getUrl('URL_OFFERS')+id, dataToSend, 
        { 
          headers: { 'Authorization': 'Bearer ' + aToken}
        }
          ).then(response => {
            
            //setTotalPrice(response.data.totalPrice)
            //setShowTP(true)
            rootUpgradeStore.fetchUpgradeOffersFromData(response.data)
            console.log(response.data)
            
            // Nastavovačky, co se má v případě, že je to OK, zobrazit
            //setLoading(false)
            //setShowForm('nodisplay')
            //setShowInfo(true)
            sessionStorage.setItem('autoCloseModal','1');

            // Po 2800 ms celé modální okno zavřeme
            setTimeout(() => {
              if (sessionStorage.getItem('autoCloseModal') === '1') {  
                //setShow(false)
                setError(null)
                sessionStorage.removeItem('autoCloseModal')
                //setShowInfo(false)
                //setShowForm('')
              }
            }, 2800);


          }).catch(error => {
            //setLoading(false)
            //const errorMessage:any = (<ErrorAlert errorData={error} />)
            //setError(errorMessage)
          });

        }
      //setLoading(false)
      })()    
  }
  
  // Schéma validace formuláře
  // eslint-disable-next-line
  const schemaUpdate = Yup.object().shape({
    
  })   

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              <CustomerHeader />
              <Container fluid>
                <div className="float-right">
                  {rightsBH &&<Button variant="outline-danger" size="sm" onClick={() => { handleOnClickDeleteOffer() } } >
                    Smazat objednávku
                  </Button>}
                </div>
                <h4>Detail objednávky upgrade</h4>
                <br />
                {upgradeOffer === undefined &&<div>Objednávka neexistuje.</div>}
                {upgradeOffer !== undefined &&<div>
                <Card
                  bg='light'
                  text='dark'
                  className={rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.program.code === 'D' ? 'border-left-danger':'border-left-info'}
                >
                  <Card.Header as="h3">
                    
                    {rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.program.name} {rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.programVersion.version} (pro rok {rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.program.code === 'D' ? 2004+rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.programVersion.version! : 1991+rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.programVersion.version! })
                    
                  </Card.Header>
                  <Card.Body>
                    <div className="float-right" style={{textAlign: 'right', fontWeight: 'normal', maxWidth: '230px', fontSize: '80%'}}>
                      <h4>Celkem</h4>
                      <h3><Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.totalWithoutVat!} />&nbsp;Kč</Badge></h3>  
                      <strong>Uvedené ceny jsou bez DPH.</strong>
                      <br />
                      Při distribuci k nim bude připočtena daň dle aktuální legislativy.
                      <br />
                      <strong>Orientační výpočet DPH:</strong>
                      <br />
                      <span style={{fontStyle: 'italic'}}>
                      Daň <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.totalVat!} />&nbsp;Kč
                      <br />
                      Celkem s daní <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.totalWithVat!} />&nbsp;Kč
                      <br />
                      <br />
                      </span>
                    </div>  
                    <div className="float-left">  
                      <h5>Typ licence</h5> {rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.licenseType.code}&nbsp;-&nbsp;{rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.licenseType.name}
                      <br /><br />
                      <h5>Počet</h5> {rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.count}&nbsp;PC
                      <br /><br />
                      
                      <h5>Jednorázové služby</h5>

                      <Form.Check 
                        type="switch"
                        id="custom-switch-dI"
                        label="Instalace upgrade vzdálenou správou"
                        name="dInstall"
                        disabled={!rights}
                        //onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                        //onClick={async () => {await handleDefault(values);await handleReset();}}
                        //checked={true}
                      />
                      <Form.Check 
                        type="switch"
                        id="custom-switch-dG"
                        label="Souhlas se zpracováním osobních údajů (GDPR)"
                        name="dGDPR"
                        disabled={!rights}
                        //onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                        //onClick={async () => {await handleDefault(values);await handleReset();}}
                        //checked={true}
                        />
                      <br />
                      <h5>Způsob distribuce</h5>
                      <Form.Check 
                        type="switch"
                        id="custom-switch-distE"
                        label="Elektronicky (bez poplatku)"
                        name="distEl"
                        disabled={!rights}
                        defaultChecked={upgradeOffer.distributionMethod === 'E' ? true:false}
                        //onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                        //onClick={async () => {await handleDefault(values);await handleReset();}}
                        //checked={true}
                      />
                      <Form.Check 
                        type="switch"
                        id="custom-switch-distD"
                        label="Dobírkou (poplatek 500 Kč)"
                        name="distDob"
                        disabled={!rights}
                        defaultChecked={upgradeOffer.distributionMethod === 'D' ? true:false}
                        //onChange={async (e:any) => {await handleClose2(e);await handleChange(e);}}
                        //onClick={async () => {await handleDefault(values);await handleReset();}}
                        //checked={true}
                        />

                      {!customerDeliveryAddressIsOK &&<span style={{color: 'red', fontSize: '80%'}}>Vypadá to, že máte chybu v dodací adrese. Na přehledu firmy, prosím, zkontrolujte vaší dodací adresu!</span>}                                
                      <br />
                      
                      <br />
                      {rights && <UpgradeOfferDetailEdit id={id} label="Upravit rozsah licence" showIcon={true} block={false} />}
                      
                      {rights &&<div  style={{marginBottom: '10px'}}></div>}
                      {rights &&<Button variant="success" size="lg" onClick={() => {  } }><i className="fas fa-cart-arrow-down"></i>&nbsp;Závazně objednat</Button>}
                    </div>
                    
                    
                  </Card.Body>
                </Card>
                <br />
                <h5>Rozpis ceny modulů</h5>
                <p>V této části naleznete detailní rozpis ceny upgrade jednotlivých modulů. Můžete zde také počty PC upravovat.</p>
                {rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.modules! !== undefined && rootUpgradeStore.getUpgradeOfferById(id)?.targetLicense.modules!.slice().sort(function(a:any, b:any) {
                  var nameA = getModuleOrder(a.module.code); 
                  var nameB = getModuleOrder(b.module.code); 
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                }).map(modules => (
                
                  <div key={modules.module.id} style={{marginBottom: '10px'}}>
                  <Card
                    bg='light'
                    text='dark'
                    style={{minWidth: '350px'}}
                  >
                    <Card.Header as="h5">
                      <strong>{modules.module.name}</strong>
                      <div className="float-right" style={{textAlign: 'right', fontWeight: 'normal', minWidth: '150px'}}>
                        <Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.totalPrice!} />&nbsp;Kč</Badge>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      
                      <div className="float-left">
                        <table>
                          <tbody>
                            <tr>
                              <td></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>{upgradeOffer.actualLicense.program.name}&nbsp;{upgradeOffer.actualLicense.programVersion.version}</Badge></td>
                              <td style={{textAlign:'center', minWidth: '20px'}}></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={rootUpgradeStore.getUpgradeOfferById(id)?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>{upgradeOffer.upgradeScenario.program.name}&nbsp;{upgradeOffer.upgradeScenario.programVersion.version}</Badge></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Počet&nbsp;PC</td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.actualLicense.modules?.find(actualModule => actualModule.module.id === modules.module.id)?.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              <td style={{textAlign:'center'}}><i className="fas fa-angle-double-right"></i></td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} value={modules.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              <td>{rights && <UpgradeOfferDetailEdit id={id} label="Upravit" showIcon={false} block={false} />}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className='nodisplay'>
                        Aktuální počet PC:&nbsp;<input type="text" disabled={true} value={rootUpgradeStore.getUpgradeOfferById(id)?.actualLicense.modules?.find(actualModule => actualModule.module.id === modules.module.id)?.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} />
                        <br />
                        Požadovaný počet PC:&nbsp;<input type="text" disabled={true} value={modules.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} />
                        
                        <span style={{ display: 'none',fontSize: '90%', fontStyle: 'italic'}}>(aktuálně {rootUpgradeStore.getUpgradeOfferById(id)?.actualLicense.modules?.find(actualModule => actualModule.module.id === modules.module.id)?.count!} PC)</span>
                        <br />
                        </div>
                        
                        {Object.keys(rootUpgradeStore.getUpgradeOfferById(id)?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items!).length > 0 &&
                        <div>
                        <hr />
                        <h6 style={{fontWeight: 'normal'}}>Detailní rozpis ceny:</h6> 
                        {rootUpgradeStore.getUpgradeOfferById(id)?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items?.slice().sort(function(a:any, b:any) {
                          var nameA = getPriceListDetailOrder(a.priceType, a.isFirst); 
                          var nameB = getPriceListDetailOrder(b.priceType, b.isFirst);
                          
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        }).map((priceDetail, index) => (
                          <div key={index} style={{fontStyle: 'italic'}}>
                            
                            {priceDetail.priceType === 'P' && 'Upgrade'}
                            {priceDetail.priceType === 'F' && 'Upgrade z rozšíření v ochranném období'}
                            {priceDetail.priceType === 'S' && 'Doplatek za přechod na vyšší typ licence'}
                            {priceDetail.priceType === 'E' && 'Rozšíření v rámci upgrade s 20% slevou'}
                            
                            {priceDetail.priceType !== 'F' ? priceDetail.isFirst ? ' 1. PC (':' další PC (':' ('}
                            <strong>{priceDetail.count}x</strong>):&nbsp;
                            <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={priceDetail.price!} />&nbsp;Kč
                          </div>
                        ))}
                        </div>}

                      </div>
                    </Card.Body>
                  </Card>
                  </div>
                
                ))} 
                <br />
                </div>}
              </Container>

            </Loader>  
            </main>
          </div>
        </div>      
      

        <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání objednávky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
    </div> 
  );
}

export default observer(UpgradeOfferDetail)
