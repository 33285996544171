import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getModuleOrder, getNumberFormated, getPriceListDetailOrder, } from '../../../Utils/Common'

import { Button, Modal, Spinner, Dropdown, Card, Table, Badge, OverlayTrigger, Popover } from 'react-bootstrap'

import UpgradeOfferState  from './Upgrade.Offer.StateToDiv.part'

import NumberFormat from 'react-number-format'

import { useUpgradeStore } from '../../../store'
import { observer } from 'mobx-react'

interface UpgradeOfferPreviewProps {
  id: string,
  showAs: string,
  block: boolean
}

const UpgradeOfferPreview: React.FC<UpgradeOfferPreviewProps & RouteComponentProps>  = (props:any) => {
  
  
  // Nastavení stavů pro zobrazení
  //const [error, setError] = useState(null)
  
  
  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(true);
  

  // Připojení store
  const rootUpgradeStore = useUpgradeStore()

  const upgradeOffer = Object.keys(rootUpgradeStore.upgradeOffersDetail!).length > 0 ? rootUpgradeStore.upgradeOffersDetail[0] : null

  const countDataServices:number = upgradeOffer?.offerServices! !== undefined ? Object.keys(upgradeOffer?.offerServices!).length : 0

  const countDataIndividualItems:number = upgradeOffer?.offerItems! !== undefined ? Object.keys(upgradeOffer?.offerItems!).length : 0
  
  // Funkce pro zobrazení nebo skrytí detailních cen
  const showHide = (id:string) => {
    let el=document.getElementById("detail"+id)?.style!; 
    el.display=(el.display === 'block')?'none':'block';

    let elBS=document.getElementById("buttonShow"+id)?.style!;
    elBS.display = (el.display === 'block')?'none':'block'

    let elBH=document.getElementById("buttonHide"+id)?.style!;
    elBH.display = (el.display === 'block')?'block':'none'
    
  }

  // Funkce pro doplnění delšího názvu u služeb 
  const getServicesIndividualName = (code:string) => {
    let longText:string = '' 
    //if (code === 'VS') longText = ' - roční předplatné'
    return longText
  }
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    
    //setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  // eslint-disable-next-line
  const handleShow = async () => {
    setShow(true)
    setLoaded(false)
    

    await rootUpgradeStore.fetchUpgradeOffersDetail(props.id)

    setLoaded(true)
    
  }

  
  
 
  return (
    <span>
    <>
      {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { handleShow() } }>Detail objednávky</Dropdown.Item>}
      {props.showAs === 'button' &&<Button block={props.block} size="sm" variant="primary" onClick={() => { handleShow() } }>Detail</Button>}
    </>

    <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
        style={{maxWidth: 'none !important'}}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail objednávky</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loaded === false &&<div style={{textAlign: 'center', minHeight: '100px', paddingTop: '30px'}}><Spinner animation="border"  /></div>}
        {loaded === true &&<div >
      <Card
        bg='light'
        text='dark'
        
        id={upgradeOffer?.id}
        className={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'border-left-danger':'border-left-info'}
      >
        <Card.Header as="h3">
          <img className="noprint" alt="logo programu" src={"images/ico-header-"+upgradeOffer?.upgradeScenario.program.code+".png"} style={{marginBottom: '5px', marginRight: '15px' }} />
          {upgradeOffer?.upgradeScenario.program.name} <span>{upgradeOffer?.actualLicense.programVersion.version}&nbsp;&#8594;&nbsp;</span>{upgradeOffer?.upgradeScenario.programVersion.version}<span style={{display: 'none'}}> (pro rok {upgradeOffer?.upgradeScenario.program.code === 'D' ? 2004+upgradeOffer?.upgradeScenario.programVersion.version! : 1991+upgradeOffer?.upgradeScenario.programVersion.version! })</span>
          
          <UpgradeOfferState state={upgradeOffer?.state!} actual={false} distributionMethod={upgradeOffer?.distributionMethod!} />
        </Card.Header>
        <Card.Body>
          <div className="float-left">  
            <h5>Licence</h5> 
            <span className="noprint nodisplay"><br /></span>
            <strong>Typ licence:</strong> {upgradeOffer?.targetLicense.licenseType.code}&nbsp;-&nbsp;{upgradeOffer?.targetLicense.licenseType.name}
            <br /><br />
            <strong>Celkový počet PC:</strong> {upgradeOffer?.targetLicense.count}
            <br /><br />
            <strong>Moduly:</strong>
            
            <Table bordered size="sm" style={{width: '300px'}}>
              <tbody>
              {upgradeOffer?.targetLicense.modules! !== undefined && upgradeOffer?.targetLicense.modules!.slice().sort(function(a:any, b:any) {
                    var nameA = getModuleOrder(a.module.code); 
                    var nameB = getModuleOrder(b.module.code); 
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }).map(modules => (
                  
                    <tr key={modules.module.id}>
                      <td>{modules.module.name}</td>
                      <td style={{textAlign: 'right'}}>{modules.count}&nbsp;PC</td>
                      <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.totalPrice!} />&nbsp;Kč</td>
                    </tr>  
                  ))
              }
              </tbody>
            </Table>
            {upgradeOffer?.upgradeScenario.program.code === 'D' &&<div className="offer-under-modules" style={{fontSize: '90%', marginTop: '-10px', marginBottom: '20px'}}>Na rozšíření v rámci upgrade je sleva 20 %.</div>}
                
            {countDataServices > 0 &&<h5>Služby</h5>}
            {upgradeOffer?.offerServices.map(dialsService => (
              <div key={dialsService.service.id}>
                
                <div>
                  {dialsService.service.name+''+getServicesIndividualName(dialsService.service.code!)}{dialsService.service.code !== 'I' && <span>&nbsp;({getNumberFormated(dialsService.price!)}&nbsp;Kč)</span>}&nbsp;
                  <OverlayTrigger 
                    //trigger="click" 
                    placement="bottom" 
                    overlay={
                      <Popover id="popover-basic">
                        {dialsService.service.code === 'VS' &&<Popover.Title as="h3">Vzdálená správa</Popover.Title>}
                        {dialsService.service.code === 'I' &&<Popover.Title as="h3">Instalace upgrade</Popover.Title>}
                        {dialsService.service.code === 'VS' &&
                        <Popover.Content>
                          Roční předplatné služby, kdy se k vám technici hotline budou moci během telefonátu vzdáleně připojit až na 30 minut denně.
                        </Popover.Content>}
                        {dialsService.service.code === 'I' &&
                        <Popover.Content>
                          Placená služba, kdy vám technik od Ježka program nainstaluje, zaregistruje a převede data jedné firmy. Cena je 600&nbsp;Kč/30&nbsp;minut a fakturována bude samostatně podle skutečné doby.
                        </Popover.Content>}
                      </Popover>
                    }
                  >
                    <Button variant="link" size="sm" style={{padding: '0px', marginTop: '-5px', textDecoration: 'none', fontSize: '120%', color: 'darkgrey'}}><i className="fas fa-question-circle"></i></Button>
                  </OverlayTrigger>
                </div>
                
              </div>

            ))}
  
            <br />

            <div>
              <h5>Způsob distribuce</h5>
              {upgradeOffer?.distributionMethod === 'E' &&<div>
                  Stažením z internetu (zálohová faktura)
                </div>}
                {upgradeOffer?.distributionMethod === 'D' &&<div>
                  Na dobírku
                </div>}
            </div>

            
            <br />
            {countDataIndividualItems > 0 &&<><h5>Individuální položky</h5>
            <Table bordered size="sm" style={{width: '300px'}}>
              <tbody>
              {upgradeOffer?.offerItems.map(offerItem => (
                <tr key={offerItem.id}>
                  <td>{offerItem.name}</td>
                  <td style={{textAlign: 'right'}}>
                    <NumberFormat 
                      displayType={'text'} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      decimalScale={2} 
                      fixedDecimalScale={true} 
                      value={offerItem.price!} 
                    />&nbsp;Kč
                  </td>
                  
                </tr>
              ))}
              </tbody>
            </Table>
           
            <br />
            </>}
            </div>
          <div className="float-right offer-control" style={{fontWeight: 'normal', maxWidth: '230px', fontSize: '90%'}}>
            <h5>Celkem</h5>
            <h3><Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.totalWithoutVat!} />&nbsp;Kč</Badge></h3>  
            <strong>Uvedené ceny jsou bez DPH.</strong>
            <br />
            Při distribuci k nim bude připočtena daň dle aktuální legislativy.
            <br /><br />
            <strong>Orientační výpočet DPH:</strong>
            <br />
            <span style={{fontStyle: 'italic'}}>
            Daň <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.totalVat!} />&nbsp;Kč
            <br />
            Celkem s daní <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.totalWithVat!} />&nbsp;Kč
            <br />
            <br />
            
            
                      

            </span>
            <br />
          </div>  
          
            <div className='cleaner'></div>

            {upgradeOffer?.isUpgradeFree &&<div style={{marginBottom: '20px'}}><i className="fas fa-info-circle noprint"></i>&nbsp;<i>Upgrade zdarma.</i></div> }

            <Button variant='secondary' style={{display: 'block'}} id={"buttonShow"+upgradeOffer?.id} onClick={() => {showHide(upgradeOffer?.id!)}}><i className="fas fa-angle-double-down"></i>&nbsp;Zobrazit detailní rozpis cen modulů</Button>
            <Button variant='secondary' style={{display: 'none'}}  id={"buttonHide"+upgradeOffer?.id} onClick={() => {showHide(upgradeOffer?.id!)}}><i className="fas fa-angle-double-up"></i>&nbsp;Skrýt detailní rozpis cen modulů</Button>
            
            <div className="offer-detail" id={"detail"+upgradeOffer?.id} style={{display: 'none'}}>
              <br className="noprint" />
            <h5>Detailní rozpis cen</h5>
                
                {upgradeOffer?.targetLicense.modules! !== undefined && upgradeOffer?.targetLicense.modules!.slice().sort(function(a:any, b:any) {
                  var nameA = getModuleOrder(a.module.code); 
                  var nameB = getModuleOrder(b.module.code); 
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                }).map(modules => (
                
                  <div key={modules.module.id} style={{marginBottom: '10px'}}>
                  <Card
                    bg='light'
                    text='dark'
                    style={{minWidth: '300px'}}
                  >
                    <Card.Header as="h5">
                      <strong>{modules.module.name}</strong>
                      <div className="float-right" style={{textAlign: 'right', fontWeight: 'normal', minWidth: '150px'}}>
                        <Badge variant="secondary"><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.totalPrice!} />&nbsp;Kč</Badge>
                      </div>
                      <div className="cleaner"></div>
                    </Card.Header>
                    <Card.Body className='card-no-mouse-over-effect'>
                      
                      <div className="float-left">
                        <table>
                          <tbody>
                            <tr>
                              <td></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>Aktuální</Badge></td>
                              <td style={{textAlign:'center', minWidth: '20px'}}></td>
                              <td style={{textAlign:'center', minWidth: '80px'}}><Badge pill variant={upgradeOffer?.upgradeScenario.program.code === 'D' ? 'secondary':'secondary'}>Požadovaný</Badge></td>
                              
                            </tr>
                            <tr>
                              <td>Počet&nbsp;PC</td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} defaultValue={upgradeOffer?.actualLicense.modules?.find(actualModule => actualModule.module.id === modules.module.id)?.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              <td style={{textAlign:'center'}}><i className="fas fa-angle-double-right"></i></td>
                              <td style={{textAlign:'center'}}><input type="text" disabled={true} value={modules.count!} style={{width: '40px', textAlign: 'center', fontSize: '120%', fontWeight: 'bold'}} /></td>
                              
                            </tr>
                          </tbody>
                        </table>
                        
                        {Object.keys(upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items!).length > 0 &&
                        <div>
                        <hr />
                        <h6 style={{fontWeight: 'normal'}}>Detailní rozpis ceny:</h6> 
                        {upgradeOffer?.licensePrice.items?.find(items => items.module.id === modules.module.id)?.items?.slice().sort(function(a:any, b:any) {
                          var nameA = getPriceListDetailOrder(a.priceType, a.isFirst); 
                          var nameB = getPriceListDetailOrder(b.priceType, b.isFirst);
                          
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        }).map((priceDetail, index) => (
                          <div key={index} style={{fontStyle: 'italic'}}>
                            
                            {priceDetail.priceType === 'P' && 'Upgrade'}
                            {priceDetail.priceType === 'F' && 'Upgrade z rozšíření v ochranném období'}
                            {priceDetail.priceType === 'S' && 'Doplatek za přechod na vyšší typ licence'}
                            {priceDetail.priceType === 'E' && 'Rozšíření v rámci upgrade s 20% slevou'}
                            
                            {priceDetail.priceType !== 'F' ? priceDetail.isFirst ? ' 1. PC (':' další PC (':' ('}
                            <strong>{priceDetail.count}x</strong>):&nbsp;
                            <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={priceDetail.price!} />&nbsp;Kč
                          </div>
                        ))}
                        </div>}

                      </div>
                      <div className="cleaner"></div>
                    </Card.Body>
                  </Card>
                  </div>
                
                ))} 
                <br />
          </div>
            
        </Card.Body>
      </Card>
      <br />
    </div>  }

        </Modal.Body>

      </Modal>
      
      
    </span>
  )  
  
}

export default withRouter(observer(UpgradeOfferPreview))
