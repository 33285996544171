import React  from 'react'
import { withRouter } from 'react-router-dom'
//import { getToken, getModuleOrder } from '../../../../Utils/Common'

//import ErrorAlert from '../../../../parts/errorAlert/ErrorAlert.part'

import {Card, CardDeck} from 'react-bootstrap'

//import * as Constants from '../../../../Constants/Constants'
//import axios from 'axios';

//import { confirm } from "../../../../Utils/Confirmation"

//import { useStore } from '../../../../store'
import { useUpgradeStore } from '../../../../store'
import { observer } from 'mobx-react'

import UpgradeOfferState  from '../../components/Upgrade.Offer.StateToDiv.part'
import UpgradeOfferPreview  from '../../components/Upgrade.Offer.Preview.part'
import CustomersOtherActionsHistory from '../../../../parts/customersOtherActions/Customers.OtherActions.History.part'


import NumberFormat from 'react-number-format'

const UpgradeOfferListHistory: React.FC = (props:any) => {
  // Nastavení práv pro editaci
  //const rightsBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1')) ? true : false
  const rightsSHBH:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) ? true : false
  // Nastavení stavů pro zobrazení
  // eslint-disable-next-line


  

  // Načtení Store
  const rootUpgradeStore = useUpgradeStore()
  
  //Počet záznamů
  const countData:number = Object.keys(rootUpgradeStore.upgradeOffersList.filter(uol => uol.upgradeScenario.isActive === false)).length

  
 
  const upgradeOffersList = rootUpgradeStore.upgradeOffersList.filter(uol => uol.upgradeScenario.isActive === false).slice().sort(function(a:any, b:any) {
     
    var sort1A = a.upgradeScenario.programVersion.version; 
    var sort1B = b.upgradeScenario.programVersion.version; 

    var sort2B = a.upgradeScenario.program.code 
    var sort2A = b.upgradeScenario.program.code

    if (sort2A! < sort2B!) {
      return -1;
    }
    if (sort2A! > sort2B!) {
      return 1;
    }
    if (sort1A! < sort1B!) {
      return 1;
    }
    if (sort1A! > sort1B!) {
      return -1;
    }


    return 0;
  }).map(uol => (
    <div key={uol.id}>
    <CardDeck>
      <Card
        bg='light'
        text='dark'
        className={uol.upgradeScenario.program.code === 'D' ? 'border-left-danger':'border-left-info'}
      >
      <div >
        <Card.Header as="h3">
          {uol.upgradeScenario.program.name} {uol.upgradeScenario.programVersion.version}
          <UpgradeOfferState state={uol.state!} actual={false} distributionMethod={uol.distributionMethod!}/>
        
        </Card.Header>
        <Card.Body >
          <div className="float-left">
          Celkem bez DPH: <NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={uol.totalWithoutVat} />&nbsp;Kč
          </div>
          <div className="float-right" style={{fontWeight: 'normal', maxWidth: '230px', fontSize: '90%'}}>
          <UpgradeOfferPreview id={uol.id} showAs='button' block={true} />  
          {rightsSHBH &&<div  style={{marginBottom: '10px'}}></div>}     
          {rightsSHBH && <CustomersOtherActionsHistory label='' block={true} title={uol?.upgradeScenario.program.name +" "+uol?.upgradeScenario.programVersion.version} showAs='button' historyType='offerByScenario' historyParam={uol.upgradeScenario.id!} />}   
          {rightsSHBH &&<div  style={{marginBottom: '10px'}}></div>}   
          </div>   
          <div className="cleaner"></div>
        </Card.Body>
      </div>
      </Card>

    </CardDeck>
    <br />
    </div>
  ))


  return (
    <div>
      <br />
      <h4>Archiv objednávek</h4>
      <br />
      {countData === 0 ? <div>Seznam historických objednávek neobsahuje žádné záznamy.<br /><br /></div> : ''}
      



        {upgradeOffersList}
        

      


    </div>

  )  
  
}

export default withRouter(observer(UpgradeOfferListHistory))
