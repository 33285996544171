import { types } from 'mobx-state-tree'

//Customer
const Customer = types.model('Customer',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  companyRegistrationNumber: types.optional(types.string, ''),
  taxIdentificationNumber: types.optional(types.string, ''),
  isTaxAble: types.optional(types.boolean, false),
  isActive: types.optional(types.boolean, false)
})

//Scénář
const SProgram = types.model('SProgram',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const SProgramVersion = types.model('SProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const UpgradeScenario = types.model('UpgradeScenario',{
  id: types.optional(types.string, ''),
  program: types.frozen(SProgram),
  programVersion: types.frozen(SProgramVersion),
  })


export const DatafiltersOffers = types
  .model({
    id: types.optional(types.string, ''),
    customer: types.frozen(Customer),
    upgradeScenario: types.frozen(UpgradeScenario),
    state: types.maybeNull(types.string),
    distributionMethod: types.maybeNull(types.string),
    totalWithVat: types.maybeNull(types.number),
    totalVat: types.maybeNull(types.number),
    totalWithoutVat: types.maybeNull(types.number),
    checked: types.optional(types.boolean, false),
  })
  .views(self => ({
    get infoText() {
      return self.customer.accountNumber + ' - ' + self.customer.businessName
      
    }, 
  }))
  .actions(self => ({
    selectItem(newState: boolean) {
      self.checked = newState
    }

  }))
