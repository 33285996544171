import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getUser, getToken, getUrl } from '../../../Utils/Common'

import { Button, ButtonGroup, Col, Alert, Modal, Row, Badge, Spinner, Dropdown, DropdownButton } from 'react-bootstrap'

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import TasksUpdate from './Tasks.Update.part'
//import SupportContactsSolveUpdate from './Support.Contacts.SolveUpdate.part'
import TasksPointAddUpdate from './Tasks.PointAddUpdate.part'
import TasksSolutionAddUpdate from './Tasks.SolutionAddUpdate.part'

import moment from 'moment'
import FilesList from '../../../parts/files/Files.List.part'
import FilesAdd from '../../../parts/files/Files.Add.part'
import { sleep } from '../../../helpers/sleep'
//import FilesAdd from '../../../parts/files/Files.Add.part'

import { confirm } from "../../../Utils/Confirmation";
import axios from 'axios';

interface TasksDetailProps { 
  taskId?:string
  editing?:boolean,
  fromList?:boolean,
  fromSubMenu?:boolean,
  setPageLoading?:any    
}

const TasksDetail: React.FC<TasksDetailProps & RouteComponentProps> = (props:any) => {
  
  // Nastavení stavů pro zobrazení  
  const [loading, setLoading] = useState(false)
  const [showDetail, setShowDetail] = useState(false)

  // Připojení store
  const rootStore = useStore()
  
  // Načtení zobrazovaného úkolu
  const item = rootStore.tasks.find(s => s.id === props.taskId)
  
  // Nastavení práv pro editaci
  const rightsAuthor:boolean = (item?.authorId === getUser().id!) ? true : false
  const rightsResponsible:boolean = (item?.responsibleUserId === getUser().id!) ? true : false
  
  const rightsEdit:boolean = (rightsAuthor === true || rightsResponsible === true) ? true : false

  // Otevírání a zavírání zobrazení detailu
  const handleCloseMain = () => {
    setShowDetail(false)
    
    if (sessionStorage.getItem('tasksDetailEdited') === 'Y') {
      rootStore.fetchTasks(Number(sessionStorage.getItem('tasksSearchPage') !== 'null' ? sessionStorage.getItem('tasksSearchPage') : 0 ) === 0 ? 1 : Number(sessionStorage.getItem('tasksSearchPage')))
      sessionStorage.setItem('tasksDetailEdited', 'N')
       
    }
    sessionStorage.removeItem('autoCloseModal')
  }

// Startovací sequence při otevření datailu úkolu
const startSequence = async () => {
  setLoading(true)
  sessionStorage.setItem('tasksDetailEdited', 'N')
  await rootStore.fetchTaskPoints(props.taskId)
  await rootStore.fetchTaskSolutions(props.taskId)
  
  setShowDetail(true)

  await setLoading(false)
  await sleep(500)
  await rootStore.fetchFilesForTask(props.taskId)
}

// Funkce pro tisk úkolu
function PrintElem(elem:string)
{
  let mywindow = window.open('', 'PRINT', 'height=700,width=1024');

  mywindow!.document.write('<html><head><title>' + document.title  + '</title>');

  mywindow!.document.write('<style type="text/css">body {font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";} button {display:none !important;} h5 {font-size: 1.25rem;} h4 {font-size: 1.5rem; margin-top: 5px; margin-bottom: 5px;} h3 {font-size: 1.75rem;} .offer-under-modules{margin-top: 10px !important;} .float-right{float: right;} .float-left{float: left;} .cleaner{clear: both;} .noprint {display: none !important;}</style>');
  mywindow!.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">');

  mywindow!.document.write('<link href="/dist/css/styles.css" rel="stylesheet">');

  mywindow!.document.write('</head><body >');
  mywindow!.document.write('<div class="float-right" style="font-weight: bold; font-size: 1.0rem">Termín: '+ moment(item?.deadLine).format("DD.MM.YYYY") +'</div>');
  mywindow!.document.write('<h5>'+item?.text+'</h5>');
  mywindow!.document.write('<hr />');
  
  mywindow!.document.write('');
  mywindow!.document.write(document.getElementById(elem)!.innerHTML);
  mywindow!.document.write('</body></html>');

  mywindow!.document.close(); // necessary for IE >= 10
  mywindow!.focus(); // necessary for IE >= 10*/

  //mywindow!.print();
  //mywindow!.close();

  return true;
}


const handleOnClickUpdateDone = (state:boolean, task:string ) => {
  (async () => {
    let text1:string = state === true ? 'nastavit jako splněný':'nastavit jako nesplněný'
    let text2:string = state === true ? 'Ano, splněný':'Ano, nesplněný'

    //text1 = text1 + ' ' + getRoleName(role)


    if (await confirm(<>{Object.keys(item?.points?.filter(p => p.isDone === false)!).length > 0 &&<span style={{color: 'red', fontWeight: 'bold'}}>Pozor, úkol obsahuje nesplněné body: {Object.keys(item?.points?.filter(p => p.isDone === false)!).length}<br /></span>}Opravdu chcete úkol <strong>{task}</strong> {text1}?</>,text2, 'Ne', {title: 'Změna nastavení údaje splněno'})) {
      updateDone(state)
    } else {
    
    }  
  })()
}

// Funkce pro smazání úkolu
const updateDone = (state:boolean) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API

    await axios.post(getUrl('URL_TASKS')+'/'+item?.id, 
      { id: item?.id, 
        //deadline: item?.deadLine, 
        text: item?.text, 
        //responsibleUserId: item?.responsibleUserId, 
        //isPrivate: item?.isPrivate, 
        isDone: state, 
        //isCompleted: item?.isCompleted 
      }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam úkolů
          item?.setDone(state)
          //rootStore.fetchMeetingPoints(props.meetingId)
          //rootStore.fetchTasksForMeeting(props.meetingId)
        }).catch(error => {
          //const errorMessage:any = (<ErrorAlert errorData={error} />)
          //setError(errorMessage)
          //setShowE(true)          
        });
    })()    
}

const handleOnClickUpdateCompleted = (state:boolean, task:string ) => {
  (async () => {
    let text1:string = state === true ? 'nastavit jako uzavřený':'nastavit jako otevřený'
    let text2:string = state === true ? 'Ano, uzavřít':'Ano, otevřít'

    //text1 = text1 + ' ' + getRoleName(role)


    if (await confirm(<>{Object.keys(item?.points?.filter(p => p.isDone === false)!).length > 0 &&<span style={{color: 'red', fontWeight: 'bold'}}>Pozor, úkol obsahuje nesplněné body: {Object.keys(item?.points?.filter(p => p.isDone === false)!).length}<br /></span>}Opravdu chcete úkol <strong>{task}</strong> {text1}?</>,text2, 'Ne', {title: 'Změna nastavení údaje uzavřeno'})) {
      updateCompleted(state)
    } else {
    
    }  
  })()
}

// Funkce pro smazání úkolu
const updateCompleted = (state:boolean) => {
  (async () => {
    // Kontrola přihlášení  
    const aToken = await getToken()
    if (aToken === null) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }      
    // Zavolání API

    await axios.post(getUrl('URL_TASKS')+'/'+item?.id, { id: item?.id, deadline: item?.deadLine, text: item?.text, responsibleUserId: item?.responsibleUserId, isPrivate: item?.isPrivate, isDone: item?.isDone, isCompleted: state }, { headers: { 'Authorization': 'Bearer ' + aToken}}
        ).then(response => {
          // Po smazání přenačíst seznam úkolů
          item?.setCompleted(state)
          //rootStore.fetchMeetingPoints(props.meetingId)
          //rootStore.fetchTasksForMeeting(props.meetingId)
        }).catch(error => {
          //const errorMessage:any = (<ErrorAlert errorData={error} />)
          //setError(errorMessage)
          //setShowE(true)          
        });
    })()    
}


// VYKRESLENÍ DETAILU ÚKOLU 
const contactForm = 
      <>

          {showDetail &&<>
          <div id='taskDetail'>

            <Row>
              <Col lg={{span: 8, order :1}}>
                <h4 className='noprint'>Úkol</h4>
                <Alert variant='secondary'>

                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Termín splnění:</strong></Col>
                  <Col>{moment(item?.deadLine).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Autor:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === item?.authorId)?.fullName}</Col>
                </Row>
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Odpovídá:</strong></Col>
                  <Col>{rootStore.hedgehogUsers.find(hu => hu.id === item?.responsibleUserId)?.fullName}</Col>
                </Row>  
                {item?.isPrivate === true && 
                <Row>  
                  <Col sm={4} md={3} lg={3}><strong>Soukromý:</strong></Col>
                  <Col>Ano</Col>
                </Row>}

                {item?.isDone === true && 
                <Row>  
                  <Col sm={4} md={3} lg={3}><strong>Splněno:</strong></Col>
                  <Col>{moment(item?.doneDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>}

                {item?.isCompleted === true && 
                <Row>  
                  <Col sm={4} md={3} lg={3}><strong>Uzavřeno:</strong></Col>
                  <Col>{moment(item?.completedDate).format("DD.MM.YYYY HH:mm")}</Col>
                </Row>}
                {Object.keys(item?.points?.filter(p => p.isDone === false)!).length > 0 && 
                <Row>  
                  <Col sm={4} md={3} lg={3}><strong>Nesplněné body:</strong></Col>
                  <Col>{Object.keys(item?.points?.filter(p => p.isDone === false)!).length}</Col>
                </Row>}
                <br />
                <Row>
                  <Col sm={4} md={3} lg={3}><strong>Úkol:</strong></Col>
                  <Col>{item?.text}</Col>
                </Row>
                

                <br />
                <div className='noprint'>
                <FilesList id={item?.id!} type='task' masterId={item?.id!} />
                </div>
                
                {(rightsEdit === true) &&
                <ButtonGroup style={{}}>
                  <TasksUpdate id={item?.id!} showAs='button' />
                  <DropdownButton as={ButtonGroup} id="dropdown-basic-button" variant="secondary" title="••• " size="sm" style={{position: 'static'}} data-boundary="viewport">  
                    <FilesAdd id={props.taskId} type='task' masterId={props.taskId} showAs='drop' />
                  </DropdownButton>
                </ButtonGroup>
                }

                </Alert>
              </Col>
              <Col lg={{span: 4, order :2}}>
              <h4 className='noprint'>Akce</h4>
                <Alert variant='secondary' className='noprint'>
                <Button block variant="secondary" size="sm" onClick={() => {PrintElem('taskDetail')}}><i className="fas fa-print"></i>&nbsp;Vytisknout úkol</Button>
                <hr />
                <Button 
                  onClick={() => handleOnClickUpdateDone(!item?.isDone, item?.text!)}
                  //disabled={item?.isDisabled === true ? true : false}
                  block 
                  style={{marginBottom: '5px'}} 
                  size='sm' 
                  variant={item?.isDone ? 'outline-secondary' : 'warning'}
                >
                  {item?.isDone ? 'Nastavit úkol jako nesplněný' : 'Nastavit úkol jako splněný'}
                </Button>

                {rightsAuthor === true &&
                  <Button 
                    onClick={() => handleOnClickUpdateCompleted(!item?.isCompleted, item?.text!)}
                    //disabled={item?.isDisabled === true ? true : false}
                    block 
                    style={{marginBottom: '5px'}} 
                    size='sm' 
                    variant={item?.isCompleted ? 'outline-secondary' : 'warning'}
                  >
                    {item?.isCompleted ? 'Nastavit úkol jako otevřený' : 'Nastavit úkol jako uzavřený'}
                  </Button>
                }
                </Alert>
              </Col>
              
            </Row>
              
                <>
                  <div style={{float: 'right'}}></div> 
                  <h4>Řešení úkolu</h4>
                  
                  {(rightsEdit === true) && <TasksSolutionAddUpdate taskId={item?.id} />} 
                  
                  <br className='noprint' /><br className='noprint' />
                  {loading === true 
                    ? 
                    <><Spinner animation='border'  />&nbsp;</>
                    : 
                    <>
                      <>
                        {Object.keys(item?.solutions!).length === 0 &&<>Seznam řešení úkolu neobsahuje žádné záznamy.</>}
                      </>

                      {item?.solutions.slice()
                      .sort((a, b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : -1)
                      .map(ss => (
                
                        <Alert variant='secondary' key={ss.id}>
                        {ss.authorId !== null &&
                        <Row>
                          <Col md={3} lg={2}><strong>Autor řešení:</strong></Col>
                          <Col>{rootStore.hedgehogUsers.find(hu => hu.id === ss.authorId)?.fullName}</Col>
                        </Row>  }

                        

                        {(ss.authorId !== null) && <hr />}
                        
                        <>
                          
                          <div className="content" dangerouslySetInnerHTML={{__html: ss?.text!}}></div>
                          <br />
                        </>  
                        <div className='noprint'>
                        <FilesList id={ss.id} type='taskSolution' masterId={item?.id} />
                        </div>



                          {rightsEdit === true && <TasksSolutionAddUpdate editing={true} taskId={item?.id} solutionId={ss.id} />}
                        </Alert>
                
                      ))}
              
                    </>
                  }
                </>
                
                <br /><br />
                
                <>
                  <div style={{float: 'right'}}></div> 
                  <h4>Body úkolu</h4>
                  
                  {rightsEdit === true && <TasksPointAddUpdate taskId={item?.id} masterRights={rightsEdit} />} 
                  
                  <br className='noprint' /><br className='noprint' />
                  {loading === true 
                    ? 
                    <><Spinner animation='border'  />&nbsp;</>
                    : 
                    <>
                      <>
                        {Object.keys(item?.points!).length === 0 &&<>Seznam bodů úkolu neobsahuje žádné záznamy.</>}
                      </>

                      {item?.points.slice()
                      .sort((a, b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : -1)
                      .map(ss => (
                
                        <Alert variant='info' key={ss.id}>
                        {ss.responsibleUserId !== null &&
                        <Row>
                          <Col md={3} lg={2}><strong>Odpovídá:</strong></Col>
                          <Col>{rootStore.hedgehogUsers.find(hu => hu.id === ss.responsibleUserId)?.fullName}</Col>
                        </Row>  }

                        {ss?.isDone === true && 
                        <Row>  
                          <Col md={3} lg={2}><strong>Splněno:</strong></Col>
                          <Col>{moment(ss.doneDate).format("DD.MM.YYYY HH:mm")}</Col>
                        </Row>}

                        {(ss.responsibleUserId !== null || ss.isDone === true) && <hr />}
                        
                        <>
                          
                          <div className="content" dangerouslySetInnerHTML={{__html: ss?.text!}}></div>
                          <br />
                        </>  
                        <div className='noprint'>
                        <FilesList id={ss.id} type='task' masterId={item?.id} />
                        </div>



                          {(rightsEdit === true || ss.responsibleUserId === getUser().id)  && <TasksPointAddUpdate editing={true} taskId={item?.id} pointId={ss.id} masterRights={rightsEdit} />}

                          {Object.keys(ss.solutions!).length > 0 && <h5 style={{marginTop: '10px'}}>Řešení bodu úkolu</h5>}

                          {ss.solutions.slice()
                          .sort((a, b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : -1)
                          .map(psol => (
                
                            <Alert variant='secondary' key={psol.id}>
                            {psol.authorId !== null &&
                            <Row>
                              <Col md={3} lg={2}><strong>Autor řešení:</strong></Col>
                              <Col>{rootStore.hedgehogUsers.find(hu => hu.id === psol.authorId)?.fullName}</Col>
                            </Row>  }

                            

                            {(psol.authorId !== null) && <hr />}
                            
                            <>
                              
                              <div className="content" dangerouslySetInnerHTML={{__html: psol?.text!}}></div>
                              <br />
                            </>  
                            <div className='noprint'>
                            <FilesList id={psol.id} type='taskSolution' masterId={item?.id} />
                            </div>



                              {(rightsEdit === true || ss.responsibleUserId === getUser().id)  && <TasksSolutionAddUpdate editing={true} taskId={item?.id} solutionId={psol.id} linkId={ss.id} pointSolution={true} />}
                            </Alert>
                          ))}
                        </Alert>
                
                      ))}
              
                    </>
                  }
                </>
              
          
          </div>
          </>}

          </>



return ( 
  <>
  {props.fromList === true 
    ? 
    props.fromSubMenu === true
      ?
      <>
        <Dropdown.Item onClick={() => {startSequence()}}>Detail úkolu</Dropdown.Item>
      </>
      :
      <>
        <span className='as-link-no-color' title='Detail úkolu' onClick={() => {startSequence()}}>{item?.text}</span>
      </>
    :
    <>
      <Button variant='outline-primary' size="sm" style={{}} onClick={() => {startSequence()}} ><span style={{display: 'none'}} title='Detail úkolu' ><i className="fas fa-search"></i>&nbsp;</span>Detail</Button>
    </>
    }
  
    <Modal 
      show={showDetail} 
      onHide={handleCloseMain} 
      dialogClassName="modal-90wf"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
      style={{backgroundColor: 'rgba(227, 227, 227, 0.6)'}}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
        <div className="showMobile">
          Detail úkolu <br />
          {item?.text}
          {item?.isPrivate === true &&<><br /><Badge variant='warning' style={{marginLeft: '10px'}}>Soukromý</Badge></>}  
        </div>
        <div className="showWeb">
          Detail úkolu <Badge variant='secondary'>{item?.text}</Badge>
          {item?.isPrivate === true &&<Badge variant='warning' style={{marginLeft: '10px'}}>Soukromý</Badge>}  
        </div>  
        
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>  
      {contactForm}

      </Modal.Body>
    </Modal>
    
  </>
)

}

export default withRouter(observer(TasksDetail))
