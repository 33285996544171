import {errorNames} from '../Constants/errorNames'
import {translateConstants} from '../Constants/translate'

export const getErrorByCode = (errorCode: number) => {
  return errorNames.find((errorName: { code: number }) => errorName.code === errorCode)
}

export const getConstantTranslate = (original: string, type: string) => {
  if (translateConstants.find((translateConstants: { original: string, target: string, targetText: string }) => translateConstants.original === original) === undefined) { return original}
  else 
  {
    if (type === 'value') return translateConstants.find((translateConstants: { original: string, target: string, targetText: string }) => translateConstants.original === original)?.target
    if (type === 'text') return translateConstants.find((translateConstants: { original: string, target: string, targetText: string }) => translateConstants.original === original)?.targetText
    //return translateConstants.find((translateConstants: { original: string, target: string }) => translateConstants.original === original)?.target
  }  
}