import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken, getUrl } from '../../../Utils/Common'

//import ErrorAlert from '../errorAlert/ErrorAlert.part'

import { Button,  Alert, Modal, Dropdown, ButtonGroup } from 'react-bootstrap'

//import * as Constants from '../../Constants/Constants'
import axios from 'axios';

import { observer } from 'mobx-react'
//import { useStore } from '../../store'

interface InvoicesOpenDocumentProps {
  id: string,
  docName: string,
  //filterId:number
  showAs: string,
  invoiceId: string,
  info?: string
}

const InvoicesOpenDocument: React.FC<InvoicesOpenDocumentProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci
  const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1')  ) ? true : false

  // Nastavení stavů pro zobrazení

  const [error, setError] = useState(null)

  const [showEO, setShowEO] = useState(false)
 
  // Připojení store
  //const rootStore = useStore()
  

  const openDocument = (pdfId:string, docName:string, action:string) => {
    (async () => {
      // Kontrola přihlášení  
      const aToken = await getToken()
      if (aToken === null) {   
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }      

      var ua = window.navigator.userAgent;
      var iOS:boolean = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      var webkit:boolean = !!ua.match(/WebKit/i);
      var iOSSafari:boolean = iOS && webkit && !ua.match(/CriOS/i);


      // Zavolání API
      await axios.get(getUrl('URL_INVOICES')+'/'+props.invoiceId+'/pdf/'+pdfId,{
        headers: {
          'Authorization': 'Bearer ' + aToken,
        },
        responseType: 'blob',
      })
      .then(response => {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        //const downloadLink = document.createElement("a")
        
        //var fileURL = URL.createObjectURL(blob);
        
        var fileURL = URL.createObjectURL(blob);
      
        if (iOSSafari || action === 'download') { 
          //alert('aaa')
          const templink = document.createElement("a");
          templink.href = fileURL;
          //templink.setAttribute('href', fileURL);
          templink.setAttribute('download', docName+'.pdf');
          //templink.setAttribute('target', '_blank');
          templink.click();
          //window.open(fileURL) 
        } else { 
          window.open(fileURL, "_blank") 
        }
          
        
        
/*        
        window.open(fileURL, "_blank");

        let tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', docName+'.pdf');
        tempLink.click();

*/
      }).catch(error => {
        //console.log(error)
  
        const errorMessage:any = (
          <Alert variant="danger">
              
                  
                Dokument, který se pokoušíte otevřít  není v tuto chvíli dostupný. Zkuste to prosím později nebo kontektujte naše obchodní oddělení.
              
          </Alert> )
        
        //console.log(errorMessage)
        if (error.response.status === 400) {
        setError(errorMessage)
        setShowEO(true)
        }
      });
   
      })()    
  }




  const handleClose = () => { 
    //setShowE(false)
    setShowEO(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }  
   
  return (
    <span>
      {rights &&<>
          {props.showAs === 'drop' &&<Dropdown.Item onClick={() => { openDocument(props.id, props.docName, 'show' ) } }>Otevřít {props.info}</Dropdown.Item>}
          {props.showAs === 'button' && <ButtonGroup>
                                          <Button size="sm" variant="outline-primary" onClick={() => { openDocument(props.id, props.docName, 'show') } }>Zobrazit</Button>
                                          <Button size="sm" variant="outline-primary" onClick={() => { openDocument(props.id, props.docName, 'download') } }>Stáhnout</Button>
                                        </ButtonGroup>}
      </>}

      <Modal 
        show={showEO} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Zobrazení dokumentu PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>
      
    </span>
  )  
  
}

export default withRouter(observer(InvoicesOpenDocument))
