import { RootStore, RootStoreType } from './root.store'
import { RootDialsStore, RootDialsStoreType } from './rootDials.store'
import { RootUpgradeStore, RootUpgradeStoreType } from './rootUpgrade.store'
import { RootCalculatorsStore, RootCalculatorsStoreType } from './rootCalculators.store'
import { RootEmailingStore, RootEmailingStoreType } from './rootEmailing.store'
import { RootInvoicesStore, RootInvoicesStoreType } from './rootInvoices.store'
import { RootAppStore, RootAppStoreType } from './rootApp.store'
import React, { useContext } from 'react'

export const setupRootStore = () => {
  const rootStore = RootStore.create({
    customerList: []
  })
  //connectReduxDevtools(require('remotedev'), rootStore)
  return { rootStore }
}

export const setupRootDialsStore = () => {
  const rootDialsStore = RootDialsStore.create({
    dialsCategories: []
  })
  return { rootDialsStore }
}

export const setupRootUpgradeStore = () => {
  const rootUpgradeStore = RootUpgradeStore.create({
    upgradeOffers: []
  })
  return { rootUpgradeStore }
}

export const setupRootCalculatorsStore = () => {
  const rootCalculatorsStore = RootCalculatorsStore.create({
    calculatorsUpgradeScenarios: []
  })
  return { rootCalculatorsStore }
}

export const setupRootEmailingStore = () => {
  const rootEmailingStore = RootEmailingStore.create({
    emailingWhiteList: []
  })
  return { rootEmailingStore }
}

export const setupRootInvoicesStore = () => {
  const rootInvoicesStore = RootInvoicesStore.create({
    invoices: []
  })
  return { rootInvoicesStore }
}

export const setupRootAppStore = () => {
  const rootAppStore = RootAppStore.create({
    appProduelCustomers: []
  })
  return { rootAppStore }
}

const StoreProvider = setupRootStore()
const StoreDialsProvider = setupRootDialsStore()
const StoreUpgradeProvider = setupRootUpgradeStore()
const StoreCalculatorsProvider = setupRootCalculatorsStore()
const StoreEmailingProvider = setupRootEmailingStore()
const StoreInvoicesProvider = setupRootInvoicesStore()
const StoreAppProvider = setupRootAppStore()

export const useStore = (): RootStoreType =>
  useContext(React.createContext(StoreProvider)).rootStore

export const useDialsStore = (): RootDialsStoreType =>
  useContext(React.createContext(StoreDialsProvider)).rootDialsStore  

export const useUpgradeStore = (): RootUpgradeStoreType =>
  useContext(React.createContext(StoreUpgradeProvider)).rootUpgradeStore   
   
export const useCalculatorsStore = (): RootCalculatorsStoreType =>
  useContext(React.createContext(StoreCalculatorsProvider)).rootCalculatorsStore   

export const useEmailingStore = (): RootEmailingStoreType =>
  useContext(React.createContext(StoreEmailingProvider)).rootEmailingStore  

export const useInvoicesStore = (): RootInvoicesStoreType =>
  useContext(React.createContext(StoreInvoicesProvider)).rootInvoicesStore  

export const useAppStore = (): RootAppStoreType =>
  useContext(React.createContext(StoreAppProvider)).rootAppStore  