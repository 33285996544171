export const translateConstants = [
  {
    'original': 'MH',
    'target': 'Matematicky na haléře',
    'targetText': 'DPH je zaokrouhleno matematicky na haléře.'

  },
  {
    'original': 'upgrade',
    'target': 'Upgrade',
    'targetText': 'Upgrade.'

  },
  {
    'original': 'Bank transfer',
    'target': 'Bankovním převodem',
    'targetText': 'Bankovním převodem.'

  },
  {
    'original': 'By card',
    'target': 'Platební kartou',
    'targetText': 'Platební kartou.'

  },
  {
    'original': 'By card or cash',
    'target': 'Platební kartou nebo hotově',
    'targetText': 'Platební kartou nebo hotově.'

  },
  {
    'original': 'Cash',
    'target': 'Hotově',
    'targetText': 'Hotově.'

  },
  {
    'original': 'Cash on delivery',
    'target': 'Dobírka',
    'targetText': 'Dobírka.'

  },
  {
    'original': 'Online banking',
    'target': 'Online bankovnictví',
    'targetText': 'Online bankovnictví.'

  },
  {
    'original': 'Pay by invoice',
    'target': 'Platba na fakturu',
    'targetText': 'Platba na fakturu.'

  }
  
]