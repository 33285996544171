import { types } from 'mobx-state-tree'


//Odběratel
const Subscriber = types.model('Subscriber',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  
  companyRegistrationNumber: types.maybeNull(types.string),
  taxIdentificationNumber: types.maybeNull(types.string),

  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),

  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  
})

//Odběratel
const Recipient = types.model('Recipient',{
  id: types.optional(types.string, ''),
  accountNumber: types.maybeNull(types.string),
  businessName: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  
  companyRegistrationNumber: types.maybeNull(types.string),
  taxIdentificationNumber: types.maybeNull(types.string),

  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),

  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  
})

//Dodavatel
const Supplier = types.model('Supplier',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  
  companyRegistrationNumber: types.maybeNull(types.string),
  taxIdentificationNumber: types.maybeNull(types.string),

  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  bankAccount: types.maybeNull(types.string),
  iban: types.maybeNull(types.string),
  swift: types.maybeNull(types.string),
  fileReference: types.maybeNull(types.string),
  
})

//Položky
const Items = types.model('Items',{
  id: types.optional(types.string, ''),
  invoiceId: types.optional(types.string, ''),
  name: types.maybeNull(types.string),

  count: types.optional(types.number, 0),
  amount: types.optional(types.number, 0),

  vatType: types.optional(types.string, ''),
  vatRate: types.optional(types.number, 0),

  unit: types.optional(types.string, ''),
  unitPrice: types.optional(types.number, 0),
  accountingType: types.optional(types.string, ''),
  source: types.optional(types.string, ''),
  extended: types.maybeNull(types.string),
  data: types.maybeNull(types.string),
  rank: types.optional(types.number, 0),
  
})

//Tabulka DPH
const VatTables = types.model('VatTables',{
  id: types.optional(types.string, ''),
  invoiceId: types.optional(types.string, ''),
  vatType: types.optional(types.string, ''),
  vatRate: types.optional(types.number, 0),
  withoutVat: types.optional(types.number, 0),
  vat: types.optional(types.number, 0),
  withVat: types.optional(types.number, 0),

})

//Texty
const Texts = types.model('Texts',{
  id: types.optional(types.string, ''),
  invoiceId: types.optional(types.string, ''),
  text: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  documentType: types.maybeNull(types.string),
  createDate: types.optional(types.string, ''),
  source: types.maybeNull(types.string),

})

//Emaily
const Emails = types.model('Emails',{
  id: types.optional(types.string, ''),
  email: types.maybeNull(types.string),
})

//Dokumenty
const Documents = types.model('Documents',{
  id: types.optional(types.string, ''),
  container: types.optional(types.string, ''),
  dateOfCreating: types.optional(types.string, ''),
  dateOfPublishing: types.optional(types.string, ''),
  isPublished: types.optional(types.boolean, false),
  name: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  invoiceId: types.optional(types.string, ''),
})
.views(self => ({
  get typeTextToFile() {
    //const parentInvoice = getParent<typeof Invoices>(self).invoiceType;
    
    
    let text: string = ''
    if (self.type === 'Z') text = 'zalohova-faktura'
    if (self.type === 'P') text = 'danovy-doklad-na-prijatou-platbu'
    if (self.type === 'L') text = 'licencni-listek'
    if (self.type === 'K') text = 'faktura-danovy-doklad'
    

    //let invoiceNumber = getParent<typeof Invoices>(self).invoiceNumber
    //let invoiceNumber = getParent<typeof Invoices>(self).invoiceNumber
    //text = text + '-' + String(invoiceNumber) + '.pdf'
    //text = text + '-' + String(parentInvoice) + String(invoiceNumber) + '.pdf'
    return text 
  }
}))

export const Invoices = types
  .model({
    id: types.optional(types.string, ''),
    invoiceNumber: types.optional(types.number, 0),
    invoiceType: types.optional(types.string, ''),
    dateOfIssue: types.optional(types.string, ''),
    variableSymbol: types.optional(types.string, ''),
    specificSymbol: types.optional(types.string, ''),

    customerId: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.optional(types.string, ''),

    supplierId: types.maybeNull(types.string),
    subscriberId: types.maybeNull(types.string),
    recipientId: types.maybeNull(types.string),

    dueDate: types.maybeNull(types.string),
    dateOfPayment: types.maybeNull(types.string),
    dateOfTaxableSupply: types.maybeNull(types.string),

    total: types.optional(types.number, 0),
    totalWithoutVat:  types.optional(types.number, 0),

    vatRoutingType: types.maybeNull(types.string),
    isRoundingTotal: types.optional(types.boolean, false),
    totalVat: types.optional(types.number, 0),
    totalWithVat: types.optional(types.number, 0),

    isTransferredToAccounting: types.optional(types.boolean, false),
    paymentTerms: types.optional(types.string, ''),

    isClosed: types.optional(types.boolean, false),
    note: types.maybeNull(types.string),

    extended: types.maybeNull(types.string),
    data: types.maybeNull(types.string),

    userId: types.optional(types.string, ''),
    editingLevel: types.optional(types.number, 0),
    
    checked: types.optional(types.boolean, false),

    supplier: types.maybeNull(types.frozen(Supplier)),
    subscriber: types.maybeNull(types.frozen(Subscriber)),
    recipient: types.maybeNull(types.frozen(Recipient)),
    items: types.optional(types.array(Items),[]),
    vatTables: types.optional(types.array(VatTables),[]),
    texts: types.optional(types.array(Texts),[]),
    emails: types.optional(types.array(Emails),[]),
    documents: types.optional(types.array(Documents),[]),

  })
  .views(self => ({
    get infoText() {
      return self.accountNumber + ' - ' + self.businessName
      
    }, 
    get invoiceTypeText() {
      let text: string = ''
      if (self.invoiceType === 'T') text = 'Daňový doklad'
      if (self.invoiceType === 'P') text = 'Proforma'
      return text
    }, 
    get isPaid() {
      if (self.dateOfPayment !== null) return 1
      else {
        if (self.dateOfPayment === null && new Date(self.dueDate!) < new Date()) return 2
        else return 0
      }
    },
    get textsAbove() {
      return self.texts.filter(item => item.type === 'above').sort((a, b) => a.createDate > b.createDate ? 1 : -1)
    },
    get textsUnder() {
      return self.texts.filter(item => item.type === 'under').sort((a, b) => a.createDate > b.createDate ? 1 : -1)
    }
  }))
  .actions(self => ({
    selectItem(newState: boolean) {
      self.checked = newState
    },
    addTextsFromData(data: any) {
      self.texts = data
    },
    addEmailsFromData(data: any) {
      //console.log(data)
      self.emails = data
    },
    addDocumentsFromData(data: any) {
      self.documents = data
    },



  }))
