import { types } from 'mobx-state-tree'


export const DialsInvoicesPaymentTerms = types
  .model({
    paymentTerm: types.optional(types.string, ''),
    roundTotal: types.optional(types.boolean, false)

  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
